import React, { useEffect, useRef, useState } from 'react'
import { RetorikActivity } from '../../../models/activityTypes'
import { setStreamingReplyToIdForText } from '../../Contexts/speechStore'

interface DisplayStreamingProps {
  streamingQueue: Array<RetorikActivity>
  triggerScroll: () => void
}

const interval = 25

const DisplayStreaming = ({
  streamingQueue,
  triggerScroll
}: DisplayStreamingProps): JSX.Element => {
  const [streamingTextToDisplay, setStreamingTextToDisplay] =
    useState<string>('')
  const [currentTextEnded, setCurrentTextEnded] = useState<boolean>(true)
  const [currentQueueIndex, setCurrentQueueIndex] = useState<number>(0)

  const intervalRef = useRef<NodeJS.Timer | null>(null)
  const streamRef = useRef<Array<string>>([])

  const handleNewStreaming = (text: string): void => {
    setCurrentTextEnded(false)
    // Create array containing indexes of \n parts to replace them with html <br /> tag later
    const slashNIndexesArray: Array<number> = []
    let searchIndex = 0
    while (text.indexOf('\n', searchIndex) !== -1) {
      const index = text.indexOf('\n', searchIndex)
      slashNIndexesArray.push(index)
      searchIndex = index + 2
    }

    streamRef.current = text.split('')
    let currentIndex = 0
    intervalRef.current = setInterval(() => {
      if (streamRef.current?.length) {
        const current = streamRef.current[0]
        if (slashNIndexesArray.includes(currentIndex)) {
          // If the current index is present in the array of indexes, then it means it is a \n so replace it with <br /> and jump 2 characters after
          setStreamingTextToDisplay((value) => `${value}<br />`)
          streamRef.current = streamRef.current.slice(1)
        } else {
          setStreamingTextToDisplay((value) => `${value}${current}`)
          streamRef.current = streamRef.current.slice(1)
        }

        currentIndex++
      } else {
        setCurrentTextEnded(true)
        intervalRef.current && clearInterval(intervalRef.current)
      }
    }, interval)
  }

  useEffect(() => {
    if (streamingQueue?.length && currentTextEnded) {
      if (streamingQueue.length > currentQueueIndex) {
        handleNewStreaming(streamingQueue[currentQueueIndex].text || '')
        setCurrentQueueIndex((value) => value + 1)
      } else {
        setCurrentQueueIndex(0)
        setStreamingReplyToIdForText(null)
      }
    }
  }, [streamingQueue, currentTextEnded])

  useEffect(() => {
    streamingTextToDisplay.length % 20 === 0 && triggerScroll()
  }, [streamingTextToDisplay])

  useEffect(() => {
    return () => {
      intervalRef?.current && clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div
      id='retorik-botResponses'
      className='rf-flex rf-flex-col rf-text-size-auto rf-scrollbar-hidden'
    >
      {streamingTextToDisplay && (
        <div
          className='rf-px-4 rf-text-textModePanelConversationBot'
          dangerouslySetInnerHTML={{ __html: streamingTextToDisplay }}
        />
      )}

      {currentQueueIndex !== 0 && (
        <div className='rf-px-4 rf-font-bold rf-text-textModePanelConversationBot rf-animate-blink'>
          ...
        </div>
      )}
    </div>
  )
}

export default DisplayStreaming
