import { useEffect, useState } from 'react'
import type { Queue } from '../models/types'
import { useActivityStore } from '../components/Contexts/activityStore'
import type { RetorikActivity } from '../models/activityTypes'

export default function useAnimationQueue(): Queue | undefined {
  const lastBotActivity: RetorikActivity | undefined = useActivityStore(
    (state) => state.lastBotActivity
  )
  const [animationQueue, setAnimationQueue] = useState<Queue>()

  const handleEvent = (e: CustomEvent): void => {
    e.detail && setAnimationQueue(e.detail.queue)
  }

  useEffect(() => {
    document.addEventListener('sendPlayAnimationEvent', handleEvent)
  }, [])

  useEffect(() => {
    if (lastBotActivity?.meta?.queue && lastBotActivity.meta.queue.length > 0) {
      setAnimationQueue(lastBotActivity.meta.queue)
    }
  }, [lastBotActivity])

  return animationQueue
}
