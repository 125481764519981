import React from 'react'
import { useList } from '../../../Contexts/ListContext'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { CloseIcon } from '../../../Icons/Miscellaneous'
import { FilterItem } from '../../../../models/filterTypes'

type ChosenItemProps = {
  className?: string
  filterItem: FilterItem
  parent: {
    id: string
    title: string
  }
}

const ChosenItem = ({
  className,
  filterItem,
  parent
}: ChosenItemProps): JSX.Element => {
  const { processFiltersOnChange } = useList()
  const { locale } = useLocaleStore((state) => ({
    locale: state.locale
  }))

  return (
    <div
      className={`rf-py-1 rf-text-size-auto rf-text-white rf-bg-gray hover:rf-cursor-pointer rf-rounded-lg rf-flex rf-flex-row rf-items-center rf-justify-center ${className}`}
      onClick={(): void =>
        processFiltersOnChange([
          {
            ...parent,
            filterItems: [filterItem]
          }
        ])
      }
    >
      <div className='rf-mr-2 large:rf-mr-3'>
        {parent.id === 'Event.DateRange'
          ? `${filterItem.name.substring(8, 10)}/${filterItem.name.substring(
              5,
              7
            )}/${filterItem.name.substring(0, 4)}`
          : capitalizeFirstLetter(filterItem.name, locale)}
      </div>
      <CloseIcon className='rf-h-3 rf-w-3' />
    </div>
  )
}

export default ChosenItem
