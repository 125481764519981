import React, { useEffect, useState } from 'react'
import {
  RetorikMainComponentConfiguration,
  RetorikMainComponentProps
} from '../../models/retorikTypes'
import RetorikContainer from './RetorikContainer'
import getRetorikConfigs from '../../utils/getConfigs'
import Loader from '../Loader/Loader'
import { AvailableViews, ContainerParent, Mode } from '../../models/enums'
import { DEFAULT_VIEWS } from '../../models/constants'
import getThemeColors from '../../utils/getThemeColors'
import { useRetorikStore } from '../Contexts/retorikStore'
import { fetchEmergencyData } from '../../utils/fetchEmergencyData'
import { EmergencyTypes } from '../../models/emergencyTypes'

type RetorikConfigHandlerProps = RetorikMainComponentProps & {
  parent: number
}

const RetorikConfigHandler = (props: RetorikConfigHandlerProps) => {
  const [mainConfig, setMainConfig] = useState<
    RetorikMainComponentConfiguration | undefined
  >(undefined)
  const [isConfigUpdated, setIsConfigUpdated] = useState<boolean>(false)
  const [configurationFetchingEnded, setConfigurationFetchingEnded] =
    useState<boolean>(!!(isConfigUpdated || props.config !== undefined))
  const layoutChanged: boolean = useRetorikStore((state) => state.layoutChanged)
  const mode: Mode = useRetorikStore((state) => state.mode)
  const loaderClosed: boolean = useRetorikStore((state) => state.loaderClosed)

  /**
   * Check if emergency data can be retrieved to remove the button in menu if no data available
   * @param configuration RetorikMainComponentConfiguration
   */
  const checkEmergencyAndSetConfig = async (
    configuration: RetorikMainComponentConfiguration
  ): Promise<void> => {
    const data: EmergencyTypes = await fetchEmergencyData(
      configuration.addressData
    )

    const tempConfiguration = { ...configuration }

    if (!data) {
      const views = tempConfiguration.config?.baseMenu || DEFAULT_VIEWS
      const tempBaseMenu = views.filter(
        (menu) =>
          // @ts-ignore : error on menu.view that doesn't exist in type CustomMenu
          !menu.view || menu.view !== AvailableViews.emergency
      )
      tempConfiguration.config &&
        (tempConfiguration.config.baseMenu = tempBaseMenu)
    }

    setMainConfig(tempConfiguration)
    setIsConfigUpdated(true)
    setConfigurationFetchingEnded(true)
  }

  // Handle configuration
  useEffect(() => {
    const getUpdatedConfig = async (): Promise<void> => {
      const fetchedConfig = await getRetorikConfigs(props)
      if (fetchedConfig && fetchedConfig.config) {
        const {
          config: {
            skipLoader = false,
            defaultMode,
            config: _config,
            viewsConfig,
            chatbotData,
            chatbotDataWidget,
            agentData,
            ponyfillFactoryCredentials,
            addressData,
            userData,
            customVoice,
            colors
          },
          externalConfigEnabled
        } = fetchedConfig
        console.log(externalConfigEnabled)

        const fetchedMainConfig: RetorikMainComponentConfiguration = {
          config: _config,
          viewsConfig: viewsConfig,
          chatbotData: chatbotData,
          chatbotDataWidget: chatbotDataWidget,
          agentData: agentData,
          ponyfillFactoryCredentials: ponyfillFactoryCredentials,
          addressData: addressData,
          userData: userData,
          customVoice: customVoice,
          colors: getThemeColors(colors),
          skipLoader: skipLoader,
          defaultMode: defaultMode,
          externalComponents: props.externalComponents,
          externalEventHandler: props.externalEventHandler
        }
        configHandleLayoutChange(
          layoutChanged,
          loaderClosed,
          fetchedMainConfig,
          mode
        )
        checkEmergencyAndSetConfig(fetchedMainConfig)
      }
    }

    if (props.config !== undefined) {
      console.log('Get config from manualConfig')
      // Check mandatory properties
      if (
        props.viewsConfig &&
        props.chatbotData &&
        props.agentData &&
        props.ponyfillFactoryCredentials
      ) {
        const copyConfig = { ...props }
        if (!copyConfig.chatbotDataWidget)
          copyConfig.chatbotDataWidget = copyConfig.chatbotData
        configHandleLayoutChange(layoutChanged, loaderClosed, copyConfig, mode)
        checkEmergencyAndSetConfig(copyConfig)
      } else {
        // Warnings for missing data in props
        console.warn('Mandatory configuration data missing : ')
        !props.viewsConfig && console.warn(' * viewsConfig')
        !props.chatbotData && console.warn(' * chatbotData')
        !props.agentData && console.warn(' * agentData')
        !props.ponyfillFactoryCredentials &&
          console.warn(' * ponyfillFactoryCredentials')
      }
    } else if (!isConfigUpdated) {
      console.log('Get config from Retorik')
      getUpdatedConfig()
    }
  }, [props])

  return mainConfig && configurationFetchingEnded ? (
    <RetorikContainer
      parent={props.parent}
      config={mainConfig}
      isConfigUpdated={isConfigUpdated}
      changedLayout={layoutChanged}
    />
  ) : (
    <Loader
      className={isConfigUpdated ? 'rf-animate-loaderFadeOut' : ''}
      parent={props.parent}
      width={props.width}
      height={props.height}
      fullSize={
        !!(
          props.parent !== ContainerParent.widget &&
          mainConfig?.config?.fullSize !== false
        )
      }
    />
  )
}

const configHandleLayoutChange = (
  layoutChanged: boolean,
  loaderClosed: boolean,
  config: RetorikMainComponentConfiguration,
  mode: Mode
) => {
  if (layoutChanged) {
    config.defaultMode = mode
    if (loaderClosed) {
      config.skipLoader = true
    }
  }
}

export default RetorikConfigHandler
