/* eslint-disable react/self-closing-comp */
import React, { useState, useRef, useEffect } from 'react'
import { useView } from '../Contexts/ViewContext'
import { useRetorik } from '../Contexts/RetorikContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { hooks } from 'botframework-webchat'
import { SendMessageIcon } from '../Icons/Miscellaneous'
import { setUserTyping } from '../Contexts/utilsStore'

const { useSendMessage } = hooks

interface SendTextProps {
  className?: string
  withButton?: boolean
}

const SendTextBox = ({ className, withButton }: SendTextProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const { setSendBoxRef, canFocusSendBox } = useRetorik()
  const { themeColors, isMobile } = useView()
  const sendMessage = useSendMessage()
  const [height, setHeight] = useState<string | undefined>()
  const [message, setMessage] = useState<string>('')
  const timerRef = useRef<NodeJS.Timer | null>(null)

  const textareaRef: React.MutableRefObject<HTMLTextAreaElement | null> =
    useRef(null)

  useEffect(() => {
    if (
      canFocusSendBox &&
      textareaRef.current &&
      document.activeElement !== textareaRef.current
    ) {
      textareaRef.current.focus()
    }
  }, [textareaRef, canFocusSendBox])

  useEffect(() => {
    if (textareaRef.current) {
      setSendBoxRef(textareaRef.current)
      textareaRef.current.addEventListener('focusout', () =>
        setUserTyping(false)
      )
    }

    return () => {
      setUserTyping(false)
      timerRef?.current && clearTimeout(timerRef.current)
    }
  }, [textareaRef])

  const handleMessageChange = (): void => {
    timerRef?.current && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setUserTyping(false)
    }, 1000)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setUserTyping(true)
    handleMessageChange()
    setMessage(e.currentTarget.value)
    textareaRef?.current && setHeight(`${textareaRef.current.scrollHeight}px`)
  }

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      e.preventDefault()
      e.stopPropagation()
      handleSend()
    }
  }

  const handleSend = (): void => {
    if (message.length > 0) {
      sendMessage(message, 'speech')
      setMessage('')
      setHeight(undefined)
      timerRef?.current && clearTimeout(timerRef.current)
      setUserTyping(false)
    }
  }

  return (
    <div
      className={`rf-relative rf-min-h-12 rf-px-4 rf-text-base rf-pt-3 rf-pb-3 rf-flex rf-items-center rf-border rf-border-menuBorder rf-rounded-lg rf-bg-truewhite rf-shadow-[inset_0_0_6px_#0000003B] ${className}`}
    >
      <textarea
        ref={textareaRef}
        className={`rf-input-hidden rf-outline-none ${
          withButton ? 'rf-w-[calc(100%-3rem)]' : 'rf-w-full'
        } rf-resize-none rf-scrollbar-hidden ${
          isMobile && 'placeholder:rf-text-black placeholder:rf-opacity-100'
        }`}
        value={message}
        placeholder={translation.menu.message}
        onKeyDown={(e): void => handleKeyDown(e)}
        onChange={(e): void => handleChange(e)}
        style={{
          height: height || '1.5rem'
        }}
      ></textarea>
      {withButton && (
        <button
          className='rf-absolute rf-right-4 rf-h-5 rf-w-5'
          onClick={handleSend}
        >
          <SendMessageIcon className='rf-w-5' color={themeColors.secondary} />
        </button>
      )}
    </div>
  )
}

export default SendTextBox
