/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useRef } from 'react'
import { useView } from '../Contexts/ViewContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { setCurrentSubView } from '../Contexts/utilsStore'
import useMessageActivities from '../../hooks/useMessageActivities'
import { SubMenuClosing as Template } from '../Templates'
import HistoryItem from './HistoryItem'
import { CurrentSubView, DeviceType } from '../../models/enums'
import preventEvents from '../../utils/preventEvents'
import { useSendEvent } from 'botframework-webchat-api/lib/hooks'

const HistoryList = (): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const { currentDeviceType } = useView()
  const activities = useMessageActivities()
  const scrollRef = useRef<HTMLDivElement>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const sendEvent = useSendEvent()

  /**
   * On call :
   *  - scroll to the scrollRef HTML element at the bottom of the view
   */
  const scrollToRef = (): void => {
    if (scrollRef && scrollRef.current !== null) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }

  /**
   * On component mount :
   *  - call scrollToRef method
   */
  useEffect(() => {
    setTimeout(() => {
      scrollToRef()
    }, 200)
  }, [])

  useEffect(() => {
    if (divRef?.current) {
      currentDeviceType === DeviceType.widget && preventEvents(null, divRef)
    }
  }, [divRef?.current])

  const handleClose = (): void => {
    setCurrentSubView(CurrentSubView.none)
    sendEvent('Davi.CloseWindow', null)
    sendEvent('Davi.CloseWindowHistory', null)
  }

  return (
    <Template
      handleClose={handleClose}
      backIsConversation={true}
      title={translation.history.title}
    >
      <div
        ref={divRef}
        className={`rf-pb-4 rf-overflow-y-scroll ${
          currentDeviceType === DeviceType.borne
            ? 'rf-scrollbar-thin'
            : 'rf-scrollbar-hidden'
        }`}
      >
        {/* History items */}
        {activities.map((activity, key) => {
          return <HistoryItem key={key} activity={activity} />
        })}
        <div ref={scrollRef} />
      </div>
    </Template>
  )
}

export default HistoryList
