import React, { useEffect } from 'react'
import { RetorikProvider } from './RetorikContext'
import { SpeechProvider, SpeechProviderProps } from './SpeechContext'
import { ViewProvider } from './ViewContext'
import type { ViewsProviderProps } from './ViewContext'
import { TelemetryProvider } from './TelemetryContext'
import type { AddressData, Configuration } from '../../models/types'
import { checkLocale } from '../../utils/checkLocale'
import {
  useLocaleStore,
  setLocale,
  fetchSupportedLanguages
} from './localeStore'
import { setAgentData } from './retorikStore'

type ContextProviderProps = {
  skipLoader?: boolean
  mode: number
  config: Configuration
  addressData: AddressData
  agentSource:
    | string
    | {
        url: string
        name?: string
      }
  isConfigUpdated: boolean
} & SpeechProviderProps &
  ViewsProviderProps

const ContextProvider = ({
  skipLoader,
  mode,
  viewsConfiguration,
  config,
  customVoice,
  addressData,
  agentSource,
  ponyfillFactoryCredentials,
  themeColors,
  children,
  isConfigUpdated
}: ContextProviderProps): JSX.Element | null => {
  const { locale, supported, defaultLocale } = useLocaleStore()

  /**
   * On component mount :
   *  - fetch supported languages on studio retorik
   */
  useEffect(() => {
    fetchSupportedLanguages(addressData)
  }, [])

  /**
   * On supported state change :
   *  - set locale state depending on data retrieved after fetching available languages and data from config prop
   */
  useEffect(() => {
    if (supported.length) {
      setLocale(
        checkLocale({ all: supported, default: defaultLocale }, config.locales)
      )
    }
  }, [supported, config, isConfigUpdated])
  /**
   * On agentSource prop change :
   *  - fetch agent data from the url present in agentSource prop
   *  - set the agent's name if present in agentSource prop
   *  - set agentManifest state
   */
  useEffect(() => {
    setAgentData(agentSource)
  }, [agentSource])

  return locale && supported ? (
    <ViewProvider
      viewsConfiguration={viewsConfiguration}
      themeColors={themeColors}
    >
      <RetorikProvider
        skipLoader={skipLoader}
        chosenMode={mode}
        config={config}
        addressData={addressData}
      >
        <SpeechProvider
          ponyfillFactoryCredentials={ponyfillFactoryCredentials}
          customVoice={customVoice}
        >
          <TelemetryProvider enabled={!(config.enableTelemetry === false)}>
            {children}
          </TelemetryProvider>
        </SpeechProvider>
      </RetorikProvider>
    </ViewProvider>
  ) : (
    <React.Fragment />
  )
}

export default ContextProvider
