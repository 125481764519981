import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import type { RetorikActivity } from '../../../models/activityTypes'
import DisplayStandard from './DisplayStandard'
import DisplayStreaming from './DisplayStreaming'

interface ConversationDisplayProps {
  userQuestion: RetorikActivity | undefined
  botResponses?: Array<RetorikActivity>
  streamingId?: string | null
  streamingQueue?: Array<RetorikActivity>
  userQuestionTime?: Date
  isMobile?: boolean
  triggerScroll: () => void
}

const ConversationDisplay = ({
  userQuestion,
  botResponses,
  streamingId,
  streamingQueue,
  userQuestionTime,
  isMobile,
  triggerScroll
}: ConversationDisplayProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)

  return (
    <React.Fragment>
      {userQuestion?.text && (
        <div
          id='retorik-userQuestion'
          key={userQuestion.id}
          className='rf-pb-4 rf-text-textModePanelConversationUser rf-animate-slideBottom rf-flex rf-flex-col rf-justify-start rf-text-size-auto rf-gap-2'
        >
          {/* Time */}
          {userQuestionTime && (
            <div className='rf-px-4 rf-text-[#9A9A9A]'>
              {userQuestionTime.toLocaleString(locale, {
                hour: 'numeric',
                minute: 'numeric'
              })}
            </div>
          )}

          {/* Text */}
          <div className='rf-px-4'>{userQuestion.text}</div>
        </div>
      )}

      {streamingId && streamingQueue?.length ? (
        <DisplayStreaming
          streamingQueue={streamingQueue}
          triggerScroll={triggerScroll}
        />
      ) : (
        <DisplayStandard
          botResponses={botResponses || []}
          isMobile={isMobile}
        />
      )}
    </React.Fragment>
  )
}

export default ConversationDisplay
