import React, { useMemo } from 'react'
import { hooks } from 'botframework-webchat-component'
import type {
  RecommandationButton,
  DiscoverContentType,
  ImageButton
} from '../../models/attachmentTypes'
import { useLocaleStore } from '../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { useView } from '../Contexts/ViewContext'
import SvgColorChanger from './Utils/SvgColorChanger'

const { useSendPostBack } = hooks

const DiscoverAttachment = ({
  title,
  mainButtons,
  recommandationButton,
  history,
  height
}: DiscoverContentType): JSX.Element => {
  const sendPostBack = useSendPostBack()
  const { locale } = useLocaleStore()
  const { themeColors } = useView()
  const displayLayout = useMemo<string>(() => {
    if (mainButtons && mainButtons.length > 1) {
      return `rf-w-full rf-grid rf-grid-cols-${mainButtons.length} rf-gap-2 large:rf-gap-4`
    }

    return 'rf-w-1/2 rf-justify-self-center'
  }, [mainButtons])

  const handleClick = (data: RecommandationButton | ImageButton): void => {
    let dataToSend: any = {}
    // @ts-ignore
    if (data.eventName) {
      const tempData = data as RecommandationButton

      dataToSend = {
        type: 'event',
        name: tempData.eventName,
        recommendationId: tempData.recommendationId
      }
    } else {
      const tempData = data as ImageButton
      const value = tempData.FilterId
        ? {
            value: {
              filterId: tempData.FilterId,
              filterValue: tempData.FilterValue,
              followingCardText: tempData.FollowingCardText
            }
          }
        : {}

      dataToSend = {
        type: 'event',
        name: tempData.EventName,
        ...value
      }
    }

    sendPostBack(dataToSend)
  }

  return (
    <div
      className={`rf-w-full rf-flex rf-flex-col rf-items-center rf-justify-between rf-rounded rf-bg-cardFrameBackground large:rf-p-[25px] rf-p-4 ${
        history && 'rf-pointer-events-none'
      } `}
      style={{
        height: height
      }}
    >
      <div className='rf-w-full rf-min-h-inherit rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText rf-title-size-auto rf-mb-3 rf-text-center'>
        {capitalizeFirstLetter(title, locale)}
      </div>

      <div className={`rf-h-full ${displayLayout}`}>
        {mainButtons && mainButtons.length > 0 ? (
          mainButtons.map((button, i) => {
            let colorButtonHandle = ''
            if (button.EventName.indexOf('TouristAttractions') > -1) {
              colorButtonHandle = themeColors.tobeDoneColor
            } else if (button.EventName.indexOf('Products') > -1) {
              colorButtonHandle = themeColors.localProductsColor
            } else if (button.EventName.indexOf('FoodEstablishments') > -1) {
              colorButtonHandle = themeColors.whereToEatColor
            } else if (button.EventName.indexOf('LodgingBusinesses') > -1) {
              colorButtonHandle = themeColors.whereToSleepColor
            } else if (button.EventName.indexOf('TouristDestinations') > -1) {
              colorButtonHandle = themeColors.tobeSeenColor
            } else if (button.EventName.indexOf('Event') > -1) {
              colorButtonHandle = themeColors.agendaColor
            }

            return (
              <div
                key={i}
                className='rf-relative rf-w-full rf-card-button-discover rf-rounded rf-flex rf-flex-col rf-gap-2 large:rf-gap-3 rf-items-center rf-justify-self-center'
                onClick={(): any => handleClick(button)}
                style={{
                  boxShadow: '0px 0px 13px #0000009c'
                }}
              >
                {button.MediaUrl.indexOf('svg') > -1 &&
                colorButtonHandle !== '' ? (
                  <SvgColorChanger
                    svgUrl={button.MediaUrl}
                    height='50px'
                    fillColor={colorButtonHandle}
                  />
                ) : (
                  <img
                    src={button.MediaUrl}
                    alt={button.Text}
                    style={{ height: '50px' }}
                  />
                )}
                <p className='rf-h-full rf-flex rf-items-center rf-text-center rf-uppercase rf-font-bold large:rf-text-basecustom rf-text-xsl'>
                  {button.Text}
                </p>
              </div>
            )
          })
        ) : (
          <></>
        )}
      </div>

      {/* Subtitle 2 lines max / title 2 lines max */}
      {recommandationButton && mainButtons && mainButtons.length > 1 && (
        <p
          className='rf-mt-4 rf-font-bold rf-card-button rf-text-center rf-capitalize-first'
          onClick={(): any => handleClick(recommandationButton)}
        >
          {recommandationButton.text}
        </p>
      )}
    </div>
  )
}

export default DiscoverAttachment
