{
  "1": "halaman rumah",
  "2": "saluran berita langsung",
  "3": "laporan cuaca",
  "4": "Nomor darurat",
  "1000": "historis",
  "1001": "tutorial",

  "common": {
    "all": "semua",
    "continue": "Melanjutkan",
    "download": "unduh",
    "downloadsheet": "unduh lembar ini",
    "downloadsheetmobile": "unduh lembar ini di ponsel pintar",
    "filter": "Saring",
    "history": "historis",
    "lang": "BAHASA",
    "menu": "menu",
    "mode": "mode",
    "needhelp": "Butuh bantuan?",
    "parameters": "pengaturan",
    "print": "Mencetak",
    "printsheet": "Cetak lembar ini",
    "result": "hasil",
    "results": "hasil",
    "singleLang": "bahasa",
    "sound": "dia",
    "submit": "kirim",
    "subtitle": "subjudul",
    "tutorial": "tutorial",
    "validate": "mengesahkan"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "POLISI",
    "firefighter": "petugas pemadam kebakaran",
    "general": "umum",
    "citypolice": "Polisi setempat",
    "hospital": "RSUD",
    "homeless": "Tunawisma (panggilan gratis)",
    "poison": "Pusat Pengendalian Racun",
    "emergency": "keadaan darurat Eropa"
  },

  "error": {
    "title": "Sebuah kesalahan telah terjadi",
    "button": "Luncurkan kembali aplikasi"
  },

  "history": {
    "title": "riwayat percakapan"
  },

  "inputs": {
    "choose": "Pilih jawaban"
  },

  "language": {
    "change": "mengubah bahasa",
    "select": "pilih bahasa yang ingin Anda gunakan"
  },

  "link": {
    "buttontext": "Lihat dokumennya",
    "goto": "Pergi ke alamat",
    "click": "klik disini",
    "scanQR": "dengan memindai QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Untuk pengalaman optimal,",
      "classic": "Untuk pengalaman yang lebih klasik,",
      "vocal": "Saya berbicara tatap muka dengan agen.",
      "text": "Saya berkomunikasi secara tertulis dengan agen."
    },
    "modes": {
      "vocal": "modus vokal",
      "text": "modus teks"
    },
    "loaderValidation": "Mulai percakapan",
    "locale": "memeriksa bahasa",
    "supported": "memeriksa bahasa yang didukung",
    "addressdata": "mengambil data"
  },

  "menu": {
    "message": "Masukkan pesan Anda",
    "allowMicrophone": "Izinkan browser Anda menggunakan mikrofon untuk melanjutkan",
    "back": "kembali",
    "backconversation": "kembali ke percakapan",
    "close": "menutup",
    "answer": " menjawab pertanyaan Anda"
  },

  "mobileLandscapePreventer": {
    "firstline": "Untuk pengalaman optimal,",
    "secondline": "balik layar Anda"
  },

  "news": {
    "warningtext": "Anda akan mengakses saluran berita, suara di perangkat Anda akan diaktifkan",
    "live": "hidup",
    "continuous": "terus menerus"
  },

  "poi": {
    "comment": "ulasan pelanggan",
    "route": "jalan",
    "nodata": "Tidak ada konten yang cocok dengan pencarian Anda",
    "openinghours": "Jam buka",
    "prices": "Harga",
    "paymentmethods": "Cara Pembayaran",
    "numberofplaces": "sejumlah tempat",
    "openroutemobile": "buka rute Google Maps di smartphone",
    "FoodEstablishment": "Tempat makan",
    "LodgingBusiness": "Tempat tidur",
    "TouristDestination": "Apa yang dilihat",
    "TouristAttraction": "Apa yang harus dilakukan",
    "Product": "Produk lokal",
    "Service": "Jasa",
    "Event": "Acara",
    "City": "kota",
    "Wine": "Anggur"
  },

  "suggestion": {
    "consult": "Periksa",
    "thetutorial": "tutorialnya"
  },

  "template": {
    "reset": "atur ulang semua",
    "showresults": "Tunjukkan hasilnya",
    "suggestiontitle": "Saran pencarian",
    "reminder": "Jangan lupa, \n saya bisa menjawab pertanyaan Anda."
  }
}