import { create } from 'zustand'
import { CurrentSubView, RetorikEvent } from '../../models/enums'
import type { CustomMenu } from '../../models/types'
import { WebChatActivity } from 'botframework-webchat-core'
import {
  RetorikActivity,
  DetailedAttachmentAction
} from '../../models/activityTypes'

interface UtilsStore {
  usingOnBorne: boolean
  fullScreenImage: string | undefined
  lastListActivity: string | undefined
  listClosed: boolean
  listOpened: boolean
  currentSubView: number
  currentCustomView: CustomMenu | undefined
  retorikEvent: RetorikEvent | undefined
  displayAgent: boolean
  activityToSend: WebChatActivity | undefined
  eventToSend: WebChatActivity | undefined
  currentNewsActivity: RetorikActivity | undefined
  retorikNewsEnded: boolean
  userIsTyping: boolean
  userIsSwiping: boolean
  askedForKioskParametersOpening: boolean
  detailedAttachmentTriggers: Array<DetailedAttachmentAction>
  modalFullscreenContent: JSX.Element | null
  printingCallback: ((url: string) => void) | null
}

const initialState: UtilsStore = {
  usingOnBorne: false,
  fullScreenImage: undefined,
  lastListActivity: undefined,
  listClosed: false,
  listOpened: false,
  currentSubView: CurrentSubView.none,
  currentCustomView: undefined,
  retorikEvent: undefined,
  displayAgent: true,
  activityToSend: undefined,
  eventToSend: undefined,
  currentNewsActivity: undefined,
  retorikNewsEnded: false,
  userIsTyping: false,
  userIsSwiping: false,
  askedForKioskParametersOpening: false,
  detailedAttachmentTriggers: [
    {
      type: 'event',
      name: 'hospitality.ShowPointOfInterest',
      linkedComponent: null
    },
    {
      type: 'event',
      name: 'hospitality.ShowContentItem',
      linkedComponent: null
    }
  ],
  modalFullscreenContent: null,
  printingCallback: null
}

let timer: NodeJS.Timer | null = null

export const useUtilsStore = create<UtilsStore>()(() => {
  return initialState
})

export const setUsingOnBorne = (value: boolean): void => {
  useUtilsStore.setState({ usingOnBorne: value })
}

export const setFullScreenImage = (src: string | undefined): void => {
  useUtilsStore.setState({ fullScreenImage: src })
}

export const setLastListActivity = (id: string | undefined): void => {
  useUtilsStore.setState({ lastListActivity: id })
}

export const setListClosed = (value: boolean): void => {
  useUtilsStore.setState({ listClosed: value })
}

export const setListOpened = (value: boolean): void => {
  useUtilsStore.setState({ listOpened: value })
}

export const setCurrentSubView = (subview: number): void => {
  useUtilsStore.setState({ currentSubView: subview })
}

export const setCurrentCustomView = (customView: CustomMenu): void => {
  useUtilsStore.setState({ currentCustomView: customView })
}

export const setRetorikEvent = (event: RetorikEvent): void => {
  useUtilsStore.setState({ retorikEvent: event })
}

export const showAgent = (): void => {
  useUtilsStore.setState({ displayAgent: true })
}

export const hideAgent = (): void => {
  useUtilsStore.setState({ displayAgent: false })
}

export const sendActivity = (activity: WebChatActivity | undefined): void => {
  useUtilsStore.setState({ activityToSend: activity })
}

export const sendEvent = (event: WebChatActivity | undefined): void => {
  useUtilsStore.setState({ eventToSend: event })
}

export const setCurrentNewsActivity = (
  activity: RetorikActivity | undefined
): void => {
  useUtilsStore.setState({ currentNewsActivity: activity })
}

export const setRetorikNewsEnded = (value: boolean): void => {
  useUtilsStore.setState({ retorikNewsEnded: value })
}

export const setUserTyping = (value: boolean): void => {
  useUtilsStore.setState({ userIsTyping: value })
}

export const setUserSwiping = (): void => {
  timer && clearTimeout(timer)
  useUtilsStore.setState({ userIsSwiping: true })
  // Switch back to false after 2.5s (because test for typing indicator is done every 2s)
  timer = setTimeout(() => {
    useUtilsStore.setState({ userIsSwiping: false })
  }, 2500)
}

export const askForKioskParametersOpening = (): void => {
  useUtilsStore.setState({ askedForKioskParametersOpening: true })
  setTimeout(() => {
    useUtilsStore.setState({ askedForKioskParametersOpening: false })
  }, 50)
}

export const addDetailedAttachmentTrigger = (
  value: DetailedAttachmentAction
): void => {
  const currentTriggers = useUtilsStore.getState().detailedAttachmentTriggers
  useUtilsStore.setState({
    detailedAttachmentTriggers: [...currentTriggers, value]
  })
}

export const setModalFullscreenContent = (value: JSX.Element | null): void => {
  useUtilsStore.setState({ modalFullscreenContent: value })
}

export const setPrintingCallback = (value: ((url: string) => void) | null) => {
  useUtilsStore.setState({ printingCallback: value })
}

export const resetUtilsStore = (): void => {
  useUtilsStore.setState({ ...initialState })
}
