import React from 'react'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { useRetorikStore } from '../../../Contexts/retorikStore'
import { Mode } from '../../../../models/enums'

type MobileDisplayResultProps = {
  onClick: () => void
}

const MobileDisplayResult = ({
  onClick
}: MobileDisplayResultProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const mode = useRetorikStore((state) => state.mode)

  return (
    <div
      className={`rf-w-full rf-pt-8 ${
        mode === Mode.text ? 'rf-mb-30' : 'rf-mb-16'
      } rf-flex rf-justify-center rf-justify-self-end`}
    >
      <button
        className='rf-py-4 rf-px-6 rf-font-bold rf-text-secondary rf-border-2 rf-rounded-lg rf-border-secondary'
        onClick={onClick}
      >
        {translation.template.showresults}
      </button>
    </div>
  )
}

export default MobileDisplayResult
