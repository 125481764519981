{
  "1": "kotisivu",
  "2": "live-uutiskanava",
  "3": "Säätiedotus",
  "4": "Hätänumerot",
  "1000": "historiallinen",
  "1001": "opetusohjelma",

  "common": {
    "all": "kaikki",
    "back": "takaisin",
    "close": "kiinni",
    "continue": "Jatkaa",
    "download": "ladata",
    "downloadsheet": "lataa tämä taulukko",
    "downloadsheetmobile": "lataa tämä taulukko älypuhelimeen",
    "filter": "Suodattaa",    
    "history": "historiallinen",
    "lang": "KIELET",
    "menu": "valikosta",
    "mode": "muoti",
    "needhelp": "Tarvitsetko apua?",
    "parameters": "asetukset",
    "print": "Tulosta",
    "printsheet": "Tulosta tämä arkki",
    "result": "tulos",
    "results": "tuloksia",
    "singleLang": "Kieli",
    "sound": "hänen",
    "submit": "Lähetä",
    "subtitle": "alaotsikko",
    "tutorial": "opetusohjelma",
    "validate": "vahvistaa"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "Poliisi",
    "firefighter": "palomiehiä",
    "general": "yleistä",
    "citypolice": "Paikallinen poliisi",
    "hospital": "Sairaala",
    "homeless": "Asunnoton (ilmainen puhelu)",
    "poison": "Poison Control Center",
    "emergency": "Euroopan hätätilanteita"
  },

  "error": {
    "title": "Tapahtui virhe",
    "button": "Käynnistä sovellus uudelleen"
  },

  "history": {
    "title": "keskusteluhistoria"
  },

  "inputs": {
    "choose": "Valitse vastaus"
  },

  "language": {
    "change": "vaihtaa kieltä",
    "select": "valitse kieli, jota haluat käyttää"
  },

  "link": {
    "buttontext": "Näytä asiakirja",
    "goto": "Mene osoitteeseen",
    "click": "Klikkaa tästä",
    "scanQR": "skannaamalla QR-koodin"
  },

  "loader": {
    "loader": {
      "optimal": "Optimaalisen kokemuksen saamiseksi",
      "classic": "Klassisemman kokemuksen saamiseksi",
      "vocal": "Puhun kasvotusten agentin kanssa.",
      "text": "Olen yhteydessä välittäjään kirjallisesti."
    },
    "modes": {
      "vocal": "laulutila",
      "text": "tekstitila"
    },
    "loaderValidation": "Aloita keskustelu",
    "locale": "kielen tarkistaminen",
    "supported": "tuettujen kielten tarkistaminen",
    "addressdata": "tietojen hakeminen"
  },

  "menu": {
    "message": "Kirjoita viestisi",
    "allowMicrophone": "Anna selaimen käyttää mikrofonia jatkaaksesi",
    "backconversation": "takaisin keskusteluun",
    "answer": " vastaa kysymyksiisi"
  },

  "mobileLandscapePreventer": {
    "firstline": "Optimaalisen kokemuksen saamiseksi",
    "secondline": "käännä näyttöäsi"
  },

  "news": {
    "warningtext": "Pääset uutiskanavalle, laitteesi ääni aktivoituu",
    "live": "elää",
    "continuous": "jatkuvasti"
  },

  "poi": {
    "comment": "Asiakaspalaute",
    "route": "tie",
    "nodata": "Mikään sisältö ei vastaa hakuasi",
    "openinghours": "Aukioloajat",
    "prices": "hinnat",
    "paymentmethods": "Maksutavat",
    "numberofplaces": "paikkojen määrä",
    "openroutemobile": "avaa Google Maps -reitti älypuhelimella",
    "FoodEstablishment": "Missä syödä",
    "LodgingBusiness": "Missä nukkua",
    "TouristDestination": "Mitä nähdä",
    "TouristAttraction": "Mitä tehdä",
    "Product": "Paikalliset tuotteet",
    "Service": "Palvelut",
    "Event": "Tapahtumat",
    "City": "kaupungit",
    "Wine": "Viinit"
  },

  "suggestion": {
    "consult": "Tarkista",
    "thetutorial": "opetusohjelma"
  },

  "template": {
    "reset": "nollaa kaikki",
    "showresults": "Näytä tulokset",
    "suggestiontitle": "Hakuehdotukset",
    "reminder": "Älä unohda, \n voin vastata kysymyksiisi."
  }
}
