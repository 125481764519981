import { create } from 'zustand'
import { RetorikActivity } from '../../models/activityTypes'

interface ActivityStore {
  // All activities
  activities: Array<RetorikActivity>
  // (all / last) message / events activities
  messageActivities: Array<RetorikActivity>
  lastMessageActivity: RetorikActivity | undefined
  eventActivities: Array<RetorikActivity>
  lastEventActivity: RetorikActivity | undefined
  // Bot activities
  lastBotActivity: RetorikActivity | undefined
  botMessageActivities: Array<RetorikActivity>
  lastBotMessageActivity: RetorikActivity | undefined
  botEventActivities: Array<RetorikActivity>
  lastBotEventActivity: RetorikActivity | undefined
  // User activities
  userMessageActivities: Array<RetorikActivity>
  lastUserMessageActivity: RetorikActivity | undefined
  // Miscellaneous
  queue: Array<RetorikActivity>
  watermark: number
  conversationId: string | null
  homeActivity: RetorikActivity | undefined
  retrievingConversation: boolean
}

const initialState: ActivityStore = {
  activities: [],
  lastBotActivity: undefined,
  homeActivity: undefined,
  messageActivities: [],
  lastMessageActivity: undefined,
  eventActivities: [],
  lastEventActivity: undefined,
  botMessageActivities: [],
  lastBotMessageActivity: undefined,
  botEventActivities: [],
  lastBotEventActivity: undefined,
  userMessageActivities: [],
  lastUserMessageActivity: undefined,
  queue: [],
  conversationId: null,
  watermark: 0,
  retrievingConversation: false
}

let timerRef

export const useActivityStore = create<ActivityStore>()(() => {
  return initialState
})

const addActivity = async (
  activity: RetorikActivity,
  resetQueue?: boolean
): Promise<void> => {
  const currentState = useActivityStore.getState()
  const shouldNotSetLastBotActivity =
    currentState.retrievingConversation && !resetQueue

  if (activity.type === 'message') {
    if (activity.from.role === 'bot') {
      useActivityStore.setState({
        activities: [...currentState.activities, activity],
        lastBotActivity: shouldNotSetLastBotActivity ? undefined : activity,
        messageActivities: [...currentState.messageActivities, activity],
        botMessageActivities: [...currentState.botMessageActivities, activity],
        lastBotMessageActivity: activity,
        watermark: currentState.watermark + 1
      })
    } else {
      useActivityStore.setState({
        activities: [...currentState.activities, activity],
        messageActivities: [...currentState.messageActivities, activity],
        userMessageActivities: [
          ...currentState.userMessageActivities,
          activity
        ],
        lastUserMessageActivity: activity,
        watermark: currentState.watermark + 1
      })
    }
  } else if (activity.type === 'event') {
    if (activity.from.role === 'bot') {
      useActivityStore.setState({
        activities: [...currentState.activities, activity],
        lastBotActivity: shouldNotSetLastBotActivity ? undefined : activity,
        eventActivities: [...currentState.eventActivities, activity],
        botEventActivities: [...currentState.botEventActivities, activity],
        lastBotEventActivity: activity,
        watermark: currentState.watermark + 1
      })
    } else {
      useActivityStore.setState({
        activities: [...currentState.activities, activity],
        eventActivities: [...currentState.eventActivities, activity],
        watermark: currentState.watermark + 1
      })
    }
  }

  if (resetQueue) {
    useActivityStore.setState({
      queue: []
    })
    useActivityStore.setState({ retrievingConversation: false })
  }
}

/**
 * Deal with the queue of activities that was formed before.
 * Call again after splicing the first element of the queue every 100ms
 * @param queue Array<RetorikActivity>
 */
const addActivitiesFromQueue = async (
  queue: Array<RetorikActivity>
): Promise<void> => {
  // Keep last id to know when we get the last activity
  const lastId = queue[queue.length - 1].id
  for (const activity of queue) {
    await addActivity(activity, activity.id === lastId)
  }
}

const updateActivities = (): void => {
  const currentState = useActivityStore.getState()

  if (currentState.queue.length === 1) {
    addActivity(currentState.queue[0], true)
  } else {
    const processedQueue = currentState.queue.sort((a, b) => {
      if (!(a.id && a.id.includes('|'))) {
        return -1
      } else if (!(b.id && b.id.includes('|'))) {
        return 1
      } else {
        const id1 = parseInt(a.id.split('|')[1])
        const id2 = parseInt(b.id.split('|')[1])
        return isNaN(id1) ? -1 : isNaN(id2) ? 1 : id1 - id2
      }
    })

    // Process the queue to add each activity to the array
    addActivitiesFromQueue(processedQueue)
  }
}

export const addActivityToQueue = (activity: RetorikActivity): void => {
  useActivityStore.setState({
    queue: [...useActivityStore.getState().queue, activity]
  })
  // Wait 500ms after each call to be sure to process all incoming activities in the right order
  timerRef && clearTimeout(timerRef)
  timerRef = setTimeout(() => {
    updateActivities()
  }, 500)
}

export const addBotMessageActivity = (activity: RetorikActivity): void => {
  // Set the home activity to be able to show its attachments when needed
  if (activity.messageType === 'welcome') {
    useActivityStore.setState({ homeActivity: activity })
  }

  addActivityToQueue(activity)
}

export const checkConversationId = (value: string): void => {
  const currentState = useActivityStore.getState()

  // Check directline id to know if we have to reset the activities
  if (value && value !== currentState.conversationId) {
    currentState.conversationId === null
      ? useActivityStore.setState({ conversationId: value })
      : useActivityStore.setState({ ...initialState, conversationId: value })
  }
}

export const setRetrievingConversation = (value: boolean): void => {
  useActivityStore.setState({ retrievingConversation: value })
}

export const resetActivityStore = (): void => {
  useActivityStore.setState({ ...initialState })
}
