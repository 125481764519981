import React from 'react'
import { useList } from '../../../Contexts/ListContext'
import type { Filter } from '../../../../models/filterTypes'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import CheckBox from '../../../Utils/Inputs/CheckBox'

type SubFilterProps = {
  className?: string
  fullParent: Filter
}

const SubFilterAll = ({
  className,
  fullParent
}: SubFilterProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const { selectedFilters, processFiltersOnChange } = useList()
  const { locale } = useLocaleStore()

  return (
    <div
      className={`rf-w-full rf-py-4 large:rf-py-1 rf-flex rf-justify-between rf-items-center ${className}`}
    >
      {capitalizeFirstLetter(translation.common.all, locale)}
      <CheckBox
        className='rf-rounded large:rf-ml-8'
        iconClassName='rf-w-4 rf-h-4 large:rf-p-min1 large:rf-w-6 large:rf-h-6'
        checked={
          selectedFilters.filter(
            (filter) =>
              filter.title === fullParent.title &&
              filter.filterItems.length === fullParent.filterItems.length
          ).length === 1
        }
        onClick={(): void => processFiltersOnChange([{ ...fullParent }], true)}
      />
    </div>
  )
}

export default SubFilterAll
