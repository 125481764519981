import React, { useEffect, useState } from 'react'
import {
  RetorikMainComponentProps,
  RetorikWidgetProps
} from '../../models/retorikTypes'
import { ContainerParent } from '../../models/enums'
import { useLocaleStore } from '../Contexts/localeStore'
import {
  setLayoutChanged,
  setLayout,
  useRetorikStore
} from '../Contexts/retorikStore'
import {
  toggleDisplay,
  toggleWidget,
  useWidgetStore
} from '../Contexts/widgetStore'
import {
  getWidgetBorderStyle,
  getWidgetButtonStyle
} from '../../utils/getWidgetStyles'
import { widgetDefault } from '../../models/colors'
import { WidgetFrame } from '../../models/types'
import CloseIcon from '../Icons/Miscellaneous/CloseIcon'
import ExtendIcon from '../Icons/Miscellaneous/ExtendIcon'

type LayoutHandlerProps = {
  currentLayout: ContainerParent
  retorikProps: RetorikMainComponentProps & Partial<RetorikWidgetProps>
  children: any
}

const LayoutHandler = (props: LayoutHandlerProps) => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const displayChangeLayoutButton = useRetorikStore(
    (state) => state.changeLayoutButtonDisplay
  )
  const { open, loaded, widgetDisplayType } = useWidgetStore()
  const [currentConfiguration, setCurrentConfiguration] = useState<
    WidgetFrame | undefined
  >(props.retorikProps.widgetConfig?.large)
  const [widgetButton, setWidgetButton] = useState<string>('')
  const [widgetBorder, setWidgetBorder] = useState<string>('')
  const [mobileWidth, setMobileWidth] = useState<boolean>(
    window.innerWidth < 600
  )
  const [frameStyle, setFrameStyle] = useState<Record<string, any>>({})

  const handleResize = (): void => {
    setMobileWidth(window.innerWidth < 600)
  }

  useEffect(() => {
    setWidgetButton(
      getWidgetButtonStyle(props.retorikProps.widgetConfig?.button)
    )
    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    mobileWidth
      ? setFrameStyle({
          width: open ? '100%' : '0px',
          height: '100%'
        })
      : setFrameStyle({
          top: currentConfiguration?.position?.top,
          bottom: currentConfiguration?.position?.bottom
            ? currentConfiguration?.position?.bottom
            : currentConfiguration?.position?.top ||
              currentConfiguration?.position?.left ||
              currentConfiguration?.position?.right
            ? undefined
            : '1rem',
          left: currentConfiguration?.position?.left,
          right: currentConfiguration?.position?.right
            ? currentConfiguration?.position?.right
            : currentConfiguration?.position?.top ||
              currentConfiguration?.position?.bottom ||
              currentConfiguration?.position?.left
            ? undefined
            : '1rem',
          borderColor: currentConfiguration?.border?.color || widgetDefault.rgb
        })
  }, [mobileWidth, currentConfiguration])

  useEffect(() => {
    switch (widgetDisplayType) {
      case 'large':
        setCurrentConfiguration(props.retorikProps.widgetConfig?.large)
        setWidgetBorder(
          getWidgetBorderStyle(props.retorikProps.widgetConfig?.large?.border)
        )
        break
      case 'thumbnail':
        setCurrentConfiguration(
          props.retorikProps.widgetConfig?.thumbnail ||
            props.retorikProps.widgetConfig?.large
        )
        setWidgetBorder(
          getWidgetBorderStyle(
            props.retorikProps.widgetConfig?.thumbnail?.border ||
              props.retorikProps.widgetConfig?.large?.border
          )
        )
        break
      default:
        break
    }
  }, [widgetDisplayType])

  const extendButtonHandler = (): void => {
    // if different display type available
    if (
      props.retorikProps.widgetConfig?.large &&
      props.retorikProps.widgetConfig?.thumbnail
    ) {
      toggleDisplay()
    } else {
      setLayoutChanged(true)
      setLayout(ContainerParent.agent)
    }
  }
  return props.currentLayout === ContainerParent.widget ? (
    <>
      {props.retorikProps.widgetConfig?.button?.display !== false && (
        <button
          className={`rf-fixed rf-px-2 rf-flex rf-flex-row rf-items-center ${widgetButton}`}
          style={{
            color:
              props.retorikProps.widgetConfig?.button?.text?.color ||
              widgetDefault.rgb,
            backgroundColor:
              props.retorikProps.widgetConfig?.button?.background?.color ||
              'transparent',
            borderWidth:
              props.retorikProps.widgetConfig?.button?.border?.thickness !==
              undefined
                ? `${props.retorikProps.widgetConfig?.button?.border?.thickness}px`
                : '1px',
            borderColor:
              props.retorikProps.widgetConfig?.button?.border?.color ||
              widgetDefault.rgb,
            top: props.retorikProps.widgetConfig?.button?.position?.top,
            bottom: props.retorikProps.widgetConfig?.button?.position?.bottom
              ? props.retorikProps.widgetConfig?.button?.position?.bottom
              : props.retorikProps.widgetConfig?.button?.position?.top ||
                props.retorikProps.widgetConfig?.button?.position?.left ||
                props.retorikProps.widgetConfig?.button?.position?.right
              ? undefined
              : '1rem',
            left: props.retorikProps.widgetConfig?.button?.position?.left,
            right: props.retorikProps.widgetConfig?.button?.position?.right
              ? props.retorikProps.widgetConfig?.button?.position?.right
              : props.retorikProps.widgetConfig?.button?.position?.top ||
                props.retorikProps.widgetConfig?.button?.position?.bottom ||
                props.retorikProps.widgetConfig?.button?.position?.left
              ? undefined
              : '1rem'
          }}
          onClick={toggleWidget}
          id='retorik-widget-button'
        >
          {/* Image on the left if there is a URL and either position = 'left' or no position */}
          {props.retorikProps.widgetConfig?.button?.image?.url &&
            props.retorikProps.widgetConfig?.button?.image?.position !==
              'right' && (
              <img
                className={`${
                  props.retorikProps.widgetConfig?.button?.image.marginY ===
                  false
                    ? ''
                    : 'rf-my-1'
                }`}
                src={`${props.retorikProps.widgetConfig.button.image.url}`}
                alt='Retorik widget'
                style={{
                  height:
                    props.retorikProps.widgetConfig?.button?.image.height ||
                    '2rem'
                }}
              />
            )}
          {/* Text content */}
          <div className='rf-py-2 rf-ml-1'>
            {props.retorikProps.widgetConfig?.button?.text?.content ||
              translation.common.needhelp}
          </div>
          {/* Image on the right if there is a URL and position = 'right' */}
          {props.retorikProps.widgetConfig?.button?.image?.url &&
            props.retorikProps.widgetConfig?.button?.image?.position ===
              'right' && (
              <img
                className={`${
                  props.retorikProps.widgetConfig?.button?.image.marginY ===
                  false
                    ? ''
                    : 'rf-my-1'
                }`}
                src={`${props.retorikProps.widgetConfig.button.image.url}`}
                alt='Retorik widget'
                style={{
                  height:
                    props.retorikProps.widgetConfig?.button?.image.height ||
                    '2rem'
                }}
              />
            )}
        </button>
      )}
      {loaded && (
        <div
          className='rf-fixed rf-pt-8 rf-height-fill-available rf-transition-all rf-overflow-hidden rf-max-w-[100vw]'
          style={{
            ...frameStyle,
            width: open
              ? mobileWidth
                ? '100%'
                : currentConfiguration?.width || '400px'
              : '0px',
            height:
              currentConfiguration?.height || (mobileWidth ? '100%' : '630px')
          }}
          data-testid='widget-wrapper'
        >
          {displayChangeLayoutButton &&
            !mobileWidth &&
            props.currentLayout === ContainerParent.widget && (
              <button
                onClick={extendButtonHandler}
                aria-label='Extend'
                className='rf-absolute rf-z-exitwidget rf-flex rf-right-8 rf-top-0 rf-p-1 rf-bg-truewhite rf-border rf-border-trueblack rf-rounded-lg'
              >
                <ExtendIcon
                  className='rf-h-4 rf-w-4'
                  color='#000000'
                  extended={false}
                />
              </button>
            )}
          <button
            onClick={toggleWidget}
            aria-label='Close'
            className='rf-absolute rf-z-exitwidget rf-flex rf-right-0 rf-top-0 rf-p-1 rf-bg-truewhite rf-border rf-border-trueblack rf-rounded-lg'
          >
            <CloseIcon className='rf-h-4 rf-w-4' color='#000000' />
          </button>
          <div
            className={`rf-relative rf-h-full rf-w-full rf-overflow-hidden ${
              !mobileWidth && widgetBorder
            }`}
            style={{
              borderColor: frameStyle.borderColor
            }}
          >
            {props.children}
          </div>
        </div>
      )}
    </>
  ) : (
    props.children
  )
}

export default LayoutHandler
