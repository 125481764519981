import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const OpenLocationIcon = ({ className }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 29.771 29.771'
      className={className || 'rf-w-6 rf-h-6'}
    >
      <path d='M29.771,0,0,14.886l11.164,3.721,3.721,11.164Z' />
    </svg>
  )
}

export default OpenLocationIcon
