import React, { useEffect, useState } from 'react'
import { useView } from '../Contexts/ViewContext'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { useLocaleStore } from '../Contexts/localeStore'
import { ArrowRightIcon } from '../Icons/ArrowIcons'
import {
  setModalFullscreenContent,
  useUtilsStore
} from '../Contexts/utilsStore'
import { CurrentSubView } from '../../models/enums'
import { getTextToDisplayOnLink } from '../../utils/getTextToDisplayOnLink'
import QRCode from 'qrcode'
import { InfoWithCircleIcon } from '../Icons/Miscellaneous'
import Animation2 from '../Loader/Animation2'
import QRCodeComponent from '../Utils/QRCodeComponent'
import PrintingPreview from '../Attachments/PrintingPreview'
import { useRetorikStore } from '../Contexts/retorikStore'

interface ActionButtonProps {
  text?: string
  fullWidth: boolean
  position: string
  display: string
  urlToOpen?: string
  onClick: () => void
}

const ActionButton = ({
  text,
  fullWidth,
  position,
  display,
  urlToOpen,
  onClick
}: ActionButtonProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const isUsedOnBorne = useUtilsStore((state) => state.usingOnBorne)
  const enableDocumentPrinting = useRetorikStore(
    (state) => state.enableDocumentPrinting
  )
  const locale = useLocaleStore((state) => state.locale)
  const { themeColors } = useView()
  const [style, setStyle] = useState<string>()
  const [hover, setHover] = useState<boolean>(false)
  const [qrCodeData, setQrCodeData] = useState<any>()

  const setQrCodeDataAsync = async (): Promise<void> => {
    if (urlToOpen) {
      const qrCodeString = await QRCode.toDataURL(encodeURI(urlToOpen))
      setQrCodeData(qrCodeString)
    }
  }

  useEffect(() => {
    if (urlToOpen && isUsedOnBorne) {
      setQrCodeDataAsync()
    }
  }, [urlToOpen, isUsedOnBorne])

  useEffect(() => {
    // Set width : full or fit
    let temp = fullWidth ? 'rf-w-full' : 'rf-w-fit'
    // Set position if not in full width
    if (!fullWidth && position) {
      switch (position) {
        case 'right':
          temp += ' rf-float-right'
          break
        case 'center':
          temp += ' rf-mx-auto'
          break
        case 'left':
        default:
          break
      }
    }

    setStyle(temp)
  }, [])

  const handleOpenPreview = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      urlToOpen &&
        setModalFullscreenContent(
          <PrintingPreview
            url={urlToOpen}
            printingAllowed={enableDocumentPrinting}
          />
        )
    }
  }

  const handleClick = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      setHover(true)
      onClick()
    }
  }

  const handleEnter = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      setHover(true)
    }
  }

  const handleLeave = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      setHover(false)
    }
  }

  return urlToOpen && isUsedOnBorne ? (
    <div className='rf-w-full rf-flex rf-flex-col rf-justify-center rf-items-center rf-text-cardButtonTextDefault'>
      {urlToOpen.length > 4 &&
        urlToOpen.substring(urlToOpen.length - 4) === '.pdf' && (
          <div className='rf-w-full rf-pb-6 rf-flex rf-justify-start rf-text-primary rf-underline rf-italic rf-text-sm'>
            <p className='rf-cursor-pointer' onClick={handleOpenPreview}>
              {translation.link.buttonText}
            </p>
          </div>
        )}
      {qrCodeData ? (
        <React.Fragment>
          <QRCodeComponent src={qrCodeData} />
          <div className='rf-relative rf-text-center rf-mx-8 rf-mt-2 rf-italic'>
            <InfoWithCircleIcon className='rf-absolute rf-top-[0.125rem] -rf-left-6 rf-h-4' />
            {getTextToDisplayOnLink({
              translation,
              isUsedOnBorne,
              data: {
                url: urlToOpen,
                download: true
              }
            })}
          </div>
        </React.Fragment>
      ) : (
        <div className='rf-w-6 rf-h-6'>
          <Animation2
            color='gray'
            loaderColor={themeColors.primary}
            backgroundColor='transparent'
          />
        </div>
      )}
    </div>
  ) : text && display.toLowerCase() === 'link' ? (
    <div
      className={`rf-w-full rf-mt-2 rf-flex rf-justify-start rf-items-center rf-text-cardButtonTextDefault ${
        currentSubView !== CurrentSubView.history && 'rf-cursor-pointer'
      }`}
      onPointerEnter={handleEnter}
      onPointerLeave={handleLeave}
      onClick={handleClick}
    >
      <div
        className={`rf-h-6 rf-w-6 rf-rounded ${
          hover
            ? 'rf-bg-cardButtonBackgroundHover'
            : 'rf-bg-cardButtonBackgroundDefault'
        }`}
      >
        <ArrowRightIcon
          className={`rf-h-6 rf-p-1 rf-border rf-rounded ${
            hover
              ? 'rf-border-cardButtonBorderHover'
              : 'rf-border-cardButtonBorderDefault'
          }`}
          color={
            hover
              ? themeColors.card.button.text.hover
              : themeColors.card.button.text.default
          }
        />
      </div>
      <div className='rf-ml-4'>{capitalizeFirstLetter(text, locale)}</div>
    </div>
  ) : (
    <div
      className={` ${
        currentSubView !== CurrentSubView.history
          ? 'rf-card-button'
          : 'rf-card-button-history'
      } rf-mt-2 ${fullWidth ? 'rf-text-center' : 'rf-text-left'} ${style}`}
      onClick={handleClick}
    >
      {capitalizeFirstLetter(text || translation.common.submit, locale)}
    </div>
  )
}

export default ActionButton
