/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import WeatherContent from '@davi-ai/retorik-weather'
import { useLocaleStore } from '../Contexts/localeStore'
import { setCurrentSubView } from '../Contexts/utilsStore'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { AvailableViews, CurrentSubView, Depth } from '../../models/enums'
import { FullScreen, FullScreenMobile } from '../Templates'
import MobileTopContainer from '../Utils/SubView/MobileTopContainer'
import LargeIconAndTitle from '../Utils/SubView/LargeIconAndTitle'
import AbsoluteLargeClosingButton from '../Utils/SubView/AbsoluteLargeClosingButton'
import { useSendEvent } from 'botframework-webchat-api/lib/hooks'

interface WeatherProps {
  handleClose?: () => void
}

const Weather = ({ handleClose }: WeatherProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const { configuration } = useRetorik()
  const { currentDeviceType, isMobile } = useView()
  const sendEvent = useSendEvent()

  /**
   * On call :
   *  - set RetorikContext's displayWeather state to false
   */
  const handleBack = (): void => {
    sendEvent('Davi.CloseWindow', null)
    sendEvent('Davi.CloseWindowWeather', null)
    handleClose ? handleClose() : setCurrentSubView(CurrentSubView.none)
  }

  return isMobile ? (
    <FullScreenMobile depth={Depth.dropdown} background='#FFFFFF'>
      {/* Top container : back button + weather icon + title */}
      <MobileTopContainer
        subView={AvailableViews.weather}
        className='rf-border-b rf-border-b-menuBorder'
        handleBack={handleBack}
      />

      {/* Weather data */}
      <WeatherContent
        locale={locale}
        deviceType={currentDeviceType}
        position={{
          latitude: configuration.position?.latitude || 0,
          longitude: configuration.position?.longitude || 0
        }}
      />
    </FullScreenMobile>
  ) : (
    <FullScreen
      depth={Depth.dropdown}
      background='linear-gradient(to right bottom, #F2F8F8FC , #F2E1E2E8)'
    >
      <div className='rf-h-full rf-w-full rf-flex rf-flex-col rf-items-center'>
        {/* Weather icon + text */}
        <LargeIconAndTitle subView={AvailableViews.weather} />

        {/* Weather data */}
        <WeatherContent
          locale={locale}
          deviceType={currentDeviceType}
          position={{
            latitude: configuration.position?.latitude || 0,
            longitude: configuration.position?.longitude || 0
          }}
        />
      </div>

      {/* Closing button */}
      <AbsoluteLargeClosingButton
        dashboardVisible={true}
        onClick={handleBack}
      />
    </FullScreen>
  )
}

export default Weather
