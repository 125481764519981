/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { setModalFullscreenContent } from '../../Contexts/utilsStore'
import { PrintIcon } from '../../Icons/DetailedPOIIcons'
import PrintingPreview from '../PrintingPreview'

interface PrintingButtonProps {
  url: string
}

const PrintingButton = ({ url }: PrintingButtonProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  const handleClick = (): void => {
    setModalFullscreenContent(
      <PrintingPreview url={url} printingAllowed={true} />
    )
  }

  return (
    <button
      className='rf-px-4 rf-py-1 rf-flex rf-flex-row rf-items-center rf-gap-2 rf-font-bold rf-text-sm rf-border-2 rf-border-black rf-rounded-lg'
      onClick={handleClick}
    >
      <PrintIcon />
      {translation.common.printsheet}
    </button>
  )
}

export default PrintingButton
