import React from 'react'
import {
  CONTENT_TYPE_NEWS,
  CONTENT_TYPE_POI,
  CONTENT_TYPE_EMPTY,
  CONTENT_TYPE_IMAGE,
  CONTENT_TYPE_LINK,
  CONTENT_TYPE_SUGGESTEDACTION,
  CONTENT_TYPE_DISCOVER,
  CONTENT_TYPE_SUGGESTION
} from '../../models/attachmentTypes'
import NewsAttachment from '../Attachments/NewsAttachment'
import POIAttachment from '../Attachments/POIAttachment'
import EmptyAttachment from '../Attachments/EmptyAttachment'
import ImageAttachment from '../Attachments/ImageAttachment'
import LinkAttachment from '../Attachments/LinkAttachment'
import MultiLinkAttachment from '../Attachments/MultiLinkAttachment'
import SuggestedActionAttachment from '../Attachments/SuggestedActionAttachment'
import DiscoverAttachment from '../Attachments/DiscoverAttachment'
import SuggestionAttachment from '../Attachments/SuggestionAttachment'

const attachmentMiddleware =
  () =>
  (next) =>
  (...args): JSX.Element | any => {
    const [{ attachment, history, height }] = args

    switch (attachment.contentType) {
      case CONTENT_TYPE_NEWS:
        return <NewsAttachment {...{ ...attachment.content, height: height }} />
      case CONTENT_TYPE_POI:
        return <POIAttachment {...attachment.content} />
      case CONTENT_TYPE_EMPTY:
        return <EmptyAttachment />
      case CONTENT_TYPE_IMAGE:
        return (
          <ImageAttachment {...{ ...attachment.content, height: height }} />
        )
      case CONTENT_TYPE_LINK:
        return Array.isArray(attachment.content.urlData) ? (
          <MultiLinkAttachment {...{ ...attachment.content, height: height }} />
        ) : (
          <LinkAttachment {...{ ...attachment.content, height: height }} />
        )
      case CONTENT_TYPE_SUGGESTEDACTION:
        return (
          <SuggestedActionAttachment
            {...attachment.content}
            history={history}
            height={height}
          />
        )
      case CONTENT_TYPE_DISCOVER:
        return (
          <DiscoverAttachment
            {...attachment.content}
            history={history}
            height={height}
          />
        )
      case CONTENT_TYPE_SUGGESTION:
        return (
          <SuggestionAttachment
            {...attachment.content}
            history={history}
            height={height}
          />
        )

      default: {
        if (height) {
          const tempArgs = args[0]
          tempArgs.attachment.content.minHeight = `${height}px`
          tempArgs.attachment.content.verticalContentAlignment = 'Center'
          args[0] = tempArgs
        } else {
          const tempArgs = args[0]
          tempArgs.attachment.content.minHeight = '0px'
          tempArgs.attachment.content.verticalContentAlignment = 'Center'
          args[0] = tempArgs
        }

        return next(...args)
      }
    }
  }
export default attachmentMiddleware
