/* eslint-disable react/jsx-boolean-value */
import React, { useMemo } from 'react'
import { useView } from '../Contexts/ViewContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { setCurrentSubView } from '../Contexts/utilsStore'
import { CurrentSubView, Depth } from '../../models/enums'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { FullScreen } from '../Templates'
import { BackButton } from '../Menu/Common'
import AbsoluteLargeClosingButton from '../Utils/SubView/AbsoluteLargeClosingButton'
import { useSendEvent } from 'botframework-webchat-api/lib/hooks'

interface CustomViewProps {
  customView?: string
  title?: string
  icon?: JSX.Element
  externalComponents?: Array<{ name: string; component: JSX.Element }>
  display?: boolean
  setDisplayCustomComponent: (value: boolean) => void
}

const iconClassname =
  'rf-h-16 rf-w-16 large-vertical:rf-h-24 large-vertical:rf-w-24'
const iconClassnameMobile = 'rf-h-8 rf-w-8'

const CustomView = ({
  customView,
  title,
  icon,
  externalComponents,
  display,
  setDisplayCustomComponent
}: CustomViewProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const { isMobile, displayControls } = useView()
  const sendEvent = useSendEvent()

  const handleBack = (): void => {
    sendEvent('Davi.CloseWindow', null)
    sendEvent(
      title
        ? `Davi.CloseWindow${title.replace(/\s/g, '')}`
        : 'Davi.CloseWindowCustom',
      null
    )
    setCurrentSubView(CurrentSubView.none)
    setDisplayCustomComponent(false)
  }

  const componentToDisplay: JSX.Element = useMemo(() => {
    if (customView?.length) {
      if (externalComponents) {
        const component = externalComponents.find((e) => e.name === customView)
        if (component?.component) {
          return component.component
        }
      }
    }
    return <></>
  }, [customView])

  return (
    <FullScreen
      className={`${!display && 'rf-hidden'} ${
        isMobile && displayControls ? 'rf-mb-12' : 'rf-mb-0'
      }`}
      depth={Depth.modal}
      background='#FFFFFF'
    >
      {/* title */}
      <div className='rf-absolute rf-z-util rf-w-full rf-top-0 rf-left-0'>
        {/* Mobile display */}
        <div className='large:rf-hidden rf-p-4 rf-flex rf-flex-col'>
          <BackButton
            title={translation.common.back}
            className='rf-text-sm'
            handleBack={handleBack}
          />
          <div className='large:rf-hidden rf-py-2 rf-flex rf-flex-row rf-items-end undefined'>
            {icon ? <div className={iconClassnameMobile}>{icon} </div> : <></>}
            <div className='rf-ml-2 rf-capitalize rf-subtitle-size-auto'>
              {title ? capitalizeFirstLetter(title, locale) : <></>}
            </div>
          </div>
        </div>

        <div className='rf-hidden large:rf-flex rf-w-full rf-pt-4 large-vertical:rf-pt-8 rf-pl-4 large-vertical:rf-pl-0 rf-flex-row rf-justify-start large-vertical:rf-justify-center rf-items-center'>
          {icon ? <div className={iconClassname}>{icon} </div> : <></>}
          <div className='rf-ml-2 rf-uppercase rf-subtitle-size-auto'>
            {title ? capitalizeFirstLetter(title, locale) : <></>}
          </div>
        </div>
      </div>

      {componentToDisplay}

      {/* Closing button in large mode */}
      <AbsoluteLargeClosingButton
        dashboardVisible
        customComponent={true}
        onClick={handleBack}
      />
    </FullScreen>
  )
}

export default CustomView
