{
  "_comment": "[CI-LOCKED] Innehållet i den här filen är låst. Bidrag välkomnas men verifieringen av ändrar kan fördröjas.",
  "ACCESS_KEY_ALT": "Alt Skift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Ctrl Alt $1",
  "ACTIVITY_BOT_SAID_ALT": "Roboten $1 sade:",
  "ACTIVITY_YOU_SAID_ALT": "Du sade:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robot bifogad:",
  "ACTIVITY_ERROR_BOX_TITLE": "Felmeddelande",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Klicka för att interagera.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Meddelandet är interaktivt.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Det finns en eller flera länkar i meddelandet.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Det gick inte att skicka.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Du bifogade:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "En bilaga.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1-bilagor.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1-bilagor.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1-bilagor.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1-bilagor.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Det gick inte att skicka. [Försök igen med][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Skickar",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Skickades klockan $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Just nu",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "En timme sedan",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "En minut sedan",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "I dag",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "I går",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Fel vid parsning av adaptivt kort",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Fel vid återgivning av adaptivt kort",
  "ATTACHMENT_AUDIO": "Ett ljudklipp.",
  "ATTACHMENT_CARD": "Ett kort: $1 $2 $3",
  "ATTACHMENT_FILE": "En fil: $1",
  "ATTACHMENT_IMAGE": "En bild",
  "ATTACHMENT_TEXT": "En text: $1",
  "ATTACHMENT_VIDEO": "Ett videoklipp",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Nästa",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Föregående",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Ansluten",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Ansluter ...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Det gick inte att ansluta.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Ett nätverksavbrott inträffade. Ansluter igen ...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Återge fel. Kontrollera konsolen eller kontakta robotutvecklaren.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Det tar längre tid än vanligt att ansluta.",
  "CONNECTIVITY_STATUS_ALT": "Anslutningsstatus: $1",
  "FILE_CONTENT_ALT": "$1",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Hämta filen $1",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Ladda ned filen $1 med storleken $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1 med storleken $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Returtangent",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Få åtkomst till objekt i meddelandet",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Chatthistorik",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Esc-tangent",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Lämna meddelandet",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabbtangent\nSkift + tabbtangent",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Flytta mellan objekt i ett meddelande",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Uppåtpil\nNedåtpil",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Flytta mellan meddelanden",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Returtangent",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Utför åtgärd",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabbtangent\nSkift + tabbtangent",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Flytta mellan objekt i chattfönstret",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Chattfönster",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Stäng",
  "KEYBOARD_HELP_HEADER": "Tangentbordskontroller",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Öppnas i ett nytt fönster; externt.",
  "RECEIPT_CARD_TAX": "Skatt",
  "RECEIPT_CARD_TOTAL": "Summa",
  "RECEIPT_CARD_VAT": "Moms",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Det går inte att skicka ett tomt meddelande.",
  "SPEECH_INPUT_LISTENING": "Lyssnar ...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon av",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon på",
  "SPEECH_INPUT_STARTING": "Startar ...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Nästa",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Föregående",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Föreslagna åtgärder",
  "TEXT_INPUT_ALT": "Inmatningsruta för meddelande",
  "TEXT_INPUT_PLACEHOLDER": "Skriv ditt meddelande",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Skicka",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Läs upp",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Ladda upp fil",
  "TOAST_ACCORDION_FEW": "$1 aviseringar: Klicka här för att visa mer information.",
  "TOAST_ACCORDION_MANY": "$1 aviseringar: Klicka här för att visa mer information.",
  "TOAST_ACCORDION_OTHER": "$1 aviseringar: Klicka här för att visa mer information.",
  "TOAST_ACCORDION_TWO": "$1 aviseringar: Klicka här för att visa mer information.",
  "TOAST_ALT_ERROR": "Fel",
  "TOAST_ALT_INFO": "Information",
  "TOAST_ALT_SUCCESS": "Lyckades",
  "TOAST_ALT_WARN": "Varning",
  "TOAST_DISMISS_BUTTON": "Stäng",
  "TOAST_TITLE_ALT": "Avisering",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Chatthistorik, tryck på piltangenterna för att navigera.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "chatthistorik",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Meddelandet är interaktivt. Tryck på Skift+Tabbtangenten 2 till 3 gånger för att växla till chatthistoriken. Klicka sedan på meddelandet för att interagera.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Det finns en eller flera länkar i meddelandet. Tryck på Skift+Tabbtangenten 2 till 3 gånger för att växla till chatthistoriken. Klicka sedan på meddelandet för att interagera.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Meddelandet har föreslagit åtgärder. Tryck på Skift Tabb 2 till 3 gånger för att välja dem.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Meddelandet har föreslagit åtgärder. Tryck på $1 för att välja dem.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Det gick inte att skicka meddelandet.",
  "TRANSCRIPT_MORE_MESSAGES": "Fler meddelanden",
  "TRANSCRIPT_NEW_MESSAGES": "Nya meddelanden",
  "TRANSCRIPT_TERMINATOR_TEXT": "Slut på chatthistorik",
  "TYPING_INDICATOR_ALT": "Visar skrivindikatorn",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 skriver.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 och andra skriver."
}