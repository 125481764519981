import React, { useEffect, useState } from 'react'
import type {
  RetorikMainComponentProps,
  RetorikWidgetProps
} from '../models/retorikTypes'
import ShadowRoot from './Common/ShadowRoot'
import {
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay
} from './Contexts/widgetStore'
import { setLayout, useRetorikStore } from './Contexts/retorikStore'
import LayoutHandler from './Common/LayoutHandler'
import { ContainerParent } from '../models/enums'
import RetorikConfigHandler from './Common/RetorikConfigHandler'

window.Retorik = {
  ...window.Retorik,
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay
}

const RetorikWidget = (
  props: RetorikMainComponentProps & RetorikWidgetProps
): JSX.Element => {
  // --------- /!\ -----------
  // Ne mettre ici que de la logique spécifique au widget
  // Ce qui est commun avec RetorikAgent ou autres doit aller dans RetorikComposer ou ContextProvider
  // -------------------------
  const currentLayout = useRetorikStore((state) => state.currentLayout)
  useEffect(() => {
    setLayout(ContainerParent.widget)
  }, [])

  const [mobileWidth, setMobileWidth] = useState<boolean>(
    window.innerWidth < 600
  )

  const handleResize = (): void => {
    setMobileWidth(window.innerWidth < 600)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ShadowRoot>
      <LayoutHandler currentLayout={currentLayout} retorikProps={props}>
        <RetorikConfigHandler
          {...{
            parent: currentLayout,
            ...props,
            width: 'full',
            height: 'full',
            mobile: mobileWidth
          }}
        />
      </LayoutHandler>
    </ShadowRoot>
  )
}

export default RetorikWidget
