{
  "1": "hjemmeside",
  "2": "direktesendt nyhetskanal",
  "3": "Værmelding",
  "4": "Nødnumre",
  "1000": "historisk",
  "1001": "opplæringen",

  "common": {
    "all": "alle",
    "back": "tilbake",
    "close": "Lukk",
    "continue": "Fortsette",
    "download": "nedlasting",
    "downloadsheet": "last ned dette arket",
    "downloadsheetmobile": "last ned dette arket på smarttelefonen",
    "filter": "Filter",
    "history": "historisk",
    "lang": "språkene",
    "menu": "Meny",
    "mode": "mote",
    "needhelp": "Trenger hjelp?",
    "parameters": "innstillinger",
    "print": "Skrive ut",
    "printsheet": "Skriv ut dette arket",
    "result": "resultat",
    "results": "resultater",
    "singleLang": "språk",
    "sound": "henne",
    "submit": "sende inn",
    "subtitle": "undertekst",
    "tutorial": "opplæringen",
    "validate": "å validere"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "politiet",
    "firefighter": "brannmenn",
    "general": "generell",
    "hospital": "Sykehus",
    "homeless": "Hjemløs",
    "poison": "Giftkontrollsenter",
    "emergency": "Nødhjelp"
  },

  "error": {
    "title": "Det har oppstått en feil",
    "button": "Start applikasjonen på nytt"
  },

  "history": {
    "title": "samtalehistorikk"
  },

  "inputs": {
    "choose": "Velg et svar"
  },

  "language": {
    "change": "endre språket",
    "select": "velg språket du ønsker"
  },

  "link": {
    "buttontext": "Se dokumentet",
    "goto": "Gå til adressen",
    "click": "Klikk her",
    "scanQR": "ved å skanne QRCode"
  },

  "loader": {
    "loader": {
      "optimal": "For en optimal opplevelse,",
      "classic": "For en mer klassisk opplevelse,",
      "vocal": "Jeg snakker ansikt til ansikt med agenten.",
      "text": "Jeg kommuniserer skriftlig med agenten."
    },
    "modes": {
      "vocal": "vokalmodus",
      "text": "tekstmodus"
    },
    "loaderValidation": "Start samtale",
    "locale": "sjekke språk",
    "supported": "sjekke støttede språk",
    "addressdata": "henter data"
  },

  "menu": {
    "message": "Skriv inn meldingen din",
    "allowMicrophone": "La nettleseren din bruke mikrofonen for å fortsette",
    "backconversation": "tilbake til samtalen",
    "answer": " svarer på spørsmålene dine"
  },

  "mobileLandscapePreventer": {
    "firstline": "For en optimal opplevelse,",
    "secondline": "snu skjermen"
  },

  "news": {
    "warningtext": "Du får tilgang til nyhetskanalen, lyden på enheten din aktiveres",
    "live": "bo",
    "continuous": "kontinuerlig"
  },

  "poi": {
    "comment": "kundeanmeldelse",
    "route": "vei",
    "nodata": "Ingen innhold samsvarer med søket ditt",
    "openinghours": "Åpningstider",
    "prices": "Priser",
    "paymentmethods": "Betalingsmetoder",
    "numberofplaces": "antall plasser",
    "openroutemobile": "åpne Google Maps-ruten på smarttelefonen",
    "FoodEstablishment": "Hvor skal vi spise",
    "LodgingBusiness": "Hvor skal man sove",
    "TouristDestination": "Hva er det å se",
    "TouristAttraction": "Hva å gjøre",
    "Product": "Lokale produkter",
    "Service": "Tjenester",
    "Event": "arrangementer",
    "City": "Byer",
    "Wine": "Viner"
  },

  "suggestion": {
    "consult": "Sjekk ut",
    "thetutorial": "opplæringen"
  },

  "template": {
    "reset": "Tilbakestill alt",
    "showresults": "Vis resultatene",
    "suggestiontitle": "Søkeforslag",
    "reminder": "Ikke glem, \n jeg kan svare på spørsmålene dine."
  }
}
