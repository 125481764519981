import { useActivityStore } from '../components/Contexts/activityStore'

const useShowMap = (): boolean => {
  const lastBotActivity = useActivityStore((state) => state.lastBotActivity)

  if (
    lastBotActivity &&
    lastBotActivity.type === 'event' &&
    lastBotActivity.name === 'Hospitality.ShowMap'
  ) {
    return true
  }

  return false
}

export default useShowMap
