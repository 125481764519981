{
  "1": "pàgina d'inici",
  "2": "canal de notícies en directe",
  "3": "informe del temps",
  "4": "Números d'emergència",
  "1000": "històric",
  "1001": "tutorial",

  "common": {
    "all": "tots",
    "back": "esquena",
    "close": "tanca",
    "continue": "continua",
    "download": "descarregar",
    "downloadsheet": "descarregar aquest full",
    "downloadsheetmobile": "descarregueu aquest full al telèfon intel·ligent",
    "filter": "filtre",
    "history": "històric",
    "lang": "idiomes",
    "menu": "menú",
    "mode": "moda",
    "needhelp": "necessitar ajuda?",
    "parameters": "configuracions",
    "print": "imprimir",
    "printsheet": "imprimeix aquest full",
    "result": "resultat",
    "results": "resultats",
    "singleLang": "llenguatge",
    "sound": "ella",
    "subtitle": "subtítol",
    "submit": "presentar",
    "tutorial": "tutorial",
    "validate": "validar"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "policia",
    "firefighter": "bombers",
    "general": "general",
    "citypolice": "Policia local",
    "hospital": "Hospital",
    "homeless": "Sense llar (trucada gratuïta)",
    "poison": "Centre de control de verí",
    "emergency": "emergències europees"
  },

  "error": {
    "title": "S'ha produït un error",
    "button": "Reinicieu l'aplicació"
  },

  "history": {
    "title": "historial de converses"
  },

  "inputs": {
    "choose": "Tria una resposta"
  },

  "language": {
    "change": "canviar l'idioma",
    "select": "seleccioneu l'idioma que voleu utilitzar"
  },

  "link": {
    "buttontext": "Visualitza el document",
    "goto": "Vés a l'adreça",
    "click": "Clica aquí",
    "scanQR": "escanejant el QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Per a una experiència òptima,",
      "classic": "Per a una experiència més clàssica,",
      "vocal": "Parlo cara a cara amb l'agent.",
      "text": "Em comunico per escrit amb l'agent."
    },
    "modes": {
      "vocal": "manera vocal",
      "text": "mode text"
    },
    "loaderValidation": "Inicia la conversa",
    "locale": "comprovant el llenguatge",
    "supported": "comprovant els idiomes compatibles",
    "addressdata": "recuperant dades"
  },

  "menu": {
    "message": "Introduïu el vostre missatge",
    "allowMicrophone": "Permeteu que el vostre navegador utilitzi el micròfon per continuar",
    "backconversation": "tornar a la conversa",
    "answer": " respon les teves preguntes"
  },

  "mobileLandscapePreventer": {
    "firstline": "Per a una experiència òptima,",
    "secondline": "gira la pantalla"
  },

  "news": {
    "warningtext": "Accedeix al canal de notícies, s'activarà el so del teu dispositiu",
    "live": "viu",
    "continuous": "contínuament"
  },

  "poi": {
    "comment": "revisió del client",
    "route": "carretera",
    "nodata": "Cap contingut coincideix amb la teva cerca",
    "openinghours": "Horari d'obertura",
    "prices": "Preus",
    "paymentmethods": "Mètodes de Pagament",
    "numberofplaces": "nombre de places",
    "openroutemobile": "obre la ruta de Google Maps al telèfon intel·ligent",
    "FoodEstablishment": "On menjar",
    "LodgingBusiness": "On dormir",
    "TouristDestination": "Què veure",
    "TouristAttraction": "Què fer",
    "Product": "Productes locals",
    "Service": "Serveis",
    "Event": "Esdeveniments",
    "City": "Ciutats",
    "Wine": "Vins"
  },

  "suggestion": {
    "consult": "Fes una ullada",
    "thetutorial": "el tutorial"
  },

  "template": {
    "reset": "restablir tot",
    "showresults": "Mostra els resultats",
    "suggestiontitle": "Cerca suggeriments",
    "reminder": "No oblidis, \n puc respondre les teves preguntes."
  }
}
