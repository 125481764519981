{
  "1": "домашняя страница",
  "2": "прямой информационный канал",
  "3": "погода",
  "4": "номера экстренных служб",
  "1000": "история",
  "1001": "руководство",

  "common": {
    "all": "все",
    "back": "назад",
    "close": "закрывать",
    "continue": "Продолжать",
    "download": "Скачать",
    "downloadsheet": "Загрузите этот лист",
    "downloadsheetmobile": "скачать этот лист на смартфон",
    "filter": "Фильтр",
    "history": "исторический",
    "lang": "ЯЗЫКИ",
    "menu": "меню",
    "mode": "мода",
    "needhelp": "Нужна помощь?",
    "parameters": "настройки",
    "print": "Распечатать",
    "printsheet": "Распечатать этот лист",
    "result": "результат",
    "results": "Результаты",
    "singleLang": "язык",
    "sound": "звук",
    "submit": "Отправить",
    "subtitle": "субтитры",
    "tutorial": "руководство",
    "validate": "подтвердить"
  },

  "emergency": {
    "samu": "С.А.М.У.",
    "police": "полиция",
    "firefighter": "пожарные",
    "general": "общий",
    "hospital": "Больница",
    "homeless": "Бездомный",
    "poison": "Токсикологический центр",
    "emergency": "Аварийные службы"
  },

  "error": {
    "title": "произошла ошибка",
    "button": "Перезапустите приложение"
  },

  "history": {
    "title": "история разговоров"
  },

  "inputs": {
    "choose": "Выберите ответ"
  },

  "language": {
    "change": "изменить язык",
    "select": "выберите язык по вашему выбору"
  },

  "link": {
    "buttontext": "Посмотреть документ",
    "goto": "Перейти по адресу",
    "click": "кликните сюда",
    "scanQR": "отсканировав QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Для оптимального опыта,",
      "classic": "Для более классического опыта,",
      "vocal": "Я общаюсь, разговаривая с агентом.",
      "text": "Я общаюсь по тексту с агентом."
    },
    "modes": {
      "vocal": "голосовой режим",
      "text": "текстовый режим"
    },
    "loaderValidation": "Начать разговор",
    "locale": "проверка языка",
    "supported": "проверка поддерживаемых языков",
    "addressdata": "Извлечение данных"
  },

  "menu": {
    "message": "Введите ваше сообщение",
    "allowMicrophone": "Разрешите вашему браузеру использовать микрофон, чтобы продолжить.",
    "backconversation": "вернуться к разговору",
    "answer": " отвечает на ваши вопросы"
  },

  "mobileLandscapePreventer": {
    "firstline": "Для оптимального опыта,",
    "secondline": "переверните экран"
  },

  "news": {
    "warningtext": "Вы получите доступ к новостному каналу, звук на вашем устройстве будет активирован.",
    "live": "жить",
    "continuous": "непрерывно"
  },

  "poi": {
    "comment": "отзыв клиента",
    "route": "дорога",
    "nodata": "Нет контента, соответствующего вашему запросу",
    "openinghours": "Часы работы",
    "prices": "Цены",
    "paymentmethods": "Способы оплаты",
    "numberofplaces": "количество мест",
    "openroutemobile": "открыть маршрут Google Maps на смартфоне",
    "FoodEstablishment": "Где поесть",
    "LodgingBusiness": "Где спать",
    "TouristDestination": "Что посмотреть",
    "TouristAttraction": "Что делать",
    "Product": "Местные продукты",
    "Service": "Услуги",
    "Event": "События",
    "City": "Города",
    "Wine": "Вина"
  },

  "suggestion": {
    "consult": "Проверить",
    "thetutorial": "учебник"
  },

  "template": {
    "reset": "сбросить все",
    "showresults": "Показать результаты",
    "suggestiontitle": "Поисковые предложения",
    "reminder": "Не забывай, \n Я могу ответить на твои вопросы."
  }
}
