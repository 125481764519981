import { createStore } from 'botframework-webchat'
import createLinkAttachmentCard, {
  createAttachmentsFromMultiLink
} from './createLinkAttachmentCard'
import createImageAttachmentCard from './createImageAttachmentCard'
import processDirectLineIncomingActivity from './processDirectlineIncomingActivity'
import {
  CONTENT_TYPE_LINK,
  CONTENT_TYPE_SUGGESTEDACTION,
  CONTENT_TYPE_SUGGESTION
} from '../models/attachmentTypes'
import type {
  Action,
  SuggestedActionAttachment
} from '../models/suggestedActionAttachment'
import { processAttachments } from './processAttachments'
import {
  addToStreamingQueue,
  setCancel
} from '../components/Contexts/speechStore'
import { setListClosed } from '../components/Contexts/utilsStore'
import {
  addBotMessageActivity,
  addActivityToQueue,
  checkConversationId
} from '../components/Contexts/activityStore'
import { processMarkdownAndSpeechMarkdown } from './markdownUtils'

interface DataAddedToChannelData {
  pageSize: string | number
  location?: {
    latitude: number
    longitude: number
  }
  position?: {
    latitude: number
    longitude: number
  }
}

const regexDataCommand =
  /(\{data-command=")[\p{L}\p{N}\p{Po}\p{Pd}\p{Pc}\s\u0027\u2019]+("\})(<br\s\/>)?/gu

const createCustomStore: typeof createStore = (
  enableConversationCookie,
  conversationCookieMaxAge,
  addressData,
  locale,
  externalEventHandler,
  isUsedOnBorne,
  isRetorikNews
) => {
  return createStore(
    {},
    ({ dispatch }) =>
      (next) =>
      async (action): Promise<void> => {
        // Switch on action's type to process if needed
        switch (action.type) {
          case 'DIRECT_LINE/CONNECT_FULFILLED': {
            // Check conversationId
            const conversationId = action.payload.directLine.conversationId
            checkConversationId(conversationId)
            // Start_speaking for webchat to get the welcome speech if there is one
            dispatch({
              type: 'WEB_CHAT/START_SPEAKING'
            })

            if (isRetorikNews) {
              // Use only 1 conversation id for RetorikNews every 7 days to prevent the creation of multiple empty conversations in backoffice
              let test = true
              const fullTenantName = (
                addressData.baseURI ||
                `${addressData.prefix || ''}${addressData.tenant}`
              ).replace(/;|,|\s/g, '')

              const newsConversationData = localStorage.getItem(
                `Retorik.News.Conversation.${fullTenantName}`
              )

              if (newsConversationData) {
                const data = JSON.parse(newsConversationData)
                if (data) {
                  // 604800000 = number of milliseconds in 7 days
                  if (
                    !isNaN(data.startedAt) &&
                    data.startedAt + 604800000 > Date.now()
                  ) {
                    test = false
                  }
                }
              }

              if (test) {
                const dataToPutInlocalStorage = {
                  conversationId: conversationId,
                  startedAt: Date.now()
                }

                localStorage.setItem(
                  `Retorik.News.Conversation.${fullTenantName}`,
                  JSON.stringify(dataToPutInlocalStorage)
                )
              }
            } else if (!(enableConversationCookie === false)) {
              const conversationCookie = document.cookie
                .split('; ')
                .find((row) => row.startsWith('retorikConversationCookie='))
                ?.split('=')[1]

              const fullTenantName = (
                addressData.baseURI ||
                `${addressData.prefix || ''}${addressData.tenant}`
              ).replace(/;|,|\s/g, '')

              if (
                !(
                  conversationCookie &&
                  conversationCookie.split('||').length === 2 &&
                  conversationCookie.split('||')[1] === fullTenantName
                )
              ) {
                document.cookie = `retorikConversationCookie=${conversationId}||${fullTenantName}; max-age=${
                  conversationCookieMaxAge || 3600
                }`
              }
            }
            break
          }
          case 'DIRECT_LINE/POST_ACTIVITY': {
            // Prevent typing message from cancelling speech
            if (
              action.payload?.activity?.type === 'typing' ||
              (action.payload?.activity?.type === 'event' &&
                action.payload.activity.name
                  ?.toLowerCase()
                  .includes('davi.closewindow'))
            ) {
              return next(action)
            }

            // Cancel playing utterance if there is one
            setCancel(true)

            // Send pagination and position data in channelData on each activity sent to the chatbot, using local storage to get up to date data
            if (action.payload?.activity) {
              const paginationAsString = localStorage.getItem(
                'Retorik.Framework.Pagination'
              )
              const positionAsString = localStorage.getItem(
                'Retorik.Framework.Location'
              )
              const position = positionAsString
                ? JSON.parse(positionAsString)
                : undefined

              const dataAddedToChannelData: DataAddedToChannelData = {
                pageSize: paginationAsString
                  ? JSON.parse(paginationAsString)
                  : 10
              }

              if (position && position.latitude && position.longitude) {
                dataAddedToChannelData.location = {
                  latitude: position.latitude,
                  longitude: position.longitude
                }
                dataAddedToChannelData.position = {
                  latitude: position.latitude,
                  longitude: position.longitude
                }
              }

              if (action.payload.activity.channelData === undefined) {
                action.payload.activity = {
                  ...action.payload.activity,
                  channelData: dataAddedToChannelData
                }
              } else {
                action.payload.activity.channelData = {
                  ...action.payload.activity.channelData,
                  ...dataAddedToChannelData
                }
              }
            }

            break
          }
          case 'DIRECT_LINE/DISCONNECT':
            action = {
              type: 'WEB_CHAT/SET_LANGUAGE',
              payload: {
                language: locale
              }
            }
            break
          case 'DIRECT_LINE/INCOMING_ACTIVITY': {
            if (
              action.payload?.activity?.type === 'event' &&
              action.payload?.activity?.name === 'EndOfSpeechEvent'
            ) {
              return
            }

            if (
              action.payload?.activity?.type === 'event' &&
              action.payload?.activity?.name?.toLowerCase() ===
                'davi.streammessageactivity'
            ) {
              if (action.payload.activity.text?.trim().length) {
                // Create speak field from the text one
                const processedText = processMarkdownAndSpeechMarkdown(
                  action.payload.activity.text,
                  true
                )
                action.payload.activity.text = processedText.text
                action.payload.activity.speak = processedText.text
                console.log(action.payload.activity)
                addToStreamingQueue(action.payload.activity)
              }
              return next(action)
            }

            // Only process message activities from the bot
            if (
              action.payload?.activity?.type === 'message' &&
              action.payload.activity.from.role === 'bot'
            ) {
              setListClosed(false)
              // If the application is used on a borne and there are attachments, check if a received attachment is a multi-link one.
              // If there is some, we need to split it in several attachments, one for each link.
              if (
                isUsedOnBorne &&
                action.payload?.activity?.attachments &&
                action.payload.activity.attachments.length > 0
              ) {
                let count = 0
                const tempAttachments: Array<any> = []
                for (const attachment of action.payload.activity.attachments) {
                  if (
                    attachment.contentType === CONTENT_TYPE_LINK &&
                    Array.isArray(attachment.content?.urlData)
                  ) {
                    count++
                    const splitAttachments =
                      await createAttachmentsFromMultiLink(
                        attachment.content.urlData
                      )
                    tempAttachments.push(...splitAttachments)
                  } else {
                    tempAttachments.push(attachment)
                  }
                }
                // If some attachments have been split, we replace the attachments in the activity
                count > 0 &&
                  (action.payload.activity.attachments = tempAttachments)
                // Carousel display if there is more than 1 card
                count > 0 &&
                  action.payload.activity.attachments.length > 1 &&
                  (action.payload.activity.attachmentLayout = 'carousel')
              }

              const processedData = processDirectLineIncomingActivity(action)
              // Set processed speak, text and attachments if there are some
              processedData.speak &&
                (action.payload.activity.speak = processedData.speak)
              processedData.text &&
                (action.payload.activity.text = processedData.text)
              processedData.htmlText &&
                (action.payload.activity.htmlText = processedData.htmlText)

              // Check if there are cards to create from images
              if (processedData.images.length > 0) {
                // If there is no attachment field, create an empty one
                action.payload.activity.attachments === undefined &&
                  (action.payload.activity.attachments = [])
                // Create content from image data and add it to the attachments
                for (const image of processedData.images) {
                  const imageAttachment = await createImageAttachmentCard(image)
                  action.payload.activity.attachments.push(imageAttachment)
                }
                action.payload.activity.attachments.length > 1 &&
                  (action.payload.activity.attachmentLayout = 'carousel')
              }

              // Check if there are cards to create from links
              if (processedData.urls.length > 0) {
                // If there is no attachment field, create an empty one
                action.payload.activity.attachments === undefined &&
                  (action.payload.activity.attachments = [])
                // Create content asynchonously from url data and add it to the attachments
                for (const url of processedData.urls) {
                  const fetchedAttachment = await createLinkAttachmentCard(url)
                  action.payload.activity.attachments.push(fetchedAttachment)
                }
                action.payload.activity.attachments.length > 1 &&
                  (action.payload.activity.attachmentLayout = 'carousel')
              }

              // Transform herocards in adaptivecards
              const heroToAdaptiveAttachments = processAttachments(
                action.payload.activity.attachments
              )
              heroToAdaptiveAttachments &&
                (action.payload.activity.attachments = [
                  ...heroToAdaptiveAttachments
                ])

              // Transform suggested actions into cards if the display isn't daviList
              if (
                action.payload.activity.suggestedActions?.actions &&
                action.payload.activity.attachmentLayout?.toLowerCase() !==
                  'davilist'
              ) {
                let actionsArray: Array<Action> = []
                action.payload.activity.suggestedActions.actions.forEach(
                  (suggestedAction) => {
                    const action: Action = {
                      title: suggestedAction.title,
                      action: suggestedAction.value
                    }

                    actionsArray = [...actionsArray, action]
                  }
                )

                const suggestedActionAttachment: SuggestedActionAttachment = {
                  contentType: CONTENT_TYPE_SUGGESTEDACTION,
                  content: {
                    title: action.payload.activity.text || '',
                    actions: actionsArray
                  }
                }

                // Create field attachments with the newly created one if no attachment yet, or add to existing ones
                action.payload.activity.attachments === undefined
                  ? (action.payload.activity.attachments = [
                      suggestedActionAttachment
                    ])
                  : action.payload.activity.attachments.push(
                      suggestedActionAttachment
                    )
              }

              // Deal with text containing [data command] data to make them usable in text mode
              if (
                action.payload.activity?.text &&
                action.payload.activity.text.includes('{data-command=')
              ) {
                const splitText: Array<string> =
                  action.payload.activity.text.split('{data-command=')
                const dataForAttachment: Array<{
                  text: string
                  differentTextToSend: string
                }> = []
                for (let i = 1; i < splitText.length; i++) {
                  const text = splitText[i - 1]
                    .substring(
                      splitText[i - 1].lastIndexOf('\n')
                        ? splitText[i - 1].lastIndexOf('\n') + 1
                        : 0
                    )
                    .trim()
                  const replacement = splitText[i]
                    .substring(0, splitText[i].indexOf('}'))
                    .replaceAll('"', '')

                  dataForAttachment.push({
                    text: text,
                    differentTextToSend: replacement
                  })

                  // Remove text part from speak / text / htmlText
                  action.payload.activity.speak &&
                    (action.payload.activity.speak =
                      action.payload.activity.speak.replace(text, ''))

                  action.payload.activity.text =
                    action.payload.activity.text.replace(text, '')

                  action.payload.activity.htmlText =
                    action.payload.activity.htmlText.replace(text, '')
                }

                if (dataForAttachment.length > 0) {
                  const suggestionAttachment = {
                    contentType: CONTENT_TYPE_SUGGESTION,
                    content: {
                      suggestions: dataForAttachment,
                      showTutorial: false
                    }
                  }

                  // Create field attachment swith the newly created one if no attachment yet, or add to existing ones
                  action.payload.activity.attachments === undefined
                    ? (action.payload.activity.attachments = [
                        suggestionAttachment
                      ])
                    : action.payload.activity.attachments.push(
                        suggestionAttachment
                      )
                }

                // Remove all [data command] from speak / text / htmlText
                action.payload.activity.speak &&
                  (action.payload.activity.speak =
                    action.payload.activity.speak.replaceAll(
                      regexDataCommand,
                      ''
                    ))

                action.payload.activity.text =
                  action.payload.activity.text.replaceAll(regexDataCommand, '')

                action.payload.activity.htmlText =
                  action.payload.activity.htmlText.replaceAll(
                    regexDataCommand,
                    ''
                  )
              }

              addBotMessageActivity(action.payload.activity)
            } else if (
              action.payload?.activity?.type === 'message' &&
              action.payload.activity.from.role === 'user'
            ) {
              addActivityToQueue(action.payload.activity)
            } else if (
              action.payload?.activity?.type === 'event' &&
              action.payload.activity.from.role === 'bot'
            ) {
              addActivityToQueue(action.payload.activity)
            } else if (
              action.payload?.activity?.type === 'event' &&
              action.payload.activity.from.role === 'user'
            ) {
              addActivityToQueue(action.payload.activity)
            }

            break
          }
          case 'WEB_CHAT/START_DICTATE': {
            // Cancel playing utterance if there is one
            setCancel(true)
            break
          }
          case 'WEB_CHAT/SEND_MESSAGE': {
            // Cancel playing utterance if there is one
            setCancel(true)
            break
          }
          case 'WEB_CHAT/SUBMIT_SEND_BOX': {
            // Send event containing the speech retrieved from speech recognition
            const speech = action.payload?.channelData?.speech

            if (speech) {
              const speechEvent = new CustomEvent(
                'retorikSpeechRecognitionEnded',
                { detail: speech }
              )

              document.dispatchEvent(speechEvent)

              dispatch({
                type: 'WEB_CHAT/SEND_MESSAGE',
                payload: {
                  text: speech
                }
              })
            }
            break
          }
          default:
            break
        }

        // External event handler to listen for actions from outside the Composer component
        if (
          externalEventHandler &&
          externalEventHandler !== null &&
          typeof externalEventHandler === 'function'
        ) {
          const externalStopTrigger = externalEventHandler(action, dispatch)
          if (externalStopTrigger) {
            return
          }
        }

        return next(action)
      }
  )
}

export default createCustomStore
