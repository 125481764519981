import React, { useEffect } from 'react'
import { CurrentSubView, Mode, Routes } from '../../models/enums'
import type { ChatbotData } from '../../models/types'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { setCurrentSubView } from '../Contexts/utilsStore'
import HomeView from '../Views/HomeView'
import NewsView from '../Views/NewsView'
import WarningBeforeNewsView from '../Views/WarningBeforeNewsView'
import { useActivityStore } from '../Contexts/activityStore'

interface RetorikRouterProps {
  chatbotData: ChatbotData
  externalComponents?: Array<{ name: string; component: JSX.Element }>
}

const RetorikRouter = (props: RetorikRouterProps): JSX.Element => {
  const {
    configuration: { isUsedOnBorne }
  } = useRetorik()
  const mode = useRetorikStore((state) => state.mode)
  const { route, setRoute, setShowHomeAttachments } = useView()
  const activities = useActivityStore((state) => state.activities)
  const lastBotEvent = useActivityStore((state) => state.lastBotEventActivity)

  useEffect(() => {
    if (lastBotEvent) {
      switch (lastBotEvent.name) {
        case 'news.ShowFlashInfo':
          setRoute(Routes.News)
          break
        case 'weather.ShowWeather':
          setCurrentSubView(CurrentSubView.weather)
          break
        default:
          break
      }
    }
  }, [lastBotEvent])

  useEffect(() => {
    activities &&
      activities.length > 0 &&
      (activities[activities.length - 1].from?.role === 'bot' ||
        activities.some((a) => (a as any).name === 'setUser')) &&
      setShowHomeAttachments(undefined)
  }, [activities])

  return (
    <React.Fragment>
      {route === Routes.Home && <HomeView {...props} />}
      {route === Routes.News &&
        (mode === Mode.vocal || !!isUsedOnBorne ? (
          <NewsView {...props} />
        ) : (
          <WarningBeforeNewsView propsToTransfer={props} />
        ))}
    </React.Fragment>
  )
}

export default RetorikRouter
