// Check if the locale in the current browser is in the array of available locales
const checkLocaleFromBrowser = (allLanguages: string[]): string | undefined => {
  const navLang = navigator?.language
  if (navLang && allLanguages?.length) {
    let res: string | undefined
    // Navigator language can be 2-chars
    if (navLang.length === 2) {
      res = allLanguages.find((language) => {
        return language.split('-')[1]?.toLowerCase() === navLang.toLowerCase()
      })
    } else {
      res = allLanguages.find((language) => {
        return language.toLowerCase() === navLang.toLowerCase()
      })
    }

    if (res) return res
  }

  return undefined
}

export const checkLocale = (languageData, localeConfig): string => {
  // Use browser locale if config doesn't prevent it
  if (localeConfig?.getDefaultFromBrowser !== false) {
    const browserLocale: string | undefined = checkLocaleFromBrowser(
      languageData.all
    )
    if (browserLocale) return browserLocale
  }

  // Else, use default from languageData
  return languageData.default
}

export const getMainLocale = (locale: string): string => {
  const split = locale.split('-')
  return split.length > 0 ? split[1] : split[0]
}
