import React, { useMemo } from 'react'
import { useList } from '../../../Contexts/ListContext'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import CheckBox from '../../../Utils/Inputs/CheckBox'
import { FilterItem } from '../../../../models/filterTypes'

type SubFilterProps = {
  className?: string
  filterItem: FilterItem
  parent: {
    id: string
    title: string
  }
}

const SubFilter = ({
  className,
  filterItem,
  parent
}: SubFilterProps): JSX.Element => {
  const { selectedFilters, processFiltersOnChange } = useList()
  const { locale } = useLocaleStore((state) => ({
    locale: state.locale
  }))

  const checked = useMemo<boolean>(() => {
    if (selectedFilters.length) {
      const check = selectedFilters.filter(
        (filter) =>
          filter.title === parent.title &&
          filter.filterItems.find(
            (item) =>
              item.name === filterItem.name && item.value === filterItem.value
          ) !== undefined
      )

      return !!check.length
    }

    return false
  }, [selectedFilters])

  return (
    <div
      className={`rf-w-full rf-pt-4 large:rf-py-1 rf-flex rf-justify-between rf-items-center ${className}`}
    >
      {capitalizeFirstLetter(filterItem.name, locale)}
      <CheckBox
        className='rf-rounded large:rf-ml-8'
        iconClassName='rf-w-4 rf-h-4 large:rf-p-min1 large:rf-w-6 large:rf-h-6'
        checked={checked}
        onClick={(): void =>
          processFiltersOnChange([
            {
              ...parent,
              filterItems: [filterItem]
            }
          ])
        }
      />
    </div>
  )
}

export default SubFilter
