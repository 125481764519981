import React, { useEffect, useState } from 'react'
import DisplayFullScreenButton from '../AnswerPanel/DisplayFullScreenButton'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useView } from '../Contexts/ViewContext'
import { DeviceType } from '../../models/enums'

interface ImageAttachmentProps {
  url: string
  title?: string
  alt?: string
  isMiniature?: boolean
  height?: number
}

const ImageAttachment = ({
  url,
  title,
  alt,
  isMiniature,
  height
}: ImageAttachmentProps): JSX.Element => {
  const displaySubtitles = useRetorikStore((state) => state.displaySubtitles)
  const { currentHeight, currentDeviceType } = useView()
  const [maxHeight, setMaxHeight] = useState<number>(0)

  useEffect(() => {
    if (currentHeight && currentDeviceType) {
      switch (currentDeviceType) {
        case DeviceType.mobile:
        case DeviceType.widget:
          setMaxHeight(
            Math.floor(currentHeight * (displaySubtitles ? 0.34 : 0.5))
          )
          break
        case DeviceType.landscape:
          setMaxHeight(Math.floor(currentHeight * 0.67))
          break
        case DeviceType.borne:
          setMaxHeight(Math.floor(currentHeight * 0.34))
          break
        default:
          break
      }
    }
  }, [currentHeight, currentDeviceType, displaySubtitles])

  return (
    <div
      className='rf-w-full rf-p-4 rf-flex rf-flex-row rf-items-center rf-justify-center rf-rounded rf-bg-cardFrameBackground'
      style={{
        height: height,
        touchAction: 'pan-y'
      }}
    >
      <DisplayFullScreenButton url={url} />
      {isMiniature ? (
        <React.Fragment>
          <div className='rf-w-1/4' />
          <img
            className='rf-max-w-1/2'
            src={url}
            alt={alt || 'image'}
            style={{
              maxHeight: `calc(${maxHeight}px - 3rem)`
            }}
          />
          <div className='rf-w-1/4' />
        </React.Fragment>
      ) : (
        <img
          className='rf-max-w-full'
          src={url}
          alt={alt || 'image'}
          style={{
            maxHeight: `calc(${maxHeight}px - 3rem)`
          }}
        />
      )}
      {title && (
        <div className='rf-absolute rf-bottom-0 rf-py-2 rf-text-center rf-text-sm large:rf-text-base rf-text-cardFrameText rf-bg-cardFrameBackground rf-bg-opacity-80 rf-rounded-b rf-w-full'>
          {title}
        </div>
      )}
    </div>
  )
}

export default ImageAttachment
