import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const FileIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19.908 23.226'
      className={className || 'rf-h-6 rf-w-6'}
    >
      <path
        d='M21.6,7.129a3.016,3.016,0,0,1,.622.985,2.991,2.991,0,0,1,.259,1.141V24.185a1.239,1.239,0,0,1-1.244,1.244H3.815a1.239,1.239,0,0,1-1.244-1.244V3.448A1.239,1.239,0,0,1,3.815,2.2H15.428a2.992,2.992,0,0,1,1.141.259,3.016,3.016,0,0,1,.985.622ZM15.843,3.966V8.839h4.873a1.416,1.416,0,0,0-.285-.531L16.374,4.251a1.416,1.416,0,0,0-.531-.285Zm4.977,19.8V10.5H15.428a1.239,1.239,0,0,1-1.244-1.244V3.862H4.23V23.77H20.82Z'
        transform='translate(-2.571 -2.203)'
        fill={color || '#00d7ff'}
      />
    </svg>
  )
}

export default FileIcon
