import BorneParametersIcon from './BorneParametersIcon'
import HistoryIcon from './HistoryIcon'
import LanguageIcon from './LanguageIcon'
import ParametersIcon from './ParametersIcon'
import TutorialIcon from './TutorialIcon'

export {
  BorneParametersIcon,
  HistoryIcon,
  LanguageIcon,
  ParametersIcon,
  TutorialIcon
}
