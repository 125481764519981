import CommentsPennantIcon from './CommentsPennantIcon'
import FavoriteIcon from './FavoriteIcon'
import FavoriteLightIcon from './FavoriteLightIcon'
import PhoneIcon from './PhoneIcon'
import PhotoCameraIcon from './PhotoCameraIcon'
import PrintIcon from './PrintIcon'
import RoundIcon from './RoundIcon'

export {
  CommentsPennantIcon,
  FavoriteIcon,
  FavoriteLightIcon,
  PhoneIcon,
  PhotoCameraIcon,
  PrintIcon,
  RoundIcon
}
