import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { CloseIcon } from '../Icons/Miscellaneous'

interface CloseButtonProps {
  onClick: (event?: React.MouseEvent) => void
  label?: string
  className?: string
  showLabel?: boolean
}

const CloseButton = ({
  onClick,
  label,
  className,
  showLabel
}: CloseButtonProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  return (
    <button
      aria-label={label || translation.common.close}
      onClick={onClick}
      className={`${className} rf-absolute rf-flex rf-flex-col rf-items-center rf-right-0 rf-top-0 rf-p-2`}
    >
      <CloseIcon className='rf-h-6 rf-w-6' />
      {showLabel && (
        <div className='rf-text-[0.5rem] rf-uppercase'>
          {label || translation.common.close}
        </div>
      )}
    </button>
  )
}

export default CloseButton
