{
  "_comment": "[CI-LOCKED] Indholdet af denne fil er låst. Bidrag er velkomne, men valideringen af ændringer bliver muligvis forsinket.",
  "ACCESS_KEY_ALT": "Alt Skift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control option $1",
  "ACTIVITY_BOT_SAID_ALT": "Robotten $1 sagde:",
  "ACTIVITY_YOU_SAID_ALT": "Du sagde:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Vedhæftet af robot:",
  "ACTIVITY_ERROR_BOX_TITLE": "Fejlmeddelelse",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Klik for at interagere.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Meddelelsen er interaktiv.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Ét eller flere links i meddelelsen.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Afsendelse mislykkedes.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Du vedhæftede:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 vedhæftet fil.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 vedhæftede filer.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 vedhæftede filer.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 vedhæftede filer.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 vedhæftede filer.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Afsendelse mislykkedes. [Prøv igen][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Sender",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Sendt kl. $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Lige nu",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "For en time siden",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "For et minut siden",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "I dag",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "I går",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Fortolkningsfejl i AdaptiveCard",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Renderingsfejl i AdaptiveCard",
  "ATTACHMENT_AUDIO": "Et lydklip.",
  "ATTACHMENT_CARD": "Et kort: $1 $2 $3",
  "ATTACHMENT_FILE": "En fil: $1",
  "ATTACHMENT_IMAGE": "Et billede",
  "ATTACHMENT_TEXT": "En tekst: $1",
  "ATTACHMENT_VIDEO": "Et videoklip",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Næste",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Forrige",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Forbundet",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Opretter forbindelse ...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Det var ikke muligt at oprette forbindelse.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Der opstod en netværksafbrydelse. Opretter forbindelse igen ...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Gengivelsesfejl. Kontrollér konsollen, eller kontakt robotudvikleren.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Det tager længere tid end normalt at oprette forbindelse.",
  "CONNECTIVITY_STATUS_ALT": "Forbindelsesstatus: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Download filen '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Hent filen '$1', som fylder $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1', som fylder $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter-tast",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Adgangselementer i meddelelse",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Chathistorik",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape-tast",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Afslut meddelelse",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabulatortast\nSkift + tabulatortast",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Flyt mellem elementer i en meddelelse",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Pil op-tast\nPil ned-tast",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Flyt mellem meddelelser",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter-tast",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Udfør handling",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabulatortast\nSkift + tabulatortast",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Flyt mellem elementer i chatvinduet",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Chatvindue",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Luk",
  "KEYBOARD_HELP_HEADER": "Tastaturets kontrolelementer",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Åbner i et nyt vindue, eksternt.",
  "RECEIPT_CARD_TAX": "Moms",
  "RECEIPT_CARD_TOTAL": "I alt",
  "RECEIPT_CARD_VAT": "Moms",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Der kan ikke sendes en tom meddelelse.",
  "SPEECH_INPUT_LISTENING": "Lytter ...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon Fra",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon Til",
  "SPEECH_INPUT_STARTING": "Starter ...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Næste",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Forrige",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Foreslåede handlinger",
  "TEXT_INPUT_ALT": "Meddelelsesinputfelt",
  "TEXT_INPUT_PLACEHOLDER": "Skriv din meddelelse",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Send",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Tal",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Overfør fil",
  "TOAST_ACCORDION_FEW": "$1 Meddelelser: Klik her for at få vist detaljer",
  "TOAST_ACCORDION_MANY": "$1 Meddelelser: Klik her for at få vist detaljer",
  "TOAST_ACCORDION_OTHER": "$1 Meddelelser: Klik her for at få vist detaljer",
  "TOAST_ACCORDION_TWO": "$1 Meddelelser: Klik her for at få vist detaljer",
  "TOAST_ALT_ERROR": "Fejl",
  "TOAST_ALT_INFO": "Oplysninger",
  "TOAST_ALT_SUCCESS": "Fuldført",
  "TOAST_ALT_WARN": "Advarsel!",
  "TOAST_DISMISS_BUTTON": "Afvis",
  "TOAST_TITLE_ALT": "Meddelelse",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Chat-historik. Tryk på piletasterne for at navigere.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "chathistorik",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Meddelelsen er interaktiv. Tryk på Skift og tabulatortast 2 til 3 gange for at skifte til chathistorikken. Klik derefter på meddelelsen for at interagere.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Ét eller flere links i meddelelsen. Tryk på Skift og tabulatortast 2 til 3 gange for at skifte til chathistorikken. Klik derefter på meddelelsen for at interagere.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Meddelelsen har foreslåede handlinger. Tryk på skift tab 2 til 3 gange for at vælge dem.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Meddelelsen har foreslåede handlinger. Tryk på $1 for at vælge dem.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Meddelelsen blev ikke sendt.",
  "TRANSCRIPT_MORE_MESSAGES": "Flere meddelelser",
  "TRANSCRIPT_NEW_MESSAGES": "Nye meddelelser",
  "TRANSCRIPT_TERMINATOR_TEXT": "Slut på chathistorik",
  "TYPING_INDICATOR_ALT": "Viser indtastningsindikator",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 skriver.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 og andre skriver."
}