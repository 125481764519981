{
  "_comment": "[CI-LOCKED] Бұл файлдың мазмұны құлыпталған. Жарналар қабылданады, бірақ өзгертулерді тексеру кейінге қалдырылуы мүмкін.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "$1 боттың айтуы бойынша:",
  "ACTIVITY_YOU_SAID_ALT": "Сіздің айтуы бойынша:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Бекітілген бот:",
  "ACTIVITY_ERROR_BOX_TITLE": "Қате туралы хабар",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Өзара әрекеттесу үшін басыңыз.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Хабар интерактивті.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Хабардағы бір немесе бірнеше сілтеме.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Жіберілмеді.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Сіз бекіттіңіз:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 тіркеме.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 тіркемелері.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 тіркемелері.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 тіркемелері.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 тіркемелері.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Жіберу сәтсіз аяқталды. (Қайталау][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Жіберілуде",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Сағат $1 жіберілген",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Дәл қазір",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "1 сағат бұрын",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "1 минут бұрын",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Бүгін",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Кеше",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Бейімделгіш картаны талдау қатесі",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Бейімделгіш картаны көрсету қатесі",
  "ATTACHMENT_AUDIO": "Дыбысклип.",
  "ATTACHMENT_CARD": "Карта: $1 $2 $3",
  "ATTACHMENT_FILE": "Файл: $1",
  "ATTACHMENT_IMAGE": "Кескін",
  "ATTACHMENT_TEXT": "Мәтін: $1",
  "ATTACHMENT_VIDEO": "Бейнеклип",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Келесі",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Алдыңғы",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Қосылған",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Қосылуда...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Қосу мүмкін емес.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Желі жұмысында іркіліс болды. Қайта қосылуда…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Көрсету қатесі. Консольді тексеріңіз немесе бот әзірлеушісімен хабарласыңыз.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Қосылу әдеттегіден ұзақ уақыт алуда.",
  "CONNECTIVITY_STATUS_ALT": "Қосылым күйі: $1",
  "FILE_CONTENT_ALT": "«$1»",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "$1 файлын жүктеп алу",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "$2 өлшемді $1 файлын жүктеу",
  "FILE_CONTENT_WITH_SIZE_ALT": "$2 өлшемді $1",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter пернесі",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Хабарламадағы элементтерге қол жеткізу",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Сөйлесу журналы",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape пернесі",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Хабардан шығу",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab пернесі\nShift + tab пернесі",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Хабарламадағы элементтер арасында жылжу",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Жоғары көрсеткі пернесі\nТөмен көрсеткі пернесі",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Хабарламалар арасында жылжу",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter пернесі",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Әрекет жасау",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab пернесі\nShift + tab пернесі",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Сөйлесу терезесіндегі элементтер арасында жылжу",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Сөйлесу терезесі",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Жабу",
  "KEYBOARD_HELP_HEADER": "Пернетақтаның басқару элементтері",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Жаңа терезеде ашады; сыртқы.",
  "RECEIPT_CARD_TAX": "Салық",
  "RECEIPT_CARD_TOTAL": "Барлығы",
  "RECEIPT_CARD_VAT": "VAT",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Бос хабар жіберу мүмкін емес.",
  "SPEECH_INPUT_LISTENING": "Тыңдалуда...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Микрофон өшірулі",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Микрофон",
  "SPEECH_INPUT_STARTING": "Басталуда...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Келесі",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Алдыңғы",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Ұсынылған әрекеттер",
  "TEXT_INPUT_ALT": "Хабарды енгізу өрісі",
  "TEXT_INPUT_PLACEHOLDER": "Хабарды теріңіз",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Жіберу",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Сөйлеу",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Файлды кері жүктеу",
  "TOAST_ACCORDION_FEW": "$1 Хабарландырулар: мәліметтерді көру үшін осы жерді басыңыз",
  "TOAST_ACCORDION_MANY": "$1 Хабарландырулар: мәліметтерді көру үшін осы жерді басыңыз",
  "TOAST_ACCORDION_OTHER": "$1 Хабарландырулар: мәліметтерді көру үшін осы жерді басыңыз",
  "TOAST_ACCORDION_TWO": "$1 Хабарландырулар: мәліметтерді көру үшін осы жерді басыңыз",
  "TOAST_ALT_ERROR": "Қате",
  "TOAST_ALT_INFO": "Ақпарат",
  "TOAST_ALT_SUCCESS": "Сәтті орындалды",
  "TOAST_ALT_WARN": "Ескерту",
  "TOAST_DISMISS_BUTTON": "Жою",
  "TOAST_TITLE_ALT": "Хабарландыру",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Сөйлесу журналы, шарлау үшін көрсеткі пернелерін басыңыз.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "сөйлесу журналы",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Хабарлама интерактивті. Сөйлесу журналына ауысу үшін shift tab пернесін 2-3 рет басыңыз. Одан кейін өзара әрекеттесу үшін хабарламаны басыңыз.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Хабарламадағы бір немесе бірнеше сілтеме. Сөйлесу журналына ауысу үшін shift tab пернесін 2-3 рет басыңыз. Одан кейін өзара әрекеттесу үшін хабарламаны басыңыз.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Хабарда ұсынылған әрекеттер бар. Оларды таңдау үшін Shift және Tab пернелерін 2-3 рет басыңыз.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Хабарда ұсынылған әрекеттер бар. Оларды таңдау үшін $1 пернесін басыңыз.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Хабар жіберілмеді.",
  "TRANSCRIPT_MORE_MESSAGES": "Қосымша хабарлар",
  "TRANSCRIPT_NEW_MESSAGES": "Жаңа хабарлар",
  "TRANSCRIPT_TERMINATOR_TEXT": "Сөйлесу журналының соңы",
  "TYPING_INDICATOR_ALT": "Мәтін теру индикаторын көрсету",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 теруде.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 және басқалар теруде."
}