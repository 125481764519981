import React, { useEffect, useState } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { useView } from '../../Contexts/ViewContext'
import { setFullScreenImage } from '../../Contexts/utilsStore'
import { Mode } from '../../../models/enums'
import useFullscreenImage from '../../../hooks/useFullscreenImage'
import Template from '../../Templates/FullScreenRemoteClosing'

const FullScreenImage = (): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const fullScreenImage = useFullscreenImage()
  const mode = useRetorikStore((state) => state.mode)
  const { isMobile } = useView()
  const [content, setContent] = useState<string | undefined>()

  useEffect(() => {
    setContent(fullScreenImage)
  }, [fullScreenImage])

  const handleClose = (): void => {
    setFullScreenImage(undefined)
  }

  return content ? (
    <Template
      handleClose={handleClose}
      mobileReturnTitle={translation.menu.backconversation}
    >
      <div
        className={`rf-h-full rf-w-full ${
          isMobile ? (mode === Mode.text ? 'rf-mb-28' : 'rf-mb-16') : 'rf-pt-12'
        } rf-px-8 rf-pb-12 rf-flex rf-justify-center rf-items-center`}
      >
        <img className='rf-max-h-full rf-max-w-full' src={content} />
      </div>
    </Template>
  ) : (
    <React.Fragment />
  )
}

export default FullScreenImage
