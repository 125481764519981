{
  "_comment": "[CI-LOCKED] תוכן קובץ זה נעול. תרומות יתקבלו בברכה, אך אימות השינויים עשוי להתעכב.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "תוכנית ה- Bot‏ $1 אמרה:",
  "ACTIVITY_YOU_SAID_ALT": "אמרת:",
  "ACTIVITY_BOT_ATTACHED_ALT": "תוכנית Bot צירפה:",
  "ACTIVITY_ERROR_BOX_TITLE": "הודעת שגיאה",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "לחץ כדי לקיים אינטראקציה.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "ההודעה היא אינטראקטיבית.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "יש קישור אחד או יותר בהודעה.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "השליחה נכשלה.",
  "ACTIVITY_YOU_ATTACHED_ALT": "צירפת:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "קובץ מצורף אחד.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 קבצים מצורפים.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 קבצים מצורפים.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 קבצים מצורפים.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 קבצים מצורפים.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "השליחה נכשלה. [נסה שנית][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "שולח",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "נשלח בשעה $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "ממש עכשיו",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "לפני שעה",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "לפני דקה",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "היום",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "אתמול",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "שגיאה בניתוח מבנה טקסט של כרטיס מסתגל",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "שגיאה בעיבוד כרטיס מסתגל",
  "ATTACHMENT_AUDIO": "קליפ שמע.",
  "ATTACHMENT_CARD": "כרטיס: $1 $2 $3",
  "ATTACHMENT_FILE": "קובץ: $1",
  "ATTACHMENT_IMAGE": "תמונה",
  "ATTACHMENT_TEXT": "טקסט: $1",
  "ATTACHMENT_VIDEO": "קליפ וידאו",
  "CAROUSEL_FLIPPER_NEXT_ALT": "הבא",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "הקודם",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "מחובר",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "מתחבר...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "לא ניתן להתחבר.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "אירעה הפרעה ברשת. מתחבר מחדש...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "שגיאת עיבוד. בדוק את המסוף או פנה אל מפתח הבוט.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "ההתחברות נמשכת זמן רב מהרגיל.",
  "CONNECTIVITY_STATUS_ALT": "מצב קישוריות: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "הורד את הקובץ '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "הורד את הקובץ '$1' בגודל $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "‏'$1' בגודל $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "מקש Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "גישה לפריטים בהודעה",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "היסטוריית צ'אט",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "מקש Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "יציאה מההודעה",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "מקש Tab\nמקש Shift + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "מעבר בין פריטים בהודעה",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "מקש חץ למעלה\nמקש חץ למטה",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "מעבר בין הודעות",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "מקש Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "ביצוע פעולה",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "מקש Tab\nמקש Shift + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "מעבר בין פריטים בחלון הצ'אט",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "חלון צ'אט",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "סגור",
  "KEYBOARD_HELP_HEADER": "פקדי לוח מקשים",
  "MARKDOWN_EXTERNAL_LINK_ALT": "נפתח בחלון חדש; חיצוני.",
  "RECEIPT_CARD_TAX": "מס",
  "RECEIPT_CARD_TOTAL": "סך הכל",
  "RECEIPT_CARD_VAT": "מע\"מ",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "לא ניתן לשלוח הודעה ריקה.",
  "SPEECH_INPUT_LISTENING": "מאזין…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "כבה מיקרופון",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "הפעל מיקרופון",
  "SPEECH_INPUT_STARTING": "מתחיל...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "הבא",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "הקודם",
  "SUGGESTED_ACTIONS_LABEL_ALT": "פעולות מוצעות",
  "TEXT_INPUT_ALT": "תיבה להזנת הודעה",
  "TEXT_INPUT_PLACEHOLDER": "הקלד את ההודעה שלך",
  "TEXT_INPUT_SEND_BUTTON_ALT": "שלח",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "דבר",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "העלה קובץ",
  "TOAST_ACCORDION_FEW": "$1 הודעות: לחץ כאן להצגת פרטים",
  "TOAST_ACCORDION_MANY": "$1 הודעות: לחץ כאן להצגת פרטים",
  "TOAST_ACCORDION_OTHER": "$1 הודעות: לחץ כאן להצגת פרטים",
  "TOAST_ACCORDION_TWO": "$1 הודעות: לחץ כאן להצגת פרטים",
  "TOAST_ALT_ERROR": "‏‏שגיאה",
  "TOAST_ALT_INFO": "מידע",
  "TOAST_ALT_SUCCESS": "הצלחה",
  "TOAST_ALT_WARN": "אזהרה",
  "TOAST_DISMISS_BUTTON": "בטל",
  "TOAST_TITLE_ALT": "הודעה",
  "TRANSCRIPT_ARIA_LABEL_ALT": "היסטוריית צ'אט, הקש על מקשי החצים כדי לנווט.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "היסטוריית צ'אט",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "ההודעה היא אינטראקטיבית. הקש על המקשים Shift ו- Tab פעמיים עד שלוש פעמים כדי לעבור להיסטוריית הצ'אט. לאחר מכן לחץ על ההודעה כדי ליצור אינטראקציה.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "יש קישור אחד או יותר בהודעה. הקש על המקשים Shift ו- Tab פעמיים עד שלוש פעמים כדי לעבור להיסטוריית הצ'אט. לאחר מכן לחץ על ההודעה כדי ליצור אינטראקציה.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "ההודעה כוללת פעולות מוצעות. הקש Shift Tab פעמיים או שלוש פעמים כדי לבחור אותן.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "ההודעה כוללת פעולות מוצעות. הקש $1 כדי לבחור אותן.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "שליחת ההודעה נכשלה.",
  "TRANSCRIPT_MORE_MESSAGES": "הודעות נוספות",
  "TRANSCRIPT_NEW_MESSAGES": "הודעות חדשות",
  "TRANSCRIPT_TERMINATOR_TEXT": "סוף היסטוריית הצ'אט",
  "TYPING_INDICATOR_ALT": "מחוון שמציג הקלדה",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 מקליד/ה.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 ואחרים מקלידים/ות."
}