import React, { useEffect, useRef, useState } from 'react'
import { useList } from '../../Contexts/ListContext'
import { useView } from '../../Contexts/ViewContext'
import type { Filter } from '../../../models/filterTypes'
import { FeatherFilterIcon } from '../../Icons/Miscellaneous'
import { useLocaleStore } from '../../Contexts/localeStore'
import MobileFilterPage from './Filter/MobileFilterPage'
import MobileSubFilterPage from './Filter/MobileSubFilterPage'
import MobileDisplayResult from './Filter/MobileDisplayResults'
import { BackButton } from '../../Menu/Common'
import { DeviceType } from '../../../models/enums'
import preventEvents from '../../../utils/preventEvents'

type FilterMobileProps = {
  className?: string
  setFiltersOpened: (opened: boolean) => void
  handleClose: () => void
}

const FilterMobile = ({
  className,
  setFiltersOpened,
  handleClose
}: FilterMobileProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const { resetFilters } = useList()
  const { currentDeviceType } = useView()
  const [open, setOpen] = useState<boolean>(false)
  const [currentOpened, setCurrentOpened] = useState<Filter | undefined>(
    undefined
  )
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef?.current) {
      currentDeviceType === DeviceType.widget && preventEvents(null, divRef)
    }
  }, [divRef?.current])

  useEffect(() => {
    setFiltersOpened(open)
  }, [open])

  const handleClick = (): void => {
    setOpen(true)
  }

  const handleReturn = (): void => {
    setOpen(false)
  }

  const handleReset = (): void => {
    setOpen(false)
    resetFilters && resetFilters()
  }

  const closeCurrentSubFilters = (): void => {
    setCurrentOpened(undefined)
  }

  const handleDisplayResults = (): void => {
    setCurrentOpened(undefined)
    setOpen(false)
  }

  return !open ? (
    <div
      className={`large:rf-hidden rf-w-full rf-py-4 rf-px-4 rf-flex rf-flex-row rf-justify-between rf-items-center rf-bg-truewhite rf-shadow-[0_0_15px_0_silver] ${className}`}
    >
      <BackButton
        title={translation.menu.backconversation}
        handleBack={handleClose}
        className='rf-relative rf-text-size-auto'
      />
      <button
        className='rf-px-4 rf-py-1 rf-flex rf-flex-row rf-items-center rf-border rf-rounded-lg rf-border-black'
        onClick={handleClick}
      >
        <FeatherFilterIcon className='rf-h-4 rf-w-4' />
        <div className='rf-ml-2'>{translation.common.filter}</div>
      </button>
    </div>
  ) : (
    <div
      ref={divRef}
      className='rf-absolute rf-z-util large:rf-hidden rf-top-0 rf-left-0 rf-w-full rf-h-full rf-overflow-y-scroll rf-scrollbar-hidden rf-bg-truewhite rf-flex rf-flex-col rf-justify-between'
    >
      <div className='rf-flex rf-flex-col rf-justify-start rf-align-center'>
        {currentOpened ? (
          <MobileSubFilterPage
            filter={currentOpened}
            close={closeCurrentSubFilters}
            resetFilters={handleReset}
          />
        ) : (
          <MobileFilterPage
            setCurrentOpened={setCurrentOpened}
            close={handleReturn}
            resetFilters={handleReset}
          />
        )}
      </div>
      {/* Button to display results */}
      <MobileDisplayResult onClick={handleDisplayResults} />
    </div>
  )
}

export default FilterMobile
