import React from 'react'

interface QRCodeComponentProps {
  src: string
  alt?: string
}

const QRCodeComponent = React.forwardRef<
  HTMLImageElement,
  QRCodeComponentProps
>(({ src, alt }, ref) => {
  return (
    <img
      ref={ref}
      src={src}
      className='rf-h-full rf-max-h-qrcode'
      alt={alt || 'QR Code'}
    />
  )
})

export default QRCodeComponent
