import { useEffect, useState } from 'react'
import { hooks } from 'botframework-webchat'
import { useRetorik } from '../Contexts/RetorikContext'
import {
  setDisplaySubtitles,
  setUserAlreadySet /*, setLoaderClosed */,
  useRetorikStore
} from '../Contexts/retorikStore'
import {
  useUtilsStore,
  sendActivity as sendUtilActivity,
  sendEvent as sendUtilEvent
} from '../Contexts/utilsStore'
import { useLocaleStore } from '../Contexts/localeStore'
import useToggleMicrophone from '../../hooks/useToggleMicrophone'
import { UserData } from '../../models/types'

const { usePostActivity, useSendEvent } = hooks

interface SendActivityEventListenerProps {
  isRetorikNews: boolean
  hasConversationCookie: boolean
  userData: UserData
  changedLayout: boolean
}

interface EventReceived {
  type: EventsReceived
  detail?: any
}

enum EventsReceived {
  'retorikSendActivity' = 1,
  'retorikSendEvent',
  'retorikStartListening',
  'retorikDisplaySubtitles'
}

const SendActivityEventListener = ({
  isRetorikNews,
  hasConversationCookie,
  userData,
  changedLayout
}: SendActivityEventListenerProps): null => {
  const sendPostActivity = usePostActivity()
  const sendEvent = useSendEvent()
  const locale = useLocaleStore((state) => state.locale)
  const activityToSend = useUtilsStore((state) => state.activityToSend)
  const eventToSend = useUtilsStore((state) => state.eventToSend)
  const {
    appAvailable,
    loaderClosed,
    configuration: { skipWelcome }
  } = useRetorik()
  const toggleMicrophone = useToggleMicrophone()

  const [eventReceived, setEventReceived] = useState<EventReceived | null>(null)
  const userAlreadySet: boolean = useRetorikStore(
    (state) => state.userAlreadySet
  )

  useEffect(() => {
    if (eventReceived) {
      try {
        switch (eventReceived.type) {
          case EventsReceived.retorikSendActivity:
            eventReceived.detail && sendPostActivity(eventReceived.detail)
            break
          case EventsReceived.retorikDisplaySubtitles:
            eventReceived.detail && setDisplaySubtitles(!!eventReceived.detail)
            break
          case EventsReceived.retorikStartListening:
            toggleMicrophone()
            break
          case EventsReceived.retorikSendEvent:
            eventReceived.detail &&
              sendEvent(eventReceived.detail.name, eventReceived.detail.value)
        }
      } catch (e) {
        console.warn('Error on event reception')
      }
    }
  }, [eventReceived])

  const handleEvents = (event: CustomEvent): void => {
    setEventReceived({
      type: EventsReceived[event.type],
      detail: event.detail
    })
  }

  useEffect(() => {
    document.addEventListener('retorikSendActivity', handleEvents)
    document.addEventListener('retorikSendEvent', handleEvents)
    document.addEventListener('retorikStartListening', handleEvents)
    document.addEventListener('retorikDisplaySubtitles', handleEvents)

    return (): void => {
      document.removeEventListener('retorikSendActivity', handleEvents)
      document.removeEventListener('retorikStartListening', handleEvents)
      document.removeEventListener('retorikDisplaySubtitles', handleEvents)
    }
  }, [])

  useEffect(() => {
    if (isRetorikNews || hasConversationCookie) {
      console.log(
        'isRetorikNews || hasConversationCookie',
        isRetorikNews,
        hasConversationCookie
      )
      setUserAlreadySet(true)
    } else if (!userAlreadySet && (appAvailable || loaderClosed)) {
      console.log('changedLayout', changedLayout, appAvailable, loaderClosed)
      setUserAlreadySet(true)
      setTimeout(() => {
        sendEvent('setUser', {
          user: userData,
          skipWelcome: !!skipWelcome
        })
      }, 1000)
    }
  }, [appAvailable, loaderClosed])

  useEffect(() => {
    if (activityToSend) {
      if (
        activityToSend.type === 'message' &&
        activityToSend.text &&
        typeof activityToSend.text !== 'string'
      ) {
        const tempActivity = { ...activityToSend }
        tempActivity.text = activityToSend.text[locale] || ''
        sendPostActivity(tempActivity)
      } else {
        sendPostActivity(activityToSend)
      }

      sendUtilActivity(undefined)
    }
  }, [activityToSend])

  useEffect(() => {
    if (eventToSend) {
      if (eventToSend.type === 'event' && eventToSend.name) {
        sendEvent(eventToSend.name, eventToSend.value)
      }

      sendUtilEvent(undefined)
    }
  }, [eventToSend])

  return null
}

export default SendActivityEventListener
