import { CDNCharactersAddress } from '../models/constants'

const characters = {
  alice: `${CDNCharactersAddress}/long-shot/alice`,
  greg: `${CDNCharactersAddress}/long-shot/greg`,
  jeanmarc: `${CDNCharactersAddress}/jean-marc`,
  jessica: `${CDNCharactersAddress}/long-shot/jessica`,
  jessica_short: `${CDNCharactersAddress}/medium-shot/jessica`,
  ikaa: `${CDNCharactersAddress}/long-shot/ikaa`,
  karine: `${CDNCharactersAddress}/karine`,
  lea: `${CDNCharactersAddress}/lea`,
  prosper: `${CDNCharactersAddress}/prosper`,
  raphael: `${CDNCharactersAddress}/raphael`,
  rowan: `${CDNCharactersAddress}/long-shot/rowan`,
  rowan_short: `${CDNCharactersAddress}/medium-shot/rowan`,
  zahra: `${CDNCharactersAddress}/long-shot/zahra`
}

export { characters }
