import React from 'react'
import getDistanceAsString from '../../../utils/getDistanceAsString'
import getTimeAsString from '../../../utils/getTimeAsString'
import * as Icons from '../../Icons/MapIcons'

interface Summary {
  type: string
  distance?: number
  duration?: number
}

interface RoutesItemProps {
  summary: Summary
  chosen?: boolean
  color?: string
  onClick: (type: string) => void
}

const RoutesItem = ({
  summary,
  chosen,
  color,
  onClick
}: RoutesItemProps): JSX.Element => {
  return (
    <div
      className='rf-flex rf-flex-col rf-justify-end hover:rf-cursor-pointer'
      onClick={(): void => onClick(summary.type)}
    >
      <div className='rf-h-full rf-flex rf-flex-row rf-gap-3 rf-items-center'>
        <div className='rf-relative rf-flex rf-justify-center rf-items-center'>
          {/* Colored circle + triangle atop */}
          <div
            className='rf-absolute rf-w-9 rf-h-9 rf-rounded-max'
            style={{
              backgroundColor: chosen ? color : '#EFEDF0'
            }}
          />
          <div className='rf-absolute rf-w-full -rf-top-[1.15rem] rf-flex rf-justify-center'>
            <Icons.TriangleIcon
              className='rf-w-2 rf-h-2'
              color={chosen ? color : 'transparent'}
            />
          </div>

          {summary.type === 'drivingCar' && (
            <Icons.DrivingCarIcon
              className='rf-w-5 rf-z-util'
              color={chosen ? '#FFF' : '#535252'}
            />
          )}
          {summary.type === 'cyclingRegular' && (
            <Icons.CyclingRegularIcon
              className='rf-w-6 rf-z-util'
              color={chosen ? '#FFF' : '#535252'}
            />
          )}
          {summary.type === 'footWalking' && (
            <Icons.FootWalkingIcon
              className='rf-w-6 rf-z-util'
              color={chosen ? '#FFF' : '#535252'}
            />
          )}
        </div>
        <div className='rf-flex rf-flex-col rf-justify-center rf-items-start'>
          {/* Duration */}
          <div className='rf-whitespace-nowrap rf-font-bold'>
            {getTimeAsString(summary?.duration)}
          </div>

          {/* Distance */}
          <div className='rf-whitespace-nowrap'>
            {getDistanceAsString(summary?.distance)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoutesItem
