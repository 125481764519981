{
  "1": "pagina inicial",
  "2": "canal de informações ao vivo",
  "3": "clima",
  "4": "Números de emergência",
  "1000": "história",
  "1001": "tutorial",

  "common": {
    "all": "todos",
    "back": "voltar",
    "close": "fechar",
    "continue": "continuar",
    "download": "download",
    "downloadsheet": "baixe esta planilha",
    "downloadsheetmobile": "baixe esta planilha no smartphone",
    "filter": "filtro",
    "history": "histórico",
    "lang": "línguas",
    "menu": "cardápio",
    "mode": "moda",
    "needhelp": "Preciso de ajuda?",
    "parameters": "configurações",
    "print": "Imprimir",
    "printsheet": "Imprima esta folha",
    "result": "resultado",
    "results": "resultados",
    "singleLang": "linguagem",
    "sound": "dela",
    "submit": "enviar",
    "subtitle": "legenda",
    "tutorial": "tutorial",
    "validate": "validar"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "polícia",
    "firefighter": "bombeiros",
    "general": "general",
    "hospital": "Hospital",
    "homeless": "Morador de rua",
    "poison": "Centro de Controle de Venenos",
    "emergency": "Serviços de emergência"
  },

  "error": {
    "title": "ocorreu um erro",
    "button": "Reinicie o aplicativo"
  },

  "history": {
    "title": "histórico de conversas"
  },

  "inputs": {
    "choose": "Escolha uma resposta"
  },

  "language": {
    "change": "alterar o idioma",
    "select": "selecione o idioma de sua preferência"
  },

  "link": {
    "buttontext": "Veja o documento",
    "goto": "Ir para o endereço",
    "click": "Clique aqui",
    "scanQR": "digitalizando o QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Para uma experiência ideal,",
      "classic": "Para uma experiência mais clássica,",
      "vocal": "Falo cara a cara com o agente.",
      "text": "Eu me comunico por escrito com o agente."
    },
    "modes": {
      "vocal": "modo vocal",
      "text": "modo de texto"
    },
    "loaderValidation": "Iniciar conversa",
    "locale": "verificando o idioma",
    "supported": "verificando idiomas suportados",
    "addressdata": "recuperando dados"
  },

  "menu": {
    "message": "Digite sua mensagem",
    "allowMicrophone": "Permita que seu navegador use seu microfone para continuar",
    "backconversation": "de volta à conversa",
    "answer": " responde às suas perguntas"
  },

  "mobileLandscapePreventer": {
    "firstline": "Para uma experiência ideal,",
    "secondline": "vire sua tela"
  },

  "news": {
    "warningtext": "Você acessará o canal de notícias, o som do seu aparelho será ativado",
    "live": "ao vivo",
    "continuous": "continuamente"
  },

  "poi": {
    "comment": "avaliação do cliente",
    "route": "estrada",
    "nodata": "Nenhum conteúdo corresponde à sua pesquisa",
    "openinghours": "Horário de funcionamento",
    "prices": "Preços",
    "paymentmethods": "Métodos de Pagamento",
    "numberofplaces": "número de lugares",
    "openroutemobile": "abra a rota do Google Maps no smartphone",
    "FoodEstablishment": "Onde comer",
    "LodgingBusiness": "Onde dormir",
    "TouristDestination": "O que ver",
    "TouristAttraction": "O que fazer",
    "Product": "Produtos locais",
    "Service": "Serviços",
    "Event": "Eventos",
    "City": "Cidades",
    "Wine": "Vinhos"
  },

  "suggestion": {
    "consult": "Confira",
    "thetutorial": "o tutorial"
  },

  "template": {
    "reset": "reiniciar tudo",
    "showresults": "Mostrar os resultados",
    "suggestiontitle": "Sugestões de pesquisa",
    "reminder": "Não se esqueça, \n, posso responder às suas perguntas."
  }
}
