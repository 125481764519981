import { useEffect, useState } from 'react'
import { useRetorik } from '../components/Contexts/RetorikContext'
import { useActivityStore } from '../components/Contexts/activityStore'
import { useUtilsStore } from '../components/Contexts/utilsStore'
import type { DetailedAttachmentAction } from '../models/activityTypes'
import { mapDetailedDataFromContentItem } from '../utils/mapDetailedDataFromContentItem'
import { useLocaleStore } from '../components/Contexts/localeStore'

const useShowPOI = (): any => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const lastBotActivity = useActivityStore((state) => state.lastBotActivity)
  const triggers = useUtilsStore((state) => state.detailedAttachmentTriggers)
  const locale = useLocaleStore((state) => state.locale)
  const { baseAddress } = useRetorik()

  const [result, setResult] = useState<any>(undefined)

  useEffect(() => {
    if (lastBotActivity && lastBotActivity.value && triggers.length > 0) {
      let result: any
      triggers.forEach((trigger: DetailedAttachmentAction) => {
        if (lastBotActivity.type === trigger.type) {
          if (trigger.name && trigger.name === lastBotActivity.name) {
            if (trigger.contentType) {
              if (trigger.contentType === lastBotActivity.value?.contentType) {
                lastBotActivity.value.content.template = trigger.linkedComponent
                result = lastBotActivity.value.content
              }
            } else {
              const data = {
                id: lastBotActivity.value?.contentItemId,
                content: lastBotActivity.value?.content,
                contentType: lastBotActivity.value?.contentType,
                baseAddress: baseAddress,
                locale: locale
              }
              const checkData = mapDetailedDataFromContentItem(
                data,
                translation
              )
              if (checkData) {
                result = { ...checkData, template: trigger.linkedComponent }
              } else {
                lastBotActivity.value.template = trigger.linkedComponent
                result = lastBotActivity.value
              }
            }
          } else if (trigger.label && trigger.label === lastBotActivity.label) {
            if (trigger.contentType) {
              if (trigger.contentType === lastBotActivity.value?.contentType) {
                lastBotActivity.value.content.template = trigger.linkedComponent
                result = lastBotActivity.value.content
              }
            } else {
              lastBotActivity.value.template = trigger.linkedComponent
              result = lastBotActivity.value
            }
          }
        }
      })

      setResult(result)
    }
  }, [lastBotActivity])

  return result
}

export default useShowPOI
