import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import ParameterButton from './ParameterButton'
// import { useSendMessage } from 'botframework-webchat-api/lib/hooks'

interface TutorialButtonProps {
  handleBack?: () => void
}

const TutorialButton = ({ handleBack }: TutorialButtonProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  // const sendMessage = useSendMessage()

  const handleClick = (): void => {
    handleBack && handleBack()
    console.log('Launch tutorial')
    // sendMessage('conseil', 'speech')
  }

  return (
    <ParameterButton
      title={translation.common.tutorial}
      icon='tutorial'
      onClick={handleClick}
    />
  )
}

export default TutorialButton
