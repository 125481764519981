import type {
  Coordinates,
  POIContentType,
  AddressStructured,
  Review
} from '../models/attachmentTypes'
import type { TranslationFile } from '../models/translationsType'

const infoBannerContenTypes = ['foodestablishment', 'event']

const categoryConversion = {
  FoodEstablishment: 'Where to eat',
  LodgingBusiness: 'Where to sleep',
  TouristDestination: 'What to see',
  TouristAttraction: 'What to do',
  Product: 'Local products',
  Service: 'Services',
  Event: 'Events',
  City: 'Cities',
  Wine: 'Wines'
}

interface MappingProps {
  id?: string
  content: any
  contentType?: string
  baseAddress: string
  locale: string
}

interface Category {
  id: string
  label: string
}

interface OtherInformation {
  title: string
  content: string | Array<string>
}

const getCategoryFromContentType = (
  contentType: string | undefined,
  translation: TranslationFile
): Category | undefined => {
  if (contentType) {
    return {
      id: categoryConversion[contentType],
      label: translation.poi[contentType]
    }
  }

  return undefined
}

const mapDetailedDataFromContentItem = (
  data: MappingProps,
  translation: TranslationFile
): POIContentType | null => {
  if (data.id && data.content) {
    try {
      const content = data.content
      // Process medias
      const medias: any = []
      content.Thing?.Image?.Paths &&
        content.Thing.Image.Paths.length &&
        content.Thing?.Image?.Paths?.forEach((path) => {
          medias.push({
            url: `${data.baseAddress}media/${path}`
          })
        })

      // Processed address
      const address: AddressStructured = {
        addressLocality: content.Place?.AddressLocality?.Text || '',
        postalCode: content.Place?.PostalCode?.Text || '',
        street: content.Place?.StreetAddress?.Text || ''
      }

      // Processed coordinates
      const coordinates: Coordinates | undefined =
        content.Place?.Latitude?.Value && content.Place?.Longitude?.Value
          ? {
              latitude: content.Place.Latitude.Value,
              longitude: content.Place.Longitude.Value
            }
          : undefined

      // Processed review
      const review: Review = {
        author:
          content.Review?.Author || content.Review?.AuthorCapacity
            ? {
                name: content.Review?.Author,
                capacity: content.Review?.AuthorCapacity
              }
            : undefined,
        comment: content.Review?.ReviewBody || '',
        picture: content.Review?.Picture
          ? {
              url: content.Review.Picture,
              description: content.Review.PictureDescription || ''
            }
          : undefined
      }

      // Process other informations
      const otherInformations: Array<OtherInformation> = []
      // Opening hours
      content.LocalBusiness?.OpeningHours?.Text &&
        otherInformations.push({
          title: translation.poi.openinghours,
          content: content.LocalBusiness.OpeningHours.Text
        })
      // Prices
      content.LocalBusiness?.PriceRange?.Text &&
        otherInformations.push({
          title: translation.poi.prices,
          content: content.LocalBusiness.PriceRange.Text
        })
      // Payment methods
      content.LocalBusiness?.PaymentAccepted?.Values &&
        content.LocalBusiness.PaymentAccepted.Values.length &&
        otherInformations.push({
          title: translation.poi.paymentmethods,
          content: content.LocalBusiness.PaymentAccepted.Values
        })

      // Process information banner
      let infoBanner: string | undefined
      if (
        data.contentType &&
        infoBannerContenTypes.includes(data.contentType.toLowerCase())
      ) {
        switch (data.contentType.toLowerCase()) {
          case 'foodestablishment':
            content.Place?.MaximumAttendeeCapacity?.Value &&
              !isNaN(parseInt(content.Place?.MaximumAttendeeCapacity?.Value)) &&
              (infoBanner = `${translation.poi.numberofplaces} ${parseInt(
                content.Place?.MaximumAttendeeCapacity?.Value
              )}`)
            break
          case 'event':
            if (
              content.Event &&
              content.Event.StartDate?.Value &&
              content.Event.EndDate?.value
            ) {
              const startDate = new Date(content.Event.StartDate.Value)
              const endDate = new Date(content.Event.StartDate.Value)
              if (
                content.Event.StartDate.Value === content.Event.EndDate.value
              ) {
                infoBanner = `Le ${startDate.toLocaleDateString(data.locale)}`
              } else {
                infoBanner = `Du ${startDate.toLocaleDateString(
                  data.locale
                )} au ${endDate.toLocaleDateString(data.locale)}`
              }
            }
            break
        }
      }

      // Hack for Wines category -> TODO : remove it !
      let categoryWineTest = false
      const categories = content.Thing?.Categories?.Values
      const wineFilters = [
        'Blanc de blanc',
        'Rosé Champagne',
        'Vintages',
        'Cocktail Collection',
        'AUTHIGENE Collection'
      ]

      if (categories && categories.length) {
        categories.forEach(
          (category: string) =>
            wineFilters.includes(category) && (categoryWineTest = true)
        )
      }

      const mappedData: POIContentType = {
        identifier: data.id,
        title: content.Thing?.Name?.Text?.toString() || '',
        text: content.Thing?.Description?.Text?.toString() || '',
        addressStructured: address,
        contact: content.PointOfInterest?.Contact?.Text?.toString() || '',
        phoneNumber: content.Place?.Telephone?.Text
          ? content.Place.Telephone.Text.toString()
          : '',
        category: categoryWineTest
          ? {
              id: categoryConversion.Wine,
              label: translation.poi.Wine
            }
          : getCategoryFromContentType(content.contentType, translation),
        media: medias.length ? medias : undefined,
        customerReviews: content.Place?.AggregateRating?.Value,
        coordinates: coordinates,
        downloadUri: `${data.baseAddress}api/hospitality/${data.id}/pdf`,
        favorite: !!content.Thing?.Favorite?.Value,
        review: review,
        infoBanner: infoBanner,
        otherInformations: otherInformations
      }

      return mappedData
    } catch (e) {
      console.log(e)
      return null
    }
  }

  return null
}

export { mapDetailedDataFromContentItem }
