import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ExtendIcon = ({
  className,
  color,
  extended
}: IconDefaultProps & { extended: boolean }): JSX.Element => {
  if (extended) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20.018'
        height='19.382'
        viewBox='0 0 20.018 19.382'
      >
        <g
          id='Groupe_841'
          data-name='Groupe 841'
          transform='translate(3.415 3.891)'
        >
          <g id='PICTO'>
            <g
              id='Groupe_845'
              data-name='Groupe 845'
              transform='translate(0.003 -1)'
            >
              <g
                id='Groupe_843'
                data-name='Groupe 843'
                transform='translate(3.99 21.134) rotate(180)'
              >
                <line
                  id='Ligne_15'
                  data-name='Ligne 15'
                  y2='5.046'
                  transform='translate(0 7.031)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_16'
                  data-name='Ligne 16'
                  x1='5.04'
                  transform='translate(0 12.077)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_17'
                  data-name='Ligne 17'
                  x2='0.004'
                  y2='8.475'
                  transform='translate(5.99 6.057) rotate(45)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
              </g>
              <g
                id='Groupe_844'
                data-name='Groupe 844'
                transform='translate(9.162 -7.493)'
              >
                <line
                  id='Ligne_15-2'
                  data-name='Ligne 15'
                  y2='5.002'
                  transform='translate(0 7.031)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_16-2'
                  data-name='Ligne 16'
                  x1='5.067'
                  transform='translate(0 12.033)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_17-2'
                  data-name='Ligne 17'
                  x1='0.046'
                  y2='8.463'
                  transform='translate(5.984 6.024) rotate(45)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  } else {
    // return (
    //   <svg
    //     xmlns='http://www.w3.org/2000/svg'
    //     className={className || 'rf-h-6 rf-w-6'}
    //     fill='none'
    //     viewBox='0 0 24 24'
    //     stroke={color || 'currentColor'}
    //     strokeWidth={2}
    //   >
    //     <path
    //       strokeLinecap='round'
    //       strokeLinejoin='round'
    //       d='M6 18L18 6M6 6l12 12'
    //     />
    //   </svg>
    // )
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20.018'
        height='19.358'
        viewBox='0 0 20.018 19.358'
        className={className || 'rf-h-6 rf-w-6'}
      >
        <g
          id='Groupe_841'
          data-name='Groupe 841'
          transform='translate(3.415 3.891)'
        >
          <g id='PICTO'>
            <g
              id='Groupe_845'
              data-name='Groupe 845'
              transform='translate(0.003 -1)'
            >
              <g
                id='Groupe_843'
                data-name='Groupe 843'
                transform='translate(-2 3)'
              >
                <line
                  id='Ligne_15'
                  data-name='Ligne 15'
                  y2='5.046'
                  transform='translate(0 7.031)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_16'
                  data-name='Ligne 16'
                  x1='5.04'
                  transform='translate(0 12.077)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_17'
                  data-name='Ligne 17'
                  x2='0.004'
                  y2='8.475'
                  transform='translate(5.99 6.057) rotate(45)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
              </g>
              <g
                id='Groupe_844'
                data-name='Groupe 844'
                transform='translate(15.178 10.572) rotate(180)'
              >
                <line
                  id='Ligne_15-2'
                  data-name='Ligne 15'
                  y2='5.002'
                  transform='translate(0 7.031)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_16-2'
                  data-name='Ligne 16'
                  x1='5.067'
                  transform='translate(0 12.033)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
                <line
                  id='Ligne_17-2'
                  data-name='Ligne 17'
                  x1='0.046'
                  y2='8.463'
                  transform='translate(5.984 6.024) rotate(45)'
                  fill='none'
                  stroke={color || 'currentColor'}
                  strokeLinecap='round'
                  strokeWidth='2'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default ExtendIcon
