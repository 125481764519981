import React from 'react'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { ArrowLeftIcon } from '../../../Icons/ArrowIcons'

type MobileUpperBandProps = {
  title: string
  handleReset: (() => void) | undefined
  handleReturn: () => void
}

const MobileUpperBand = ({
  title,
  handleReset,
  handleReturn
}: MobileUpperBandProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  return (
    <div className='rf-w-full rf-py-4 rf-px-4 rf-flex rf-justify-between rf-items-center rf-border-b rf-border-b-black'>
      <button
        className='rf-flex rf-flex-row rf-items-center rf-text-size-auto'
        onClick={handleReturn}
      >
        <ArrowLeftIcon className='rf-w-8' />
        <div className='rf-ml-1'>{title}</div>
      </button>
      <div
        className='rf-text-xsm rf-uppercase rf-text-secondary rf-underline'
        onClick={handleReset}
      >
        {translation.template.reset}
      </div>
    </div>
  )
}

export default MobileUpperBand
