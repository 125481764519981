{
  "_comment": "[CI-LOCKED] Tämän tiedoston sisältö on lukittu. Osallistuminen on tervetullutta, mutta muutosten tarkistamisessa saattaa olla viivettä.",
  "ACCESS_KEY_ALT": "Alt + Vaihto + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control + Option + $1",
  "ACTIVITY_BOT_SAID_ALT": "Botti $1 sanoi:",
  "ACTIVITY_YOU_SAID_ALT": "Sanoit:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Botti lisäsi liitteen:",
  "ACTIVITY_ERROR_BOX_TITLE": "Virhesanoma",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Siirry vuorovaikutukseen napsauttamalla.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Viesti on vuorovaikutteinen.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Viestissä on vähintään yksi linkki.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Lähetys epäonnistui.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Lisäsit liitteen:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 liite.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 liitettä.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 liitettä.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 liitettä.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 liitettä.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Lähetys epäonnistui. [Yritä uudelleen][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Lähetetään",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Lähetetty $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Juuri nyt",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Tunti sitten",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Minuutti sitten",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Tänään",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Eilen",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Mukautuvan kortin jäsennysvirhe",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Mukautuvan kortin muodostusvirhe",
  "ATTACHMENT_AUDIO": "Äänileike.",
  "ATTACHMENT_CARD": "Kortti: $1 $2 $3",
  "ATTACHMENT_FILE": "Tiedosto: $1",
  "ATTACHMENT_IMAGE": "Kuva",
  "ATTACHMENT_TEXT": "Teksti: $1",
  "ATTACHMENT_VIDEO": "Videoleike",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Seuraava",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Edellinen",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Yhdistetty",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Muodostetaan yhteyttä…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Yhdistäminen ei onnistu.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Verkkoyhteys keskeytyi. Yhdistetään uudelleen…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Muodostusvirhe. Tarkista konsoli tai ota yhteyttä botin kehittäjään.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Yhteyden muodostaminen kestää tavallista kauemmin.",
  "CONNECTIVITY_STATUS_ALT": "Yhteyden tila: $1",
  "FILE_CONTENT_ALT": "$1",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Lataa tiedosto $1",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Lataa tiedosto $1 (koko $2)",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1 (koko $2)",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter-näppäin",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Viestin kohteiden käyttö",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Keskusteluhistoria",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Esc-näppäin",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Poistu viestistä",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Sarkainnäppäin\nVaihto + sarkainnäppäin",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Siirry viestin kohteiden välillä",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Ylänuolinäppäin\nAlanuolinäppäin",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Siirry viestien välillä",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter-näppäin",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Suorita toiminto",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Sarkainnäppäin\nVaihto + sarkainnäppäin",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Siirry keskusteluikkunan kohteiden välillä",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Keskusteluikkuna",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Sulje",
  "KEYBOARD_HELP_HEADER": "Näppäimistökomennot",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Avautuu uudessa ikkunassa; ulkoinen.",
  "RECEIPT_CARD_TAX": "Vero",
  "RECEIPT_CARD_TOTAL": "Yhteensä",
  "RECEIPT_CARD_VAT": "ALV",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Tyhjää viestiä ei voi lähettää.",
  "SPEECH_INPUT_LISTENING": "Kuunnellaan…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofoni pois päältä",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofoni päällä",
  "SPEECH_INPUT_STARTING": "Aloitetaan…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Seuraava",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Edellinen",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Ehdotetut toiminnot",
  "TEXT_INPUT_ALT": "Viestin syöttöruutu",
  "TEXT_INPUT_PLACEHOLDER": "Kirjoita viestisi",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Lähetä",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Puhu",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Lataa tiedosto palvelimeen",
  "TOAST_ACCORDION_FEW": "$1 ilmoitusta: Katso lisätietoja napsauttamalla tästä",
  "TOAST_ACCORDION_MANY": "$1 ilmoitusta: Katso lisätietoja napsauttamalla tästä",
  "TOAST_ACCORDION_OTHER": "$1 ilmoitusta: Katso lisätietoja napsauttamalla tästä",
  "TOAST_ACCORDION_TWO": "$1 ilmoitusta: Katso lisätietoja napsauttamalla tästä",
  "TOAST_ALT_ERROR": "Virhe",
  "TOAST_ALT_INFO": "Tiedot",
  "TOAST_ALT_SUCCESS": "Onnistui",
  "TOAST_ALT_WARN": "Varoitus",
  "TOAST_DISMISS_BUTTON": "Hylkää",
  "TOAST_TITLE_ALT": "Ilmoitus",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Keskusteluhistoria, siirry nuolinäppäimillä.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "keskusteluhistoria",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Viesti on vuorovaikutteinen. Siirry keskusteluhistoriaan painamalla vaihto- ja sarkainnäppäimiä 2–3 kertaa. Aloita sitten vuorovaikutus napsauttamalla viestiä.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Viestissä on vähintään yksi linkki. Siirry keskusteluhistoriaan painamalla vaihto- ja sarkainnäppäimiä 2–3 kertaa. Aloita sitten vuorovaikutus napsauttamalla viestiä.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Viestissä on ehdotettuja toimintoja. Valitse ne painamalla vaihto + sarkain 2–3 kertaa.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Viestissä on ehdotettuja toimintoja. Valitse ne painamalla $1.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Viestin lähettäminen epäonnistui.",
  "TRANSCRIPT_MORE_MESSAGES": "Lisää viestejä",
  "TRANSCRIPT_NEW_MESSAGES": "Uudet viestit",
  "TRANSCRIPT_TERMINATOR_TEXT": "Keskusteluhistorian loppu",
  "TYPING_INDICATOR_ALT": "Näytetään kirjoittamisen ilmaisin",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 kirjoittaa.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 ja muut kirjoittavat."
}