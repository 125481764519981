import type { DirectLineCardAction } from 'botframework-webchat-core'
import { CONTENT_TYPE_ADAPTIVE_CARD } from '../models/attachmentTypes'
import { getTextWithoutSpeechMarkdown } from './speechMarkdownUtils'

export interface BotFrameworkCardAction {
  __isBotFrameworkCardAction: true
  cardAction: DirectLineCardAction
}

interface HeroCardImage {
  url: string
  alt?: string
  tap?: any
}

interface ContentFromHeroCard {
  buttons?: DirectLineCardAction[]
  subtitle?: string
  text?: string
  title?: string
  speak?: string
  images?: Array<HeroCardImage>
  tap?: any
}

interface AdaptiveCard {
  contentType: string
  content: {
    type: string
    $schema: string
    version: string
    body: Array<any>
    text?: string
    speak?: string
    selectAction?: Action
  }
}

interface TextBlock {
  type: 'TextBlock'
  text: string
  size?: string
  horizontalAlignment?: string
  wrap?: boolean
  maxLines?: number
}

interface Image {
  type: string
  url: string
  size: string
  altText?: string
  selectAction?: any
  horizontalAlignment?: string
}

interface Action {
  type: string
  title?: string
  url?: string
  fullWidth?: boolean
  position?: string
  display?: string
  data: any
}

const addTextBlock = (text: string, type: string): TextBlock => {
  const textBlock: TextBlock = {
    type: 'TextBlock',
    text: text,
    size: undefined,
    horizontalAlignment: undefined,
    wrap: true,
    maxLines: undefined
  }

  switch (type) {
    case 'title':
      textBlock.size = 'large'
      break
    case 'subtitle':
      textBlock.size = 'medium'
      break
    case 'text':
    default:
      break
  }

  return textBlock
}

const addImage = (data: HeroCardImage): Image => {
  const image: Image = {
    type: 'Image',
    url: '',
    size: 'Large',
    altText: undefined,
    selectAction: undefined,
    horizontalAlignment: 'Center'
  }

  image.url = data.url
  image.altText = data.alt || 'image'
  image.selectAction = data.tap && addAction(data.tap)

  return { ...image }
}

const addAction = (cardAction: any, includesOAuthButtons?: boolean): Action => {
  const action: Action = {
    type: 'Action.Submit',
    title: undefined,
    url: undefined,
    data: undefined,
    fullWidth: undefined,
    position: undefined,
    display: undefined
  }

  for (const val in cardAction) {
    if (cardAction[val] && typeof cardAction[val] === 'string') {
      cardAction[val] = getTextWithoutSpeechMarkdown(cardAction[val])
    }
  }

  if (
    cardAction.type === 'imBack' ||
    cardAction.type === 'messageBack' ||
    cardAction.type === 'postBack' ||
    (cardAction.type === 'signin' && includesOAuthButtons)
  ) {
    action.type = 'Action.Submit'
    action.data = {
      __isBotFrameworkCardAction: true,
      cardAction
    }
    action.title = (cardAction as { title: string }).title || ''
  } else {
    action.type = 'Action.OpenUrl'

    action.title = (cardAction as { title: string }).title || ''
    action.url =
      cardAction.type === 'call' ? `tel:${cardAction.value}` : cardAction.value
  }

  const channelData = cardAction.channelData
  if (channelData) {
    action.fullWidth = channelData.fullWidth
    action.position = channelData.position
    action.display = channelData.display
  }

  return { ...action }
}

const buildCard = (content: ContentFromHeroCard): AdaptiveCard => {
  const adaptiveCard: AdaptiveCard = {
    contentType: CONTENT_TYPE_ADAPTIVE_CARD,
    content: {
      type: 'AdaptiveCard',
      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
      version: '1.5',
      body: [],
      text: undefined,
      speak: undefined,
      selectAction: undefined
    }
  }

  // Add texts first in order : title - subtitle - text
  let cardTexts: Array<TextBlock> = []
  if (content.title) {
    const ti = addTextBlock(content.title, 'title')
    cardTexts = [...cardTexts, { ...ti }]
  }
  if (content.subtitle) {
    const st = addTextBlock(content.subtitle, 'subtitle')
    cardTexts = [...cardTexts, { ...st }]
  }
  if (content.text) {
    const txt = addTextBlock(content.text, 'text')
    cardTexts = [...cardTexts, { ...txt }]
    adaptiveCard.content.text = content.text
  }

  // Set speak data so that the card is taken in account in the renderer
  if (content.speak || content.text) {
    adaptiveCard.content.speak = content.speak || content.text
  }

  // Set body as Container with empty items
  adaptiveCard.content.body = [
    {
      type: 'Container',
      items: []
    }
  ]

  // Add texts to items is there are some
  cardTexts.length > 0 && (adaptiveCard.content.body[0].items = [...cardTexts])

  // Add Images if there are some
  let images: Array<Image> = []
  content.images &&
    content.images.forEach((heroImage) => {
      const image = addImage(heroImage)
      images = [...images, { ...image }]
    })

  images.length > 0 &&
    (adaptiveCard.content.body[0].items = [
      ...adaptiveCard.content.body[0].items,
      ...images
    ])

  // Add actions if there are some
  let actions: Array<Action> = []
  content.buttons &&
    content.buttons.forEach((button) => {
      const action = addAction(button)
      actions = [...actions, { ...action }]
    })

  if (actions.length > 0) {
    const actionSet = {
      type: 'ActionSet',
      actions: [...actions]
    }

    adaptiveCard.content.body[0].items = [
      ...adaptiveCard.content.body[0].items,
      { ...actionSet }
    ]
  }

  // Tap action
  if (content.tap) {
    const tapAction = addAction(content.tap)
    adaptiveCard.content.selectAction = { ...tapAction }
  }

  return { ...adaptiveCard }
}

export { buildCard }
