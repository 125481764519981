import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const TriangleIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 12'
      className={className || 'rf-w-3 rf-h-3'}
    >
      <path
        d='M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z'
        fill={color || 'transparent'}
      />
    </svg>
  )
}

export default TriangleIcon
