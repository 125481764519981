import React from 'react'

const MicrophoneLoader = (): JSX.Element => {
  return (
    <div className='rf-w-full rf-h-full rf-bg-transparent'>
      {/* Animation */}
      <div className='rf-w-full rf-h-full rf-relative rf-rounded-max rf-mx-0 rf-my-auto rf-loader-microphone' />
    </div>
  )
}

export default MicrophoneLoader
