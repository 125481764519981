/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useView } from '../Contexts/ViewContext'
import { DeviceType, Mode } from '../../models/enums'
import VoiceInput from './VoiceInput'
import SendTextBox from '../Utils/SendTextBox'

const UserRequest = (): JSX.Element => {
  const mode = useRetorikStore((state) => state.mode)
  const { isMobile, currentDeviceType, mediumLandscape } = useView()

  return (
    <React.Fragment>
      {!isMobile && (
        <div
          className={`rf-relative rf-h-full
            ${
              currentDeviceType === DeviceType.borne && mode === Mode.vocal
                ? 'rf-w-4/9'
                : 'rf-w-3/5'
            }
            ${
              mode === Mode.text && mediumLandscape
                ? 'rf-col-start-4'
                : 'rf-col-start-5'
            } rf-col-span-full rf-row-start-1 ${
            mode === Mode.text ? 'rf-row-end-4' : 'rf-row-end-5'
          } rf-py-4
            large-vertical:rf-col-start-1 large-vertical:rf-col-span-full large-vertical:rf-row-start-5 large-vertical:rf-row-end-7
            large-vertical:rf-py-5
            rf-flex rf-flex-col rf-justify-self-center rf-justify-end rf-text-size-auto rf-text-trueblack`}
          id='retorik-userRequest'
        >
          {mode === Mode.text ? (
            <SendTextBox withButton={true} />
          ) : (
            <VoiceInput />
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default UserRequest
