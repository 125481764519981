{
  "_comment": "[CI-LOCKED] 此文件的内容已锁定。欢迎发布内容，但更改验证可能会延迟。",
  "ACCESS_KEY_ALT": "Alt + Shift + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "控制键 + 选项键 + $1",
  "ACTIVITY_BOT_SAID_ALT": "机器人 $1 说:",
  "ACTIVITY_YOU_SAID_ALT": "您说:",
  "ACTIVITY_BOT_ATTACHED_ALT": "机器人附加了:",
  "ACTIVITY_ERROR_BOX_TITLE": "错误消息",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "单击以进行交互。",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "消息是交互式消息。",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "消息中有一个或多个链接。",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "发送失败。",
  "ACTIVITY_YOU_ATTACHED_ALT": "您附加了:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 个附件。",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 个附件。",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 个附件。",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 个附件。",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 个附件。",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "发送失败。[重试][RETRY]。",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "正在发送",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "已于 $1 发送",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "刚刚",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "1 小时前",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "1 分钟前",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "今天",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "昨天",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "自适应卡分析错误",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "自适应卡呈现错误",
  "ATTACHMENT_AUDIO": "音频剪辑。",
  "ATTACHMENT_CARD": "卡片: $1 $2 $3",
  "ATTACHMENT_FILE": "文件: $1",
  "ATTACHMENT_IMAGE": "图像",
  "ATTACHMENT_TEXT": "文本: $1",
  "ATTACHMENT_VIDEO": "视频剪辑",
  "CAROUSEL_FLIPPER_NEXT_ALT": "下一个",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "上一个",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "已连接",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "正在连接...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "无法连接。",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "发生网络中断。正在重新连接...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "呈现错误。请检查控制台或联系机器人开发者。",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "连接所花费的时间长于平时。",
  "CONNECTIVITY_STATUS_ALT": "连接状态: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "下载文件 '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "下载文件 '$1'，大小为 $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' 的大小为 $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter 键",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "访问消息中的项目",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "聊天历史记录",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape 键",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "退出消息",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab 键\nShift + Tab 键",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "在消息中的项目之间移动",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "向上键\n向下键",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "在消息之间移动",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter 键",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "执行操作",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab 键\nShift + Tab 键",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "在聊天窗口中的项目之间移动",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "聊天窗口",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "关闭",
  "KEYBOARD_HELP_HEADER": "键盘控件",
  "MARKDOWN_EXTERNAL_LINK_ALT": "在新窗口中打开；外部。",
  "RECEIPT_CARD_TAX": "税款",
  "RECEIPT_CARD_TOTAL": "总额",
  "RECEIPT_CARD_VAT": "增值税",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "无法发送空消息。",
  "SPEECH_INPUT_LISTENING": "正在倾听...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "麦克风关闭",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "麦克风打开",
  "SPEECH_INPUT_STARTING": "正在启动...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "下一个",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "上一个",
  "SUGGESTED_ACTIONS_LABEL_ALT": "建议的操作",
  "TEXT_INPUT_ALT": "消息输入框",
  "TEXT_INPUT_PLACEHOLDER": "键入消息",
  "TEXT_INPUT_SEND_BUTTON_ALT": "发送",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "咨询",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "上传文件",
  "TOAST_ACCORDION_FEW": "$1 条通知: 单击此处以查看详细信息",
  "TOAST_ACCORDION_MANY": "$1 条通知: 单击此处以查看详细信息",
  "TOAST_ACCORDION_OTHER": "$1 条通知: 单击此处以查看详细信息",
  "TOAST_ACCORDION_TWO": "$1 条通知: 单击此处以查看详细信息",
  "TOAST_ALT_ERROR": "出错",
  "TOAST_ALT_INFO": "信息",
  "TOAST_ALT_SUCCESS": "成功",
  "TOAST_ALT_WARN": "警告",
  "TOAST_DISMISS_BUTTON": "取消",
  "TOAST_TITLE_ALT": "通知",
  "TRANSCRIPT_ARIA_LABEL_ALT": "聊天历史记录，按箭头键导航。",
  "TRANSCRIPT_ARIA_ROLE_ALT": "聊天历史记录",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "消息是交互式消息。按 Shift Tab 键 2 到 3 次切换到聊天历史记录。然后单击消息以进行交互。",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "消息中有一个或多个链接。按 Shift Tab 键 2 到 3 次切换到聊天历史记录。然后单击消息以进行交互。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "消息提供了建议的操作。请按 Shift + Tab 键 2 到 3 次进行选择。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "消息提供了建议的操作。请按 $1 进行选择。",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "无法发送消息。",
  "TRANSCRIPT_MORE_MESSAGES": "更多消息",
  "TRANSCRIPT_NEW_MESSAGES": "新消息",
  "TRANSCRIPT_TERMINATOR_TEXT": "聊天历史记录结束",
  "TYPING_INDICATOR_ALT": "显示键入指示器",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 正在键入。",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 和其他人正在键入。"
}