import React, { useLayoutEffect, useRef } from 'react'
import { useView } from '../Contexts/ViewContext'
import useFullscreenImage from '../../hooks/useFullscreenImage'
import ScrollButtonIcon from '../Icons/ScrollButton/ScrollButtonIcon'
import preventEvents from '../../utils/preventEvents'

interface ScrollButtonProps {
  direction: 'top' | 'bottom'
  display: boolean
  scrollHandler: (to: 'bottom' | 'top', from: 'newMessage' | 'button') => void
  setPreventScroll: (value: boolean) => void
  className: string
}

const ScrollButton = ({
  direction,
  display,
  scrollHandler,
  setPreventScroll,
  className
}: ScrollButtonProps): JSX.Element => {
  const { themeColors } = useView()
  const fullScreenImage = useFullscreenImage()
  const divRef = useRef<HTMLDivElement>(null)

  const handleClick = (): void => {
    setPreventScroll(true)
    if (direction === 'top') {
      scrollHandler('top', 'button')
    } else {
      scrollHandler('bottom', 'button')
    }
  }

  useLayoutEffect(() => {
    divRef?.current && preventEvents(divRef.current)
  })

  return display && !fullScreenImage ? (
    <div
      id='ScrollButton'
      ref={divRef}
      className={className}
      onClick={handleClick}
    >
      <ScrollButtonIcon
        className={`${direction === 'top' ? 'rf-rotate-0' : 'rf-rotate-180'}`}
        color={themeColors.primary}
      />
    </div>
  ) : (
    <></>
  )
}

export { ScrollButton }
