import React, { useEffect, useState } from 'react'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { useView } from '../../../Contexts/ViewContext'
import type { Filter } from '../../../../models/filterTypes'
import { ArrowUpIcon, ArrowDownIcon } from '../../../Icons/ArrowIcons'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import SubFilter from './SubFilter'
import SubFilterAll from './SubFilterAll'
import SubFilterCalendar from './SubFilterCalendar'

type FilterItemProps = {
  identifier: number
  className?: string
  opened?: boolean
  filter: Filter
  closeOpenedFilters: (key: number | undefined) => void
}

const FilterItem = ({
  className,
  identifier,
  opened,
  filter,
  closeOpenedFilters
}: FilterItemProps): JSX.Element => {
  const { locale } = useLocaleStore((state) => ({
    locale: state.locale
  }))
  const { isMobile } = useView()
  const [style, setStyle] = useState<string>('')

  useEffect(() => {
    opened
      ? setStyle('rf-bg-truewhite rf-text-black rf-border-black')
      : setStyle('rf-bg-templateBackground rf-text-gray rf-border-gray')
  }, [opened])

  const handleOpen = (e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    closeOpenedFilters(opened ? undefined : identifier)
  }

  return (
    <div className='rf-relative rf-mt-1'>
      <div
        className={`rf-px-4 rf-py-3 rf-mr-4 large-vertical:rf-py-4 ${style} rf-border rf-rounded-lg hover:rf-cursor-pointer rf-flex rf-flex-row rf-items-center rf-justify-center ${className}`}
        onClick={(e): void => handleOpen(e)}
      >
        <div className='rf-mr-3'>
          {capitalizeFirstLetter(filter.title, locale)}
        </div>
        {opened ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      {opened && (
        <div
          className='rf-absolute rf-left-0 rf-top-110 rf-z-util rf-w-max rf-py-1 rf-px-4 rf-text-black rf-border rf-rounded-lg rf-border-black rf-bg-truewhite rf-flex rf-flex-col rf-items-center rf-justify-center'
          style={{
            transform:
              filter.id === 'Event.DateRange' && !isMobile
                ? 'translate(-20%, -20%) scale(0.6, 0.6)'
                : ''
          }}
        >
          {filter.id === 'Event.DateRange' ? (
            <SubFilterCalendar
              parent={{ id: filter.id, title: filter.title }}
            />
          ) : (
            <React.Fragment>
              <SubFilterAll fullParent={filter} />
              {filter.filterItems.map((item, key) => {
                return (
                  <SubFilter
                    key={key}
                    filterItem={item}
                    parent={{ id: filter.id, title: filter.title }}
                  />
                )
              })}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  )
}

export default FilterItem
