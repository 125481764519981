import { CONTENT_TYPE_NEWS, NewsContent } from '../models/attachmentTypes'
import type { RetorikActivity } from '../models/activityTypes'
import { useActivityStore } from '../components/Contexts/activityStore'

type NewsAttachment = {
  contentType: string
  content: NewsContent
}

const useNews = (): {
  newsAttachments: Array<NewsAttachment>
  activity?: RetorikActivity
} => {
  const activities = useActivityStore((state) => state.botEventActivities)

  if (!activities.length) {
    return { newsAttachments: [] }
  }

  // Filter messages that can income during news, with label = Davi.BringupMessage
  const filterBringUpActivities = activities.filter(
    (act: any) => act.label !== 'Davi.BringupMessage'
  )

  const activity = filterBringUpActivities[
    filterBringUpActivities.length - 1
  ] as any

  if (!activity || !activity.attachments?.length) {
    return { newsAttachments: [], activity }
  }

  const newsAttachments: NewsAttachment[] = []
  activity.attachments.forEach((attachment) => {
    if (attachment.contentType === CONTENT_TYPE_NEWS) {
      newsAttachments.push({
        contentType: CONTENT_TYPE_NEWS,
        content: attachment.content || ''
      })
    }
  })

  return { newsAttachments, activity }
}

export default useNews
