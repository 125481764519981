/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useList } from '../../../Contexts/ListContext'
import InputCalendar from '../../../CustomAdaptiveCard/InputCalendar'

type SubFilterCalendarProps = {
  parent: {
    id: string
    title: string
  }
}

const SubFilterCalendar = ({ parent }: SubFilterCalendarProps): JSX.Element => {
  const { selectedFilters, processFiltersOnChange } = useList()

  const handleCalendarChange = (newDate: string): void => {
    processFiltersOnChange([
      {
        ...parent,
        filterItems: [
          {
            name: newDate,
            value: newDate
          }
        ]
      }
    ])
  }

  return (
    <div className='rf-w-full rf-pt-4 large:rf-py-1 rf-flex rf-justify-between rf-items-center'>
      <InputCalendar
        id='filterCalendar'
        handleChange={handleCalendarChange}
        defaultValue={selectedFilters
          .find(
            (filter) =>
              filter.id === 'Event.DateRange' && filter.filterItems.length > 0
          )
          ?.filterItems[0].name.substring(0, 11)}
        isFilter={true}
      />
    </div>
  )
}

export default SubFilterCalendar
