import React, { useEffect, useState } from 'react'
import { FeatherCheckIcon } from '../../Icons/Miscellaneous'

type CheckBoxProps = {
  className?: string
  iconClassName?: string
  checked?: boolean
  onClick?: () => void
}

const CheckBox = ({
  className,
  iconClassName,
  checked,
  onClick
}: CheckBoxProps): JSX.Element => {
  const [bgColor, setBgColor] = useState<string>(
    checked ? 'rf-bg-secondary' : 'rf-bg-truewhite'
  )

  useEffect(() => {
    setBgColor(checked ? 'rf-bg-secondary' : 'rf-bg-truewhite')
  }, [checked])

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    onClick && onClick()
  }

  return (
    <div
      className={`rf-w-6 rf-h-6 rf-min-w-6 rf-min-h-6 rf-flex rf-justify-center rf-items-center rf-border rf-border-templateCheckboxBorder hover:rf-cursor-pointer ${bgColor} ${className}`}
      onClick={(e): void => handleClick(e)}
    >
      <FeatherCheckIcon className={iconClassName} />
    </div>
  )
}

export default CheckBox
