import React, { useState } from 'react'
import { useLocaleStore, setLocale } from '../Contexts/localeStore'
import { setCurrentSubView } from '../Contexts/utilsStore'
import { useRetorik } from '../Contexts/RetorikContext'
import { useSendEvent } from 'botframework-webchat-api/lib/hooks'
import languageInMotherTongue from '../../translations/languageCodeToMotherTongue.json'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { SubMenuClosing as Template } from '../Templates'
import { Radio } from '../Utils'
import { CurrentSubView } from '../../models/enums'

const LanguageChoice = (): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const supported = useLocaleStore((state) => state.supported)
  const {
    configuration: { skipWelcome }
  } = useRetorik()
  const sendEvent = useSendEvent()
  const [currentLanguage, setCurrentLanguage] = useState<string>(locale)

  const handleClose = (changed?: boolean): void => {
    setCurrentSubView(CurrentSubView.none)

    // Only send events when language has not been changed, either way it will collide with the TriggerWelcome event
    if (!changed) {
      sendEvent('Davi.CloseWindow', null)
      sendEvent('Davi.CloseWindowLanguages', null)
    }
  }

  const handleChange = (language: string): void => {
    setCurrentLanguage(language)
  }

  /**
   * Change current locale with the one selected if they are different.
   * Send a 'TriggerWelcome' event to get the welcome message in the new language.
   * @param language string
   */
  const handleSelect = (language: string): void => {
    const changed = language !== locale
    if (changed) {
      setLocale(language)
      !skipWelcome &&
        setTimeout(() => {
          sendEvent('TriggerWelcome', undefined)
        }, 150)
    }

    handleClose(changed)
  }

  return (
    <Template handleClose={() => handleClose(false)}>
      {/* Title */}
      <div className='large:rf-mt-[20vh] large-tactile:rf-mt-0 rf-title-bold-size-auto rf-font-bold'>
        {capitalizeFirstLetter(translation.language.change, locale)}
      </div>

      {/* Subtitle */}
      <div className='rf-text-lightgray rf-pt-1 rf-pb-2'>
        {capitalizeFirstLetter(translation.language.select, locale)}
      </div>

      {/* Languages */}
      {supported.map((language, key) => {
        return (
          <div
            key={key}
            className={`rf-w-full rf-py-4 rf-border-t rf-border-t-menuBorder ${
              key === 0 && 'large:rf-border-t-0'
            }`}
          >
            <Radio
              title={languageInMotherTongue[language]}
              checked={language === currentLanguage}
              handleChange={(): void => handleChange(language)}
            />
          </div>
        )
      })}

      {/* Validation button */}
      <div className='rf-w-full rf-mt-8 rf-flex rf-justify-center'>
        <button
          className='rf-px-8 rf-py-3 rf-text-secondary rf-font-bold rf-border-2 rf-border-secondary rf-rounded-lg'
          onClick={(): void => handleSelect(currentLanguage)}
        >
          {capitalizeFirstLetter(translation.common.validate, locale)}
        </button>
      </div>
    </Template>
  )
}

export default LanguageChoice
