{
  "1": "domovská stránka",
  "2": "živý zpravodajský kanál",
  "3": "zpráva o počasí",
  "4": "Tísňová čísla",
  "1000": "historický",
  "1001": "tutorial",

  "common": {
    "all": "všechno",
    "back": "zadní",
    "close": "zavřít",
    "continue": "pokračovat",
    "download": "stažení",
    "downloadsheet": "stáhněte si tento list",
    "downloadsheetmobile": "stáhněte si tento list na smartphone",
    "filter": "filtr",
    "history": "historický",
    "lang": "jazyky",
    "menu": "jídelní lístek",
    "mode": "móda",
    "needhelp": "potřebovat pomoc?",
    "parameters": "nastavení",
    "print": "tisk",
    "printsheet": "tytiskněte tento list",
    "result": "výsledek",
    "results": "výsledek",
    "singleLang": "jazyk",
    "sound": "její",
    "submit": "předložit",
    "subtitle": "podtitulek",
    "tutorial": "tutorial",
    "validate": "ověřit"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "POLICIE",
    "firefighter": "hasiči",
    "general": "všeobecný",
    "citypolice": "Místní policie",
    "hospital": "Nemocnice",
    "homeless": "Bezdomovci (volání zdarma)",
    "poison": "Poison Control Center",
    "emergency": "evropské mimořádné události"
  },

  "error": {
    "title": "Došlo k chybě",
    "button": "Znovu spusťte aplikaci"
  },

  "history": {
    "title": "historie konverzace"
  },

  "inputs": {
    "choose": "Vyberte odpověď"
  },

  "language": {
    "change": "změnit jazyk",
    "select": "vyberte jazyk, který chcete používat"
  },

  "link": {
    "buttontext": "Zobrazit dokument",
    "goto": "Přejděte na adresu",
    "click": "Klikněte zde",
    "scanQR": "skenováním QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Pro optimální zážitek,",
      "classic": "Pro klasičtější zážitek,",
      "vocal": "Mluvím tváří v tvář s agentem.",
      "text": "S jednatelem komunikuji písemně."
    },
    "modes": {
      "vocal": "hlasový režim",
      "text": "textový režim"
    },
    "loaderValidation": "Zahajte konverzaci",
    "locale": "kontrola jazyka",
    "supported": "kontrola podporovaných jazyků",
    "addressdata": "načítání dat"
  },

  "menu": {
    "message": "Zadejte svou zprávu",
    "allowMicrophone": "Chcete-li pokračovat, povolte prohlížeči používat váš mikrofon",
    "backconversation": "zpět k rozhovoru",
    "answer": " odpovídá na vaše otázky"
  },

  "mobileLandscapePreventer": {
    "firstline": "Pro optimální zážitek,",
    "secondline": "otočte obrazovku"
  },

  "news": {
    "warningtext": "Vstoupíte do zpravodajského kanálu, zvuk na vašem zařízení bude aktivován",
    "live": "žít",
    "continuous": "nepřetržitě"
  },

  "poi": {
    "comment": "zákaznická recenze",
    "route": "silnice",
    "nodata": "Vašemu vyhledávání neodpovídá žádný obsah",
    "openinghours": "Otevírací doba",
    "prices": "Ceny",
    "paymentmethods": "Platební metody",
    "numberofplaces": "počet míst",
    "openroutemobile": "otevřete trasu Google Maps na smartphonu",
    "FoodEstablishment": "Kde jíst",
    "LodgingBusiness": "Kde spát",
    "TouristDestination": "Co vidět",
    "TouristAttraction": "Co dělat",
    "Product": "Místní produkty",
    "Service": "Služby",
    "Event": "Události",
    "City": "Města",
    "Wine": "Vína"
  },

  "suggestion": {
    "consult": "Překontrolovat",
    "thetutorial": "tutoriál"
  },

  "template": {
    "reset": "resetovat vše",
    "showresults": "Ukaž výsledky",
    "suggestiontitle": "Návrhy vyhledávání",
    "reminder": "Nezapomeňte, \n mohu odpovědět na vaše otázky."
  }
}
