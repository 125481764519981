import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useEffect
} from 'react'
import type { AddressData, Configuration } from '../../models/types'
import processBaseURI from '../../utils/studioRetorikAddressFromBaseURI'
import processPrefix from '../../utils/studioRetorikAddressFromPrefix'
import {
  setDisplayChangeLayoutButton,
  setEnableDocumentPrinting,
  setMode,
  setLoaderClosed as storeSetLoaderClosed,
  setDisableSound,
  useRetorikStore
} from './retorikStore'
import { setUsingOnBorne } from './utilsStore'
import { setMuted } from './speechStore'

export type RetorikProviderProps = {
  chosenMode: number
  config: Configuration
  skipLoader?: boolean
  children?: ReactNode
  addressData: AddressData
}

type LocationData = {
  searchForLocation: boolean
  latitude: number
  longitude: number
  city?: string
  country?: string
}

export type RetorikContextType = {
  configuration: Configuration
  appAvailable: boolean
  loaderClosed: boolean
  addressData: AddressData
  baseAddress: string
  canFocusSendBox: boolean
  setConfiguration: (x: Configuration) => void
  setAppAvailable: (x: boolean) => void
  setLoaderClosed: (x: boolean) => void
  setSendBoxRef: (x: HTMLTextAreaElement) => void
  focusSendBox: () => void
  setCanFocusSendBox: (x: boolean) => void
}

const defaultConfiguration: Configuration = {
  subtitles: false
}

const RetorikContextDefaultValues: RetorikContextType = {
  configuration: defaultConfiguration,
  appAvailable: false,
  loaderClosed: false,
  addressData: {},
  baseAddress: '',
  canFocusSendBox: false,
  setConfiguration: () => {},
  setAppAvailable: () => {},
  setLoaderClosed: () => {},
  setSendBoxRef: () => {},
  focusSendBox: () => {},
  setCanFocusSendBox: () => {}
}

export const RetorikContext = createContext<RetorikContextType>(
  RetorikContextDefaultValues
)

export function useRetorik(): RetorikContextType {
  return useContext(RetorikContext)
}

export function RetorikProvider({
  skipLoader,
  chosenMode,
  config,
  children,
  addressData
}: RetorikProviderProps): JSX.Element {
  const [configuration, setConfiguration] = useState<Configuration>(config)
  const [appAvailable, setAppAvailable] = useState<boolean>(false)
  const [loaderClosed, setLoaderClosed] = useState<boolean>(!!skipLoader)
  const [sendBoxRef, setSendBoxRef] = useState<HTMLTextAreaElement | null>(null)
  const [canFocusSendBox, setCanFocusSendBox] = useState<boolean>(false)
  const focusSendBox = (): void => {
    sendBoxRef && document.activeElement !== sendBoxRef && sendBoxRef.focus()
  }

  const baseAddress = useMemo<string>(() => {
    return `${
      addressData.baseURI && addressData.baseURI.length > 0
        ? processBaseURI(addressData.baseURI)
        : processPrefix(addressData.prefix)
    }/${addressData.tenant ? addressData.tenant + '/' : ''}`
  }, [addressData])

  const storeLoaderClosed = useRetorikStore((state) => state.loaderClosed)
  const value = useMemo(
    () => ({
      configuration,
      appAvailable,
      loaderClosed,
      addressData,
      baseAddress,
      canFocusSendBox,
      setConfiguration,
      setAppAvailable,
      setLoaderClosed,
      setSendBoxRef,
      focusSendBox,
      setCanFocusSendBox
    }),
    [
      configuration,
      appAvailable,
      loaderClosed,
      baseAddress,
      canFocusSendBox,
      setConfiguration,
      setAppAvailable,
      setLoaderClosed,
      setSendBoxRef,
      setCanFocusSendBox
    ]
  )

  const setLocation = async (data): Promise<void> => {
    const position: LocationData = {
      searchForLocation: true,
      latitude: data.coords.latitude,
      longitude: data.coords.longitude
    }

    localStorage.setItem('Retorik.Framework.Location', JSON.stringify(position))

    setConfiguration({
      ...configuration,
      position: position
    })
  }

  useEffect(() => {
    if (configuration.disableSound === true) {
      setMuted(true)
      setDisableSound(configuration.disableSound)
    }
    if (configuration.disableSound === false) {
      setDisableSound(configuration.disableSound)
    }
  }, [configuration.disableSound])

  useEffect(() => {
    setUsingOnBorne(!!configuration.isUsedOnBorne)
  }, [configuration.isUsedOnBorne])

  useEffect(() => {
    setDisplayChangeLayoutButton(!!configuration.allowSwitchLayout)
  }, [configuration.allowSwitchLayout])

  useEffect(() => {
    setEnableDocumentPrinting(!!configuration.enableDocumentPrinting)
  }, [configuration.enableDocumentPrinting])

  // Get position from navigator
  useEffect(() => {
    if (configuration.position?.searchForLocation) {
      // Position enabled
      if (
        configuration.position.latitude === undefined &&
        configuration.position.longitude === undefined
      ) {
        // Try to get the position of the device if no one has been given in the configuration
        navigator.geolocation?.getCurrentPosition(
          setLocation,
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.warn(
                  'Geolocation : User denied the request for Geolocation.'
                )
                break
              case error.POSITION_UNAVAILABLE:
                console.warn(
                  'Geolocation : Location information is unavailable.'
                )
                break
              case error.TIMEOUT:
                console.warn(
                  'Geolocation : The request to get user location timed out.'
                )
                break
              default:
                console.warn('Geolocation : An unknown error occurred.')
                break
            }
          },
          {
            timeout: 3000
          }
        )
      }
    }
  }, [configuration.position])

  useEffect(() => {
    setMode(chosenMode)
  }, [chosenMode])

  useEffect(() => {
    storeSetLoaderClosed(loaderClosed)
  }, [loaderClosed])

  useEffect(() => {
    if (storeLoaderClosed !== loaderClosed) setLoaderClosed(storeLoaderClosed)
  }, [storeLoaderClosed])

  useEffect(() => {
    setConfiguration(config)
  }, [config])

  useEffect(() => {
    localStorage.setItem(
      'Retorik.Framework.Location',
      JSON.stringify(configuration.position || null)
    )
  }, [configuration.position])

  useEffect(() => {
    setLoaderClosed(!!skipLoader)
  }, [skipLoader])

  return (
    <RetorikContext.Provider value={value}>{children}</RetorikContext.Provider>
  )
}
