import CyclingRegularIcon from './CyclingRegularIcon'
import DrivingCarIcon from './DrivingCarIcon'
import FootWalkingIcon from './FootWalkingIcon'
import OpenLocationIcon from './OpenLocationIcon'
import TriangleIcon from './TriangleIcon'

export {
  CyclingRegularIcon,
  DrivingCarIcon,
  FootWalkingIcon,
  OpenLocationIcon,
  TriangleIcon
}
