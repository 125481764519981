{
  "1": "pagina iniziale",
  "2": "canale di notizie in diretta",
  "3": "bollettino meteorologico",
  "4": "Numeri di emergenza",
  "1000": "storico",
  "1001": "tutorial",

  "common": {
    "all": "tutti",
    "back": "Indietro",
    "close": "chiudere",
    "continue": "continuare",
    "download": "scaricamento",
    "downloadsheet": "scarica questa scheda",
    "downloadsheetmobile": "scarica questa scheda sullo smartphone",
    "filter": "Filtro",
    "history": "storico",
    "lang": "lingua",
    "menu": "menù",
    "mode": "moda",
    "needhelp": "Ho bisogno di aiuto?",
    "parameters": "impostazioni",
    "print": "Stampa",
    "printsheet": "Stampa questo foglio",
    "result": "risultato",
    "results": "risultati",
    "singleLang": "Lingua",
    "sound": "suo",
    "submit": "invia",
    "subtitle": "sottotitolo",
    "tutorial": "tutorial",
    "validate": "convalidare"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "Polizia Stradale",
    "firefighter": "vigili del fuoco",
    "general": "generale",
    "citypolice": "Polizia locale",
    "hospital": "Ospedale",
    "homeless": "Senzatetto (chiamata gratuita)",
    "poison": "Centro antiveleni",
    "emergency": "Emergenze europee"
  },

  "error": {
    "title": "C'è stato un errore",
    "button": "Riavviare l'applicazione"
  },

  "history": {
    "title": "cronologia delle conversazioni"
  },

  "inputs": {
    "choose": "Scegli una risposta"
  },

  "language": {
    "change": "cambiare la lingua",
    "select": "seleziona la lingua che desideri utilizzare"
  },

  "link": {
    "buttontext": "Visualizza il documento",
    "goto": "Vai all'indirizzo",
    "click": "clicca qui",
    "scanQR": "scansionando il QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Per un'esperienza ottimale,",
      "classic": "Per un'esperienza più classica,",
      "vocal": "Parlo faccia a faccia con l'agente.",
      "text": "Comunico per iscritto con l'agente."
    },
    "modes": {
      "vocal": "modalità vocale",
      "text": "modalità testo"
    },
    "loaderValidation": "Inizia la conversazione",
    "locale": "controllo della lingua",
    "supported": "verifica delle lingue supportate",
    "addressdata": "recupero dati"
  },

  "menu": {
    "message": "Inserisci il tuo messaggio",
    "allowMicrophone": "Consenti al tuo browser di utilizzare il microfono per continuare",
    "backconversation": "torniamo alla conversazione",
    "answer": " risponde alle tue domande"
  },

  "mobileLandscapePreventer": {
    "firstline": "Per un'esperienza ottimale,",
    "secondline": "capovolgi lo schermo"
  },

  "news": {
    "warningtext": "Accederai al canale delle notizie, l'audio sul tuo dispositivo verrà attivato",
    "live": "vivere",
    "continuous": "continuamente"
  },

  "poi": {
    "comment": "recensione del cliente",
    "route": "strada",
    "nodata": "Nessun contenuto corrisponde alla tua ricerca",
    "openinghours": "Orari di apertura",
    "prices": "Prezzi",
    "paymentmethods": "Modalità di pagamento",
    "numberofplaces": "numero di posti",
    "openroutemobile": "apri il percorso di Google Maps sullo smartphone",
    "FoodEstablishment": "Dove mangiare",
    "LodgingBusiness": "Dove dormire",
    "TouristDestination": "Cosa guardare",
    "TouristAttraction": "Cosa fare",
    "Product": "Prodotti locali",
    "Service": "Servizi",
    "Event": "Eventi",
    "City": "Città",
    "Wine": "Vini"
  },

  "suggestion": {
    "consult": "Guardare",
    "thetutorial": "il tutorial"
  },

  "template": {
    "reset": "resetta tutto",
    "showresults": "Mostra i risultati",
    "suggestiontitle": "Suggerimenti per la ricerca",
    "reminder": "Non dimenticare, \n posso rispondere alle tue domande."
  }
}
