{
  "1": "inicio",
  "2": "canal de información en directo",
  "3": "tiempo",
  "4": "números de emergencia",
  "1000": "historia",
  "1001": "tutorial",

  "common": {
    "all": "todos",
    "back": "volver",
    "close": "cerrar",
    "continue": "continuar",
    "download": "descargar",
    "downloadsheet": "descargar esta hoja",
    "downloadsheetmobile": "descargar esta hoja en el teléfono inteligente",
    "filter": "filtrar",
    "history": "historia",
    "lang": "idiomas",
    "menu": "menú",
    "mode": "modo",
    "needhelp": "necesitar ayuda ?",
    "parameters": "parámetros",
    "print": "imprime",
    "printsheet": "imprime esta hoja",
    "result": "resultado",
    "results": "resultados",
    "singleLang": "idioma",
    "sound": "sonido",
    "submit": "enviar",
    "subtitle": "subtítulos",
    "tutorial": "tutorial",
    "validate": "valide"
  },

  "emergency": {
    "samu": "samu",
    "police": "policía",
    "firefighter": "bomberos",
    "general": "general",
    "citypolice": "Policía Local",
    "hospital": "Hospital",
    "homeless": "Personas sin hogar (llamada gratuita)",
    "poison": "Centro de control de venenos",
    "emergency": "Emergencias europeas"
  },

  "error": {
    "title": "Se ha producido un error",
    "button": "Vuelva a iniciar la aplicación"
  },

  "history": {
    "title": "historial de chat"
  },

  "inputs": {
    "choose": "Elija una respuesta"
  },

  "language": {
    "change": "cambiar el idioma",
    "select": "seleccione la lengua que desea utilizar"
  },

  "link": {
    "buttontext": "Ver el documento",
    "goto": "Ir a la dirección",
    "click": "Haga clic aquí",
    "scanQR": "escaneando el QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Para una experiencia óptima,",
      "classic": "Para una experiencia más clásica,",
      "vocal": "Me comunico hablando con el agente.",
      "text": "Me comunico por SMS con el agente."
    },
    "modes": {
      "vocal": "modo vocal",
      "text": "modo texto"
    },
    "loaderValidation": "Iniciar conversación",
    "locale": "comprobar configuración regional",
    "supported": "comprobación de las configuraciones regionales admitidas",
    "store": "crear tienda",
    "directline": "conexión directline",
    "ponyfill": "creación del ponyfill",
    "addressdata": "recuperar datos"
  },

  "menu": {
    "message": "Escriba su mensaje",
    "allowMicrophone": "Dé permiso a su navegador para utilizar el micrófono para continuar",
    "backconversation": "volver a la conversación",
    "answer": " responde a tus preguntas"
  },

  "mobileLandscapePreventer": {
    "firstline": "Para una experiencia óptima",
    "secondline": "voltea la pantalla"
  },

  "news": {
    "warningtext": "Está a punto de acceder al canal de información, el sonido de su dispositivo se activará",
    "live": "en directo",
    "continuous": "continuo"
  },

  "poi": {
    "comment": "revisión del cliente",
    "route": "ruta",
    "nodata": "Ningún contenido corresponde a su búsqueda",
    "openinghours": "Horario de apertura",
    "prices": "Precios",
    "paymentmethods": "Métodos de pago",
    "numberofplaces": "numero de lugares",
    "openroutemobile": "abrir la ruta de Google Maps en el teléfono inteligente",
    "FoodEstablishment": "Dónde comer",
    "LodgingBusiness": "Dónde dormir",
    "TouristDestination": "Qué ver",
    "TouristAttraction": "Qué hacer",
    "Product": "Productos locales",
    "Service": "Servicios",
    "Event": "Eventos",
    "City": "Ciudades",
    "Wine": "Vinos"
  },

  "suggestion": {
    "consult": "Mira",
    "thetutorial": "el tutorial"
  },

  "template": {
    "reset": "restablecer filtros",
    "showresults": "Mostrar resultados",
    "suggestiontitle": "Sugerencias de investigación",
    "reminder": "No olvide :\n puedo responder a sus preguntas."
  }
}
