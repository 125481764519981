import CloseIcon from './CloseIcon'
import DownloadIcon from './DownloadIcon'
import FeatherCheckIcon from './FeatherCheckIcon'
import FeatherFilterIcon from './FeatherFilterIcon'
import InfoWithCircleIcon from './InfoWithCircleIcon'
import SendMessageIcon from './SendMessageIcon'
import ZoomOutIcon from './ZoomOutIcon'
import RetorikLogoIcon from './RetorikLogoIcon'
import FileIcon from './FileIcon'

export {
  CloseIcon,
  DownloadIcon,
  FeatherCheckIcon,
  FeatherFilterIcon,
  InfoWithCircleIcon,
  SendMessageIcon,
  ZoomOutIcon,
  RetorikLogoIcon,
  FileIcon
}
