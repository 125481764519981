/* eslint-disable camelcase */
import ar_EG from './ar-EG.json'
import ar_SA from './ar-SA.json'

import ca_ES from './ca-ES.json'
import cs_CZ from './cs-CZ.json'
import da_DK from './da-DK.json'

import de_AT from './de-AT.json'
import de_CH from './de-CH.json'
import de_DE from './de-DE.json'

import en_CA from './en-CA.json'
import en_GB from './en-GB.json'
import en_HK from './en-HK.json'
import en_IE from './en-IE.json'
import en_IN from './en-IN.json'
import en_US from './en-US.json'

import es_ES from './es-ES.json'
import es_MX from './es-MX.json'

import fi_FI from './fi-FI.json'

import fr_BE from './fr-BE.json'
import fr_CA from './fr-CA.json'
import fr_CH from './fr-CH.json'
import fr_FR from './fr-FR.json'

import hi_IN from './hi-IN.json'
import hu_HU from './hu-HU.json'
import id_ID from './id-ID.json'
import it_IT from './it-IT.json'
import ja_JP from './ja-JP.json'
import ko_KR from './ko-KR.json'
import nb_NO from './nb-NO.json'

import nl_BE from './nl-BE.json'
import nl_NL from './nl-NL.json'

import pl_PL from './pl-PL.json'

import pt_BR from './pt-BR.json'
import pt_PT from './pt-PT.json'

import ru_RU from './ru-RU.json'
import sv_SE from './sv-SE.json'
import th_TH from './th-TH.json'
import tr_TR from './tr-TR.json'

import zh_CN from './zh-CN.json'
import zh_HK from './zh-HK.json'
import zh_TW from './zh-TW.json'

export {
  ar_EG,
  ar_SA,
  ca_ES,
  cs_CZ,
  da_DK,
  de_AT,
  de_CH,
  de_DE,
  en_CA,
  en_GB,
  en_HK,
  en_IE,
  en_IN,
  en_US,
  es_ES,
  es_MX,
  fi_FI,
  fr_BE,
  fr_CA,
  fr_CH,
  fr_FR,
  hi_IN,
  hu_HU,
  id_ID,
  it_IT,
  ja_JP,
  ko_KR,
  nb_NO,
  nl_BE,
  nl_NL,
  pl_PL,
  pt_BR,
  pt_PT,
  ru_RU,
  sv_SE,
  th_TH,
  tr_TR,
  zh_CN,
  zh_HK,
  zh_TW
}
