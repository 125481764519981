{
  "_comment": "[CI-LOCKED] Šio failo turinys užrakintas. Galite prisidėti, tačiau gali užtrukti, kol keitimai bus patikrinti.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Robotas $1 pasakė:",
  "ACTIVITY_YOU_SAID_ALT": "Pasakėte:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robotas pridėjo:",
  "ACTIVITY_ERROR_BOX_TITLE": "Klaidos pranešimas",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Spustelėkite norėdami sąveikauti.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Pranešimas yra interaktyvus.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Pranešime yra vienas ar keli saitai.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Išsiųsti nepavyko.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Pridėjote:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 priedą.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 priedus.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 priedų.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 priedus.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 priedus.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Nepavyko išsiųsti. [Bandyti dar kartą][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Siunčiama",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Išsiųsta $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Ką tik",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Prieš valandą",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Prieš minutę",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Šiandien",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Vakar",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Adaptyviosios kortelės analizės klaida",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Adaptyviosios kortelės atvaizdavimo klaida",
  "ATTACHMENT_AUDIO": "Garso įrašas.",
  "ATTACHMENT_CARD": "Kortelė: $1 $2 $3",
  "ATTACHMENT_FILE": "Failas: $1",
  "ATTACHMENT_IMAGE": "Vaizdas",
  "ATTACHMENT_TEXT": "Teksto žinutė: $1",
  "ATTACHMENT_VIDEO": "Vaizdo įrašas",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Pirmyn",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Atgal",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Prijungta",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Jungiamasi…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Nepavyko prijungti.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Nutrūko tinklo ryšys. Jungiamasi iš naujo...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Atvaizdavimo klaida. Patikrinkite konsolę arba kreipkitės į roboto kūrėją.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Prisijungimas trunka ilgiau nei įprasta.",
  "CONNECTIVITY_STATUS_ALT": "Ryšio būsena: $1",
  "FILE_CONTENT_ALT": "$1",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Atsisiųsti failą $1",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Atsisiųsti failą $1, kurio dydis $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1, kurio dydis $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Klavišas „Enter“",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Pasiekti pranešimo elementus",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Pokalbio retrospektyva",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Klavišas „Escape“",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Išeiti iš pranešimo",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabuliavimo klavišas\n„Shift“ ir tabuliavimo klavišas",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Judėti tarp pranešimo elementų",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Rodyklės aukštyn klavišas\nRodyklės žemyn klavišas",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Judėti tarp pranešimų",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Klavišas „Enter“",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Atlikti veiksmą",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabuliavimo klavišas\n„Shift“ ir tabuliavimo klavišas",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Judėti tarp pokalbio lango elementų",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Pokalbio langas",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Uždaryti",
  "KEYBOARD_HELP_HEADER": "Klaviatūros valdikliai",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Atidaroma naujame lange; išorinis.",
  "RECEIPT_CARD_TAX": "Mokestis",
  "RECEIPT_CARD_TOTAL": "Iš viso",
  "RECEIPT_CARD_VAT": "PVM",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Negali siųsti tuščio pranešimo.",
  "SPEECH_INPUT_LISTENING": "Klausomasi…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofonas išjungtas",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofonas įjungtas",
  "SPEECH_INPUT_STARTING": "Pradedama…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Pirmyn",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Atgal",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Siūlomi veiksmai",
  "TEXT_INPUT_ALT": "Pranešimo įvedimo laukas",
  "TEXT_INPUT_PLACEHOLDER": "Įveskite savo pranešimą",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Siųsti",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Kalbėti",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Nusiųsti failą",
  "TOAST_ACCORDION_FEW": "Pranešimų: $1. Spustelėkite čia, kad pamatytumėte išsamesnę informaciją",
  "TOAST_ACCORDION_MANY": "Pranešimų: $1. Spustelėkite čia, kad pamatytumėte išsamesnę informaciją",
  "TOAST_ACCORDION_OTHER": "Pranešimų: $1. Spustelėkite čia, kad pamatytumėte išsamesnę informaciją",
  "TOAST_ACCORDION_TWO": "Pranešimų: $1. Spustelėkite čia, kad pamatytumėte išsamesnę informaciją",
  "TOAST_ALT_ERROR": "Klaida",
  "TOAST_ALT_INFO": "Informacija",
  "TOAST_ALT_SUCCESS": "Sėkmingai",
  "TOAST_ALT_WARN": "Įspėjimas",
  "TOAST_DISMISS_BUTTON": "Išjungti",
  "TOAST_TITLE_ALT": "Pranešimas",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Pokalbio retrospektyva, naršykite naudodami rodyklių klavišus.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "pokalbio retrospektyva",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Pranešimas yra interaktyvus. Norėdami perjungti į pokalbio retrospektyvą, 2–3 kartus paspauskite klavišą „Shift“ ir tabuliavimo klavišą. Tada spustelėkite pranešimą, kad sąveikautumėte.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Pranešime yra vienas ar keli saitai. Norėdami perjungti į pokalbio retrospektyvą, 2–3 kartus paspauskite klavišą „Shift“ ir tabuliavimo klavišą. Tada spustelėkite pranešimą, kad sąveikautumėte.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Pranešime siūlomi veiksmai. Norėdami juos pasirinkti, 2–3 kartus paspauskite klavišą „Shift“.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Pranešime siūlomi veiksmai. Norėdami juos pasirinkti, paspauskite klavišą $1.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Nepavyko išsiųsti pranešimo.",
  "TRANSCRIPT_MORE_MESSAGES": "Daugiau pranešimų",
  "TRANSCRIPT_NEW_MESSAGES": "Nauji pranešimai",
  "TRANSCRIPT_TERMINATOR_TEXT": "Pokalbio retrospektyvos pabaiga",
  "TYPING_INDICATOR_ALT": "Rodomas teksto įvedimo indikatorius",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 rašo.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "Rašo $1 ir kiti."
}