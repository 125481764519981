import React from 'react'
import { useList } from '../../../Contexts/ListContext'
import type { Filter } from '../../../../models/filterTypes'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { ChevronRightIcon } from '../../../Icons/ChevronIcons'
import MobileUpperBand from './MobileUpperBand'

type MobileFilterProps = {
  close: () => void
  resetFilters: () => void
  setCurrentOpened: (filter: Filter) => void
}

const MobileFilterPage = ({
  close,
  resetFilters,
  setCurrentOpened
}: MobileFilterProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const { filters, processFiltersOnChange } = useList()
  const { locale } = useLocaleStore((state) => ({
    locale: state.locale
  }))

  const handleSelectAllAndOpen = (filter: Filter): void => {
    processFiltersOnChange([filter], true)
    setCurrentOpened(filter)
  }

  return (
    <React.Fragment>
      {/* Upper band with left arrow + text + reset on the right */}
      <MobileUpperBand
        title={translation.common.filter}
        handleReset={resetFilters}
        handleReturn={close}
      />
      <div className='rf-w-full rf-px-2'>
        {filters.map((filter, key) => {
          return (
            <div
              key={key}
              className={`rf-py-4 rf-text-xl rf-font-semibold rf-flex rf-justify-between rf-items-center rf-cursor-pointer ${
                key !== 0 && 'rf-border-t rf-border-t-[#CBC9C9]'
              }`}
              onClick={(): void => setCurrentOpened(filter)}
            >
              {capitalizeFirstLetter(filter.title, locale)}
              <div className='rf-flex rf-items-center'>
                {filter.id !== 'Event.DateRange' && (
                  <div
                    className='rf-text-sm rf-text-gray rf-mr-5'
                    onClick={(): void => handleSelectAllAndOpen(filter)}
                  >
                    {capitalizeFirstLetter(translation.common.all, locale)}
                  </div>
                )}
                <ChevronRightIcon className='rf-h-5' color='#000' />
              </div>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default MobileFilterPage
