import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'

type TitleProps = {
  className?: string
  title: string
  numberOfResults?: number
}

const Title = ({
  className,
  title,
  numberOfResults
}: TitleProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  return (
    <div
      className={`rf-mb-4 rf-flex rf-flex-col rf-items-start rf-justify-center large:rf-flex-row large:rf-items-baseline large:rf-justify-start ${className}`}
    >
      <div className='rf-text-3xl large:rf-text-5xl rf-font-bold'>{title}</div>
      {numberOfResults !== undefined && (
        <div className='rf-text-gray large:rf-ml-4'>
          {numberOfResults}{' '}
          {numberOfResults > 1
            ? translation.common.results
            : translation.common.result}
        </div>
      )}
    </div>
  )
}

export default Title
