import React from 'react'
import { useSpeechStore } from '../Contexts/speechStore'
import { useView } from '../Contexts/ViewContext'

interface VoiceInputProps {
  className?: string
}

const VoiceInput = ({ className }: VoiceInputProps): JSX.Element => {
  const { isMobile } = useView()
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )

  return lastRecognitionInterim ? (
    isMobile ? (
      <p className={className}>{lastRecognitionInterim}</p>
    ) : (
      <div className='rf-w-full rf-px-4 rf-py-3 rf-subtitle-size-auto rf-font-bold rf-text-truewhite rf-text-shadow-black'>
        {lastRecognitionInterim}
      </div>
    )
  ) : (
    <React.Fragment />
  )
}

export default VoiceInput
