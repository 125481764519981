import { useActivityStore } from '../components/Contexts/activityStore'

const useShowEmergency = (): boolean => {
  const lastBotActivity = useActivityStore((state) => state.lastBotActivity)

  if (
    lastBotActivity &&
    lastBotActivity.type === 'event' &&
    lastBotActivity.name === 'Hospitality.ShowEmergency'
  ) {
    return true
  }

  return false
}

export default useShowEmergency
