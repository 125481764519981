{
  "_comment": "[CI-LOCKED] A fájl tartalma zárolva van. Szívesen fogadjuk a hozzájárulásokat, de a módosítások érvényesítése elhúzódhat.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "A(z) $1 robot azt mondta:",
  "ACTIVITY_YOU_SAID_ALT": "Ön azt mondta:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robot által csatolva:",
  "ACTIVITY_ERROR_BOX_TITLE": "Hibaüzenet",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Kattintson az interakcióhoz.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Az üzenet interaktív.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Egy vagy több hivatkozás van az üzenetben.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Sikertelen küldés.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Ön által csatolva:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 melléklet.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 melléklet.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 melléklet.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 melléklet.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 melléklet.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "A küldés sikertelen. [Újra][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Küldés",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Elküldve: $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Éppen most",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Egy órája",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Egy perce",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Ma",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Tegnap",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Elemzési hiba az adaptív kártyánál",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Renderelési hiba az adaptív kártyánál",
  "ATTACHMENT_AUDIO": "Egy hangklip.",
  "ATTACHMENT_CARD": "Egy kártya: $1 $2 $3",
  "ATTACHMENT_FILE": "Egy fájl: $1",
  "ATTACHMENT_IMAGE": "Egy kép",
  "ATTACHMENT_TEXT": "Egy szöveg: $1",
  "ATTACHMENT_VIDEO": "Egy videoklip",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Következő",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Előző",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Csatlakoztatva",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Csatlakozás...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Nem lehet csatlakozni.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "A hálózati kapcsolat megszakadt. Újracsatlakozás...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Renderelési hiba. Ellenőrizze a konzolt, vagy forduljon a robot fejlesztőjéhez.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "A szokásosnál tovább tart a csatlakozás.",
  "CONNECTIVITY_STATUS_ALT": "Kapcsolat állapota: $1",
  "FILE_CONTENT_ALT": "$1",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "$1 fájl letöltése",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "A(z) $1 fájl letöltése. Méret: $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1 mérete: $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter billentyű",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Az üzenet elemeinek elérése",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Csevegés előzményei",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape billentyű",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Kilépés az üzenetből",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabulátor billentyű\nShift + tabulátor billentyű",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Az üzenetben lévő elemek közötti navigáció",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Felfelé mutató nyíl\nLefelé mutató nyíl",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Navigáció az üzenetek között",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter billentyű",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Művelet végrehajtása",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabulátor billentyű\nShift + tabulátor billentyű",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "A csevegőablakban lévő elemek közötti navigáció",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Csevegőablak",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Bezárás",
  "KEYBOARD_HELP_HEADER": "Billentyűzetvezérlők",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Megnyitja egy új ablakban; külső hivatkozás.",
  "RECEIPT_CARD_TAX": "Adó",
  "RECEIPT_CARD_TOTAL": "Összesen",
  "RECEIPT_CARD_VAT": "ÁFA",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Nem lehet üres üzenetet küldeni.",
  "SPEECH_INPUT_LISTENING": "Hallgatás...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon kikapcsolva",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon bekapcsolva",
  "SPEECH_INPUT_STARTING": "Kezdés...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Következő",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Előző",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Javasolt műveletek",
  "TEXT_INPUT_ALT": "Üzenet beviteli mezője",
  "TEXT_INPUT_PLACEHOLDER": "Írja be az üzenetét",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Küldés",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Beszéd",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Fájl feltöltése",
  "TOAST_ACCORDION_FEW": "$1 értesítés: Kattintson ide a részletek megtekintéséhez",
  "TOAST_ACCORDION_MANY": "$1 értesítés: Kattintson ide a részletek megtekintéséhez",
  "TOAST_ACCORDION_OTHER": "$1 értesítés: Kattintson ide a részletek megtekintéséhez",
  "TOAST_ACCORDION_TWO": "$1 értesítés: Kattintson ide a részletek megtekintéséhez",
  "TOAST_ALT_ERROR": "Hiba",
  "TOAST_ALT_INFO": "Információk",
  "TOAST_ALT_SUCCESS": "Sikeres",
  "TOAST_ALT_WARN": "Figyelmeztetés",
  "TOAST_DISMISS_BUTTON": "Elvetés",
  "TOAST_TITLE_ALT": "Értesítés",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Csevegési előzmények; a navigáláshoz használja a nyílbillentyűket.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "csevegés előzményei",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Az üzenet interaktív. A csevegési előzményekre váltáshoz nyomja le 2-3 alkalommal a shift és tabulátor billentyűket. Ezután kattintson az üzenetre az interakcióhoz.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Egy vagy több hivatkozás van az üzenetben. A csevegési előzményekre váltáshoz nyomja le 2-3 alkalommal a shift és tabulátor billentyűket. Ezután kattintson az üzenetre az interakcióhoz.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Az üzenet javasolt intézkedéseket tartalmaz. Nyomja meg 2-3 alkalommal a Shift+Tab billentyűket a kiválasztásukhoz.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Az üzenet javasolt intézkedéseket tartalmaz. Nyomja meg a $1 billentyűt a kiválasztásukhoz.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Nem sikerült elküldeni az üzenetet.",
  "TRANSCRIPT_MORE_MESSAGES": "További üzenetek",
  "TRANSCRIPT_NEW_MESSAGES": "Új üzenetek",
  "TRANSCRIPT_TERMINATOR_TEXT": "A csevegési előzmények vége",
  "TYPING_INDICATOR_ALT": "Gépelésjelző megjelenítése",
  "TYPING_INDICATOR_SINGLE_TEXT": "1 $ és ír.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "1 $ és mások még írnak."
}