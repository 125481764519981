{
  "_comment": "[CI-LOCKED] Obsah tohto súboru je zamknutý. Príspevky sú vítané, ale overenie zmien sa môže oneskoriť.",
  "ACCESS_KEY_ALT": "Alt + Shift + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control + Option + $1",
  "ACTIVITY_BOT_SAID_ALT": "Robot $1 povedal:",
  "ACTIVITY_YOU_SAID_ALT": "Povedali ste:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robot priložil:",
  "ACTIVITY_ERROR_BOX_TITLE": "Chybové hlásenie",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Kliknutím môžete vykonať interakciu.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Správa je interaktívna.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Najmenej jedno prepojenie v správe.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Odoslanie zlyhalo.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Priložili ste:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 príloha.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 prílohy.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 prílohy.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 príloh.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 prílohy.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Odoslanie zlyhalo. [Skúsiť znova][RETRY]",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Odosiela sa",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Odoslané o $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Práve teraz",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Pred hodinou",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Pred minútou",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Dnes",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Včera",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Chyba analýzy adaptívnej karty",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Chyba vykreslenia adaptívnej karty",
  "ATTACHMENT_AUDIO": "Zvukový klip.",
  "ATTACHMENT_CARD": "Karta: $1 $2 $3",
  "ATTACHMENT_FILE": "Súbor: $1",
  "ATTACHMENT_IMAGE": "Obrázok",
  "ATTACHMENT_TEXT": "Text: $1",
  "ATTACHMENT_VIDEO": "Videoklip",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Nasledujúce",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Predchádzajúce",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Pripojené",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Pripája sa...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Pripojenie sa nepodarilo.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Vyskytlo sa prerušenie siete. Prebieha opätovné pripájanie...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Chyba vykreslenia. Skontrolujte konzolu alebo sa obráťte na vývojára bota.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Pripojenie trvá dlhšie ako zvyčajne.",
  "CONNECTIVITY_STATUS_ALT": "Stav pripojenia: $1",
  "FILE_CONTENT_ALT": "$1",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Stiahnuť súbor $1",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Stiahnuť súbor $1 s veľkosťou $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1 s veľkosťou $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Kláves Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Získajte prístup k položkám v správe",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "História konverzácie",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Kláves Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Ukončiť zobrazenie správy",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Kláves Tab\nKlávesy Shift + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Prechádzajte medzi položkami v správe",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Kláves so šípkou nahor\nKláves so šípkou nadol",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Prechádzajte medzi správami",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Kláves Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Vykonajte akciu",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Kláves Tab\nKlávesy Shift + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Prechádzajte medzi položkami v okne konverzácie",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Okno konverzácie",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Zavrieť",
  "KEYBOARD_HELP_HEADER": "Ovládacie prvky klávesnice",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Otvorí sa v novom okne, externé.",
  "RECEIPT_CARD_TAX": "Daň",
  "RECEIPT_CARD_TOTAL": "Celkový počet",
  "RECEIPT_CARD_VAT": "DPH",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Prázdnu správu nemožno odoslať.",
  "SPEECH_INPUT_LISTENING": "Počúvanie...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Vypnúť mikrofón",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Zapnúť mikrofón",
  "SPEECH_INPUT_STARTING": "Spúšťa sa...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Nasledujúce",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Predchádzajúce",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Navrhované akcie",
  "TEXT_INPUT_ALT": "Vstupné pole pre správu",
  "TEXT_INPUT_PLACEHOLDER": "Zadajte svoju správu",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Odoslať",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Čítať",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Nahrať súbor",
  "TOAST_ACCORDION_FEW": "Počet oznámení: $1 – kliknutím sem sa zobrazia podrobnosti",
  "TOAST_ACCORDION_MANY": "Počet oznámení: $1 – kliknutím sem sa zobrazia podrobnosti",
  "TOAST_ACCORDION_OTHER": "Počet oznámení: $1 – kliknutím sem sa zobrazia podrobnosti",
  "TOAST_ACCORDION_TWO": "Počet oznámení: $1 – kliknutím sem sa zobrazia podrobnosti",
  "TOAST_ALT_ERROR": "Chyba",
  "TOAST_ALT_INFO": "Informácie",
  "TOAST_ALT_SUCCESS": "Úspešné",
  "TOAST_ALT_WARN": "Upozornenie",
  "TOAST_DISMISS_BUTTON": "Zrušiť",
  "TOAST_TITLE_ALT": "Oznámenie",
  "TRANSCRIPT_ARIA_LABEL_ALT": "História konverzácie – na navigáciu stlačte klávesy so šípkami.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "história konverzácie",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Správa je interaktívna. Stlačením klávesov Shift a Tab 2- až 3-krát prepnete do histórie konverzácie. Potom môžete interagovať kliknutím na správu.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Najmenej jedno prepojenie v správe. Stlačením klávesov Shift a Tab 2- až 3-krát prepnete do histórie konverzácie. Potom môžete interagovať kliknutím na správu.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Správa obsahuje navrhované akcie. Vyberte ich stlačením klávesov Shift + Tab 2 až 3-krát.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Správa obsahuje navrhované akcie. Vyberte ich stlačením klávesu $1.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Nepodarilo sa odoslať správu.",
  "TRANSCRIPT_MORE_MESSAGES": "Ďalšie správy",
  "TRANSCRIPT_NEW_MESSAGES": "Nové správy",
  "TRANSCRIPT_TERMINATOR_TEXT": "Koniec histórie konverzácie",
  "TYPING_INDICATOR_ALT": "Zobrazuje sa indikátor písania",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 píše.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 a ďalší píšu."
}