import React, { useEffect, useMemo, useRef } from 'react'
import { useView } from '../../Contexts/ViewContext'
import useActivitiesTextMode from '../../../hooks/useActivitiesTextMode'
import type { RetorikActivity } from '../../../models/activityTypes'
import Attachments from '../../Attachments/Attachments'
import ConversationDisplay from './ConversationDisplay'
import { useSpeechStore } from '../../Contexts/speechStore'

interface ConversationPanelLargeProps {
  homeActivity: RetorikActivity | undefined
  itemsInConversation: number
}

const ConversationPanelLarge = ({
  homeActivity,
  itemsInConversation
}: ConversationPanelLargeProps): JSX.Element => {
  const activities = useActivitiesTextMode()
  const { mediumLandscape } = useView()
  const streamingId = useSpeechStore((state) => state.streamingReplyToIdForText)
  const streamingQueue = useSpeechStore((state) => state.streamingQueueForText)

  const botEndRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const userQuestion = useMemo<RetorikActivity | undefined>(() => {
    return activities.length && activities[activities.length - 1].question?.text
      ? activities[activities.length - 1].question
      : undefined
  }, [activities])

  const botResponses = useMemo<Array<RetorikActivity> | undefined>(() => {
    return activities.length
      ? activities[activities.length - 1].answers
      : undefined
  }, [activities])

  const handleScroll = (): void => {
    botEndRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      handleScroll()
    }, 100)

    return (): void => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [itemsInConversation])

  return homeActivity?.attachments?.length ||
    streamingId ||
    userQuestion ||
    botResponses ? (
    <div
      className={`rf-relative rf-max-h-full ${
        mediumLandscape ? 'rf-col-start-4' : 'rf-col-start-5'
      } vertical:rf-col-start-1 rf-col-span-full rf-row-start-4 rf-row-end-12 large-vertical:rf-row-start-7 large-vertical:rf-row-span-6 rf-self-start rf-overflow-hidden rf-justify-self-center rf-w-3/5 rf-flex rf-flex-col rf-rounded rf-bg-textModePanelBackground`}
    >
      <div
        id='retorik-conversation'
        className='rf-relative rf-max-h-full rf-bg-textModePanelBackground rf-py-4 rf-overflow-y-scroll rf-overflow-x-hidden rf-scrollbar-thin vertical:rf-overflow-x-auto rf-justify-self-center rf-flex rf-flex-col rf-text-size-auto rf-text-trueblack rf-shadow-[3px_3px_6px_#00000029]'
      >
        {/* Home activity attachments or only the last question / answers */}
        {homeActivity?.attachments?.length ? (
          <div className='rf-pt-4 rf-flex rf-flex-col rf-text-size-auto rf-gap-2'>
            <Attachments activity={homeActivity} />
          </div>
        ) : (
          <ConversationDisplay
            userQuestion={userQuestion}
            botResponses={botResponses}
            streamingId={streamingId}
            streamingQueue={streamingQueue}
            triggerScroll={handleScroll}
          />
        )}
        <div ref={botEndRef} />
      </div>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default ConversationPanelLarge
