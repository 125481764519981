import React, { useEffect, useMemo, useState } from 'react'
import { useView } from '../Contexts/ViewContext'
import { useRetorik } from '../Contexts/RetorikContext'
import { useUtilsStore, setListClosed } from '../Contexts/utilsStore'
import useActivitiesTextMode from '../../hooks/useActivitiesTextMode'
import type { RetorikActivity } from '../../models/activityTypes'
import ListTemplate from '../Templates/List'
import ConversationPanelMobile from './ConversationPanel/ConversationPanelMobile'
import ConversationPanelLarge from './ConversationPanel/ConversationPanelLarge'
import { checkListDisplay } from '../../utils/checkListDisplay'

const ConversationPanel = (): JSX.Element => {
  const activities = useActivitiesTextMode()
  const {
    configuration: { forceMobileView }
  } = useRetorik()
  const { isMobile, showHomeAttachments } = useView()
  const listClosed = useUtilsStore((state) => state.listClosed)
  const [listActivity, setListActivity] = useState<RetorikActivity | undefined>(
    undefined
  )

  /**
   * Number of questions and answers in the conversation. Used to trigger useEffects more efficiently
   */
  const itemsInConversation = useMemo<number>(() => {
    let number = 0
    activities.forEach((element) => {
      number += element.answers.length + 1
    })
    return number
  }, [activities])

  /**
   * Scroll to the botEndRef HTML element at the bottom of the view, then check if there is an activity in need of List display.
   * Finally, set list state depending on the check's result.
   */
  useEffect(() => {
    const answers = activities.length
      ? activities[activities.length - 1].answers
      : []
    setListActivity(
      answers.find((activity: RetorikActivity) => checkListDisplay(activity))
    )
  }, [itemsInConversation])

  useEffect(() => {
    if (listClosed || showHomeAttachments) setListActivity(undefined)
  }, [listClosed, showHomeAttachments])

  return (
    <React.Fragment>
      {listActivity && !listClosed ? (
        <ListTemplate
          activity={listActivity}
          onClose={() => setListClosed(true)}
        />
      ) : isMobile || forceMobileView ? (
        <ConversationPanelMobile
          homeActivity={showHomeAttachments}
          itemsInConversation={itemsInConversation}
        />
      ) : (
        <ConversationPanelLarge
          homeActivity={showHomeAttachments}
          itemsInConversation={itemsInConversation}
        />
      )}
    </React.Fragment>
  )
}

export { ConversationPanel }
