import React, { useState, useEffect, useRef } from 'react'
import { CommentsPennantIcon } from '../../Icons/DetailedPOIIcons'
import { useLocaleStore } from '../../Contexts/localeStore'

type ComponentsPennantType = {
  className?: string
  color?: string
  customerReviews: number
}

const CommentsPennant = ({
  className,
  color,
  customerReviews
}: ComponentsPennantType): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const [widthInPx, setWidthInPx] = useState<number>(0)
  const pennantRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (pennantRef.current) {
      setWidthInPx(Math.ceil(pennantRef.current.offsetWidth / 2))
    }
  }, [pennantRef])

  return (
    <div
      className={`${className} rf-flex rf-flex-col rf-justify-center rf-text-white`}
      ref={pennantRef}
    >
      <div
        className='rf-flex rf-flex-col rf-items-center rf-p-2 rf-bg-primary'
        style={{ backgroundColor: color }}
      >
        <CommentsPennantIcon className='rf-w-12 large:rf-w-14 large-vertical:rf-w-16 rf-pb-2' />
        <div className='rf-flex-col rf-text-center rf-text-size-auto rf-uppercase'>
          {translation.poi.comment.split(' ').map((word, index) => {
            return <div key={index}>{word}</div>
          })}
        </div>
        <div className='rf-font-bold rf-subtitle-size-auto'>
          {customerReviews}/10
        </div>
      </div>
      <div
        className='rf-w-0 rf-h-0 rf-border-t-0 rf-border-x-primary rf-border-b-transparent'
        style={{
          borderLeftColor: color,
          borderRightColor: color,
          borderBottomWidth: (widthInPx * 2) / 3,
          borderLeftWidth: widthInPx,
          borderRightWidth: widthInPx
        }}
      />
    </div>
  )
}

export default CommentsPennant
