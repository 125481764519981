import React, { useEffect, useRef } from 'react'
import { hooks } from 'botframework-webchat'
import { useSpeechStore } from '../Contexts/speechStore'
import { useView } from '../Contexts/ViewContext'
import { useRetorik } from '../Contexts/RetorikContext'
import { useUtilsStore } from '../Contexts/utilsStore'
import { CurrentSubView, RetorikEvent, Routes } from '../../models/enums'

const { useEmitTypingIndicator } = hooks

const typingTimer = 2000

const SendTypingIndicator = (): JSX.Element => {
  const sendTypingIndicator = useEmitTypingIndicator()
  const speaking = useSpeechStore((state) => state.speaking)
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )
  const { route } = useView()
  const { appAvailable, loaderClosed } = useRetorik()
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const listOpened = useUtilsStore((state) => state.listOpened)
  const retorikEvent = useUtilsStore((state) => state.retorikEvent)
  const userIsTyping = useUtilsStore((state) => state.userIsTyping)
  const userIsSwiping = useUtilsStore((state) => state.userIsSwiping)

  const dataRef = useRef<boolean | null>(false)

  useEffect(() => {
    dataRef.current =
      !loaderClosed ||
      lastRecognitionInterim !== '' ||
      userIsTyping ||
      userIsSwiping ||
      speaking ||
      currentSubView !== CurrentSubView.none ||
      route === Routes.News ||
      retorikEvent === RetorikEvent.DetailViewOpen ||
      listOpened
  }, [
    appAvailable,
    loaderClosed,
    lastRecognitionInterim,
    userIsTyping,
    userIsSwiping,
    speaking,
    currentSubView,
    route,
    retorikEvent,
    listOpened
  ])

  const checkShouldSendTypingIndicator = (): void => {
    dataRef.current && sendTypingIndicator()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkShouldSendTypingIndicator()
    }, typingTimer)

    return () => {
      interval && clearInterval(interval)
    }
  }, [])

  return <React.Fragment />
}

export default SendTypingIndicator
