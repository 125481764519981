{
  "_comment": "[CI-LOCKED] The content of this file is locked. Contributions are welcome, but change validation may be delayed.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "_ACCESS_KEY_ALT.comment": "These are keyboard modifier keys for screen reader.",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "_ACCESS_KEY_FOR_MAC_ALT.comment": "These are keyboard modifier keys for screen reader on a Mac.",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 said:",
  "_ACTIVITY_BOT_SAID_ALT.comment": "This is for screen reader only. $1 is the initials for the bot (e.g. \"JD\").",
  "ACTIVITY_YOU_SAID_ALT": "You said:",
  "_ACTIVITY_YOU_SAID_ALT.comment": "This is for screen reader only.",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot attached:",
  "_ACTIVITY_BOT_ATTACHED_ALT.comment": "This is for screen reader and is narrated before each attachments sent by the bot.",
  "ACTIVITY_ERROR_BOX_TITLE": "Error message",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Click to interact.",
  "_ACTIVITY_INTERACTIVE_FOOTNOTE_ALT.comment": "This is for screen reader. When the user is navigating to this message which is either contains interactive elements, contains links, or failed to send (with a retry button), it will give this hint.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Message is interactive.",
  "_ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT.comment": "This is for screen reader. When the user is navigating to this message which contains interactive elements (excluding links), it will give this hint.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "One or more links in the message.",
  "_ACTIVITY_INTERACTIVE_REASON_LINK_ALT.comment": "This is for screen reader. When the user is navigating to this message which contains links, it will give this hint.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Send failed.",
  "_ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT.comment": "This is for screen reader. When the user is navigating to this message which was failed to send, it will give this hint.",
  "ACTIVITY_YOU_ATTACHED_ALT": "You attached:",
  "_ACTIVITY_YOU_ATTACHED_ALT.comment": "This is for screen reader and is narrated before each attachments sent by the user.",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 attachment.",
  "_ACTIVITY_NUM_ATTACHMENTS_ONE_ALT.comment": "This is for screen reader and only narrated when the message arrive. The narration when the message arrive, could be \"Bot WC said: Hello, World! 1 attachment. Sent at 2020-01-02 12:34 PM.\". This is for plural rule of \"one\".",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 attachments.",
  "_ACTIVITY_NUM_ATTACHMENTS_FEW_ALT.comment": "$1 is the number of attachments. This is for plural rule of \"few\".",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 attachments.",
  "_ACTIVITY_NUM_ATTACHMENTS_MANY_ALT.comment": "$1 is the number of attachments. This is for plural rule of \"many\".",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 attachments.",
  "_ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT.comment": "$1 is the number of attachments. This is for plural rule of \"other\".",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 attachments.",
  "_ACTIVITY_NUM_ATTACHMENTS_TWO_ALT.comment": "$1 is the number of attachments. This is for plural rule of \"two\".",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Send failed. [Retry][RETRY].",
  "_ACTIVITY_STATUS_SEND_FAILED_RETRY.comment": "This is Markdown. Do not localize \"[RETRY]\" (the one in uppercase).",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Sending",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Sent at $1",
  "_ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT.comment": "$1 is the time when the message is successfully sent, in absolute format, e.g. 12:34 PM.",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Just now",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "An hour ago",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "A minute ago",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Today",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Yesterday",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Adaptive Card parse error",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Adaptive Card render error",
  "ATTACHMENT_AUDIO": "An audio clip.",
  "ATTACHMENT_CARD": "A card: $1 $2 $3",
  "_ATTACHMENT_CARD.comment": "$1 is the title of the card. $2 is the subtitle. $3 is the text content.",
  "ATTACHMENT_FILE": "A file: $1",
  "_ATTACHMENT_FILE.comment": "$1 is the filename.",
  "ATTACHMENT_IMAGE": "An image",
  "ATTACHMENT_TEXT": "A text: $1",
  "_ATTACHMENT_TEXT.comment": "$1 is the content.",
  "ATTACHMENT_VIDEO": "A video clip",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Next",
  "_CAROUSEL_FLIPPER_NEXT_ALT.comment": "This is for screen reader for the label of the right flipper button for carousels for left-to-right language or left flipper button for right-to-left (RTL) languages. To support RTL, the language used in this text should be less directional.",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Previous",
  "_CAROUSEL_FLIPPER_PREVIOUS_ALT.comment": "This is for screen reader for the label of the left flipper button for carousels for left-to-right language or right flipper button for right-to-left (RTL) languages. To support RTL, the language used in this text should be less directional.",
  "CITATION_MODEL_DIALOG_ALT": "Citation",
  "_CITATION_MODEL_DIALOG_ALT.comment": "This is for screen reader. This is the title of a modal dialog showing citation. When end-user clicks on a citation marker in the bot response, this citation modal dialog will open and show citation related to the marker.",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Connected",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Connecting…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Unable to connect.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Network interruption occurred. Reconnecting…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Render error. Please check the console or contact the bot developer.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Taking longer than usual to connect.",
  "CONNECTIVITY_STATUS_ALT": "Connectivity Status: $1",
  "_CONNECTIVITY_STATUS_ALT.comment": "This is for screen reader. $1 will be one of \"CONNECTIVITY_STATUS_ALT_\"*.",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Download file '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Download file '$1' of size $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' of size $2",
  "_FILE_CONTENT_ALT.comment": "$1 is the name of the file, e.g. document.docx. $2 is the file size, e.g. 12 MB",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter key",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Access items in message",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Chat history",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape key",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Exit message",
  "_KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER.comment": "While focus is on the message, when the user press ESCAPE key, it should exit the message focus mode and focus back on the text box in the send box.",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab key\nShift + tab key",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Move between items in a message",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Arrow up key\nArrow down key",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Move between messages",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter key",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Do action",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab key\nShift + tab key",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Move between items in the chat window",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Chat window",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Close",
  "KEYBOARD_HELP_HEADER": "Keyboard controls",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Opens in a new window; external.",
  "_MARKDOWN_EXTERNAL_LINK_ALT.comment": "This is for the screen reader and is added to the label of the hyperlink. When clicked, the hyperlink will open in a new tab or window.",
  "RECEIPT_CARD_TAX": "Tax",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "VAT",
  "REFERENCE_LIST_HEADER_ONE": "$1 reference",
  "_REFERENCE_LIST_HEADER_ONE.comment": "Header of the expand/collapse of reference list. This is for plural rule of \"one\".",
  "REFERENCE_LIST_HEADER_FEW": "$1 references",
  "_REFERENCE_LIST_HEADER_FEW.comment": "Header of the expand/collapse of reference list. This is for plural rule of \"few\".",
  "REFERENCE_LIST_HEADER_MANY": "$1 references",
  "_REFERENCE_LIST_HEADER_MANY.comment": "Header of the expand/collapse of reference list. This is for plural rule of \"many\".",
  "REFERENCE_LIST_HEADER_OTHER": "$1 references",
  "_REFERENCE_LIST_HEADER_OTHER.comment": "Header of the expand/collapse of reference list. This is for plural rule of \"other\".",
  "REFERENCE_LIST_HEADER_TWO": "$1 references",
  "_REFERENCE_LIST_HEADER_TWO.comment": "Header of the expand/collapse of reference list. This is for plural rule of \"two\".",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Cannot send empty message.",
  "_SEND_BOX_IS_EMPTY_TOOLTIP_ALT.comment": "This is for screen reader on the send button. When the send text box is empty, the send button is disabled and this text on the send button explain why the button is disabled.",
  "SPEECH_INPUT_LISTENING": "Listening…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Microphone off",
  "_SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT.comment": "This is for screen reader and is the label of the microphone button, when clicked, will close microphone.",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Microphone on",
  "_SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT.comment": "This is for screen reader and is the label of the microphone button, when clicked, will open microphone.",
  "SPEECH_INPUT_STARTING": "Starting…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Next",
  "_SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT.comment": "This is for screen reader for the label of the right flipper button for suggested actions. Probably can re-use the value from CAROUSEL_FLIPPER_NEXT_ALT.",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Previous",
  "_SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT.comment": "This is for screen reader for the label of the left flipper button for suggested actions. Probably can re-use the value from CAROUSEL_FLIPPER_PREVIOUS_ALT.",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Suggested actions",
  "_SUGGESTED_ACTIONS_LABEL_ALT.comment": "This is for screen reader. Browser will read as \"Suggested actions toolbar\", where \"toolbar\" is a role name appended by browser.",
  "TEXT_INPUT_ALT": "Message input box",
  "_TEXT_INPUT_ALT.comment": "This is for screen reader for the label of the message input box.",
  "TEXT_INPUT_PLACEHOLDER": "Type your message",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Send",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Speak",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Upload file",
  "TOAST_ACCORDION_FEW": "$1 Notifications: Click here to see details",
  "_TOAST_ACCORDION_FEW.comment": "$1 is the number of notifications. This is for plural rule of \"few\".",
  "TOAST_ACCORDION_MANY": "$1 Notifications: Click here to see details",
  "_TOAST_ACCORDION_MANY.comment": "$1 is the number of notifications. This is for plural rule of \"many\".",
  "TOAST_ACCORDION_OTHER": "$1 Notifications: Click here to see details",
  "_TOAST_ACCORDION_OTHER.comment": "$1 is the number of notifications. This is for plural rule of \"other\".",
  "TOAST_ACCORDION_TWO": "$1 Notifications: Click here to see details",
  "_TOAST_ACCORDION_TWO.comment": "$1 is the number of notifications. This is for plural rule of \"two\".",
  "TOAST_ALT_ERROR": "Error",
  "_TOAST_ALT_ERROR.comment": "This is for screen reader.",
  "TOAST_ALT_INFO": "Information",
  "_TOAST_ALT_INFO.comment": "This is for screen reader.",
  "TOAST_ALT_SUCCESS": "Success",
  "_TOAST_ALT_SUCCESS.comment": "This is for screen reader.",
  "TOAST_ALT_WARN": "Warning",
  "_TOAST_ALT_WARN.comment": "This is for screen reader.",
  "TOAST_DISMISS_BUTTON": "Dismiss",
  "TOAST_TITLE_ALT": "Notification",
  "_TOAST_TITLE_ALT.comment": "This is for screen reader. The label for individiual notification.",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Chat history, press arrow keys to navigate.",
  "_TRANSCRIPT_ARIA_LABEL_ALT.comment": "This is for screen reader for the label that will be narrated when the chat history is focused.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "chat history",
  "_TRANSCRIPT_ARIA_ROLE_ALT.comment": "This is for screen reader for the aria-roledescription of the chat history container.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Message is interactive. Press shift tab key 2 to 3 times to switch to the chat history. Then click on the message to interact.",
  "_TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT.comment": "This is for screen reader. When one or more activities arrived with interactive elements (no links) attached, the chat history (live region) will give this hint.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "One or more links in the message. Press shift tab key 2 to 3 times to switch to the chat history. Then click on the message to interact.",
  "_TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT.comment": "This is for screen reader. When one or more activities arrived with links attached, the chat history (live region) will give this hint.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Message has suggested actions. Press shift tab 2 to 3 times to select them.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Message has suggested actions. Press $1 to select them.",
  "_TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT.comment": "$1 will be \"ACCESS_KEY_ALT\".",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Failed to send message.",
  "TRANSCRIPT_MORE_MESSAGES": "More messages",
  "TRANSCRIPT_NEW_MESSAGES": "New messages",
  "TRANSCRIPT_TERMINATOR_TEXT": "End of chat history",
  "TYPING_INDICATOR_ALT": "Showing typing indicator",
  "_TYPING_INDICATOR_SINGLE_TEXT.comment": "This shows when a single user is typing.",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 is typing.",
  "_TYPING_INDICATOR_MULTIPLE_TEXT.comment": "This shows when two or more users are typing simultaneously.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 and others are typing.",
  "VOTE_DISLIKE_ALT": "Dislike",
  "_VOTE_DISLIKE_ALT.comment": "This is for screen reader. The label of a button with a thumb up icon and is placed next to the timestamp. The button is for giving feedback that the end-user don't think the response is useful.",
  "VOTE_LIKE_ALT": "Like",
  "_VOTE_LIKE_ALT.comment": "This is for screen reader. The label of a button with a thumb up icon and is placed next to the timestamp. The button is for giving feedback that the end-user think the response is useful."
}
