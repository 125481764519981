import React, { useRef, useState, useEffect } from 'react'
import type { WithChildren } from '../../models/utils'
import { ScrollButton } from './ScrollButton'

type CardProps = WithChildren<{
  className?: string
  shadow?: boolean
}>

const Card = ({
  children,
  className,
  shadow = false
}: CardProps): JSX.Element => {
  const [scrollButtonDisplay, setScrollButtonDisplay] = useState<boolean>(false)
  const [scrollButtonDirection, setScrollButtonDirection] = useState<
    'top' | 'bottom'
  >('bottom')
  const botEndRef = useRef<HTMLDivElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)

  const scrollHandler = (
    to: 'bottom' | 'top',
    from: 'newMessage' | 'button'
  ): void => {
    if (to === 'bottom') {
      setScrollButtonDirection('top')
    } else {
      setScrollButtonDirection('bottom')
    }
    if (
      from === 'button' &&
      botEndRef &&
      botEndRef.current !== null &&
      to === 'bottom'
    ) {
      botEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    } else if (to === 'top' && cardRef && cardRef.current) {
      cardRef.current.children[1].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }

  const handleScroll = (): void => {
    if (cardRef.current) {
      const parentHeight = (cardRef.current as HTMLDivElement).parentElement
        ?.clientHeight
      const scrollHeight = (cardRef.current as HTMLDivElement).scrollHeight
      const scrollTop = (cardRef.current as HTMLDivElement).scrollTop
      // const offsetHeight = (cardRef.current as HTMLDivElement).offsetHeight
      const clientHeight = (cardRef.current as HTMLDivElement).clientHeight
      if (parentHeight && scrollHeight > parentHeight) {
        if (scrollHeight - scrollTop < clientHeight + 10) {
          setScrollButtonDisplay(true)
          setScrollButtonDirection('top')
        } else {
          setScrollButtonDisplay(true)
          setScrollButtonDirection('bottom')
        }
      } else {
        setScrollButtonDisplay(false)
      }
    }
  }

  /**
   * On DragStart event call :
   *  - prevent default behaviour and stop propagation of the event
   *  - this is to prevent image dragging while trying to swipe in a carousel
   * @param e : React.DragEvent
   */
  const handleDragStart = (e: React.DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()
  }

  // Check scroll at component first render
  useEffect(() => {
    const timer = setTimeout(() => {
      handleScroll()
    }, 500)

    return (): void => timer && clearTimeout(timer)
  }, [])

  return (
    <div
      className={`rf-relative rf-max-h-full rf-flex rf-flex-col rf-text-size-auto rf-unselectable ${className}`}
      draggable
      onDragStart={handleDragStart}
      style={{
        pointerEvents: 'all'
      }}
    >
      <div
        className='rf-w-full rf-max-h-full rf-rounded rf-border rf-border-cardFrameBorder rf-flex rf-flex-col rf-content-around rf-justify-start rf-overflow-y-auto rf-scrollbar-thin'
        ref={cardRef}
        onScroll={(): void => handleScroll()}
        style={{
          touchAction: 'pan-y'
        }}
      >
        <ScrollButton
          direction={scrollButtonDirection}
          display={scrollButtonDisplay}
          scrollHandler={scrollHandler}
          setPreventScroll={(): void => {}}
          className='rf-absolute rf-z-ui rf-bottom-1 rf-right-6 rf-w-9 rf-h-9 rf-cursor-pointer rf-bg-[#00000050] rf-p-[7px_12px] rf-rounded-[20px] rf-flex'
        />
        {children}
        <div ref={botEndRef} />
      </div>
      {shadow && (
        <div className='rf-relative rf--bottom-2 rf-h-0 rf-w-11/12 rf-mx-auto rf-shadow-card' />
      )}
    </div>
  )
}

export default Card
