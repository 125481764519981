{
  "_comment": "[CI-LOCKED] Bu dosyanın içeriği kilitli. Katılımcılar dosyayı kullanabilir ancak değişikliğin doğrulanması gecikebilir.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "$1 botu şöyle diyor:",
  "ACTIVITY_YOU_SAID_ALT": "Şöyle dediniz:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot eklendi:",
  "ACTIVITY_ERROR_BOX_TITLE": "Hata iletisi",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Etkileşim kurmak için tıklayın.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "İleti etkileşimli.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "İletide bir veya daha fazla bağlantı var.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Gönderilemedi.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Şunu eklediniz:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 ek.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 ek.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 ek.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 ekler.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 ek.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Gönderme başarısız. [Yeniden Dene][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Gönderiliyor",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "$1 itibarıyla gönderildi",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Hemen şimdi",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Bir saat önce",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Bir dakika önce",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Bugün",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Dün",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Uyarlamalı Kart ayrıştırma hatası",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Uyarlamalı Kart işleme hatası",
  "ATTACHMENT_AUDIO": "Ses klibi.",
  "ATTACHMENT_CARD": "Kart: $1 $2 $3",
  "ATTACHMENT_FILE": "Dosya: $1",
  "ATTACHMENT_IMAGE": "Görüntü",
  "ATTACHMENT_TEXT": "Metin: $1",
  "ATTACHMENT_VIDEO": "Video klibi",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Sonraki",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Önceki",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Bağlı",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Bağlantı kuruluyor...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Bağlantı kurulamadı.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Ağ kesintisi oluştu. Yeniden bağlanıyor...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "İşleme hatası. Lütfen konsolu denetleyin veya bot geliştiricisine başvurun.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Bağlantı kurmak normalden uzun sürüyor.",
  "CONNECTIVITY_STATUS_ALT": "Bağlantı Durumu: $1",
  "FILE_CONTENT_ALT": "\"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "\"$1\" dosyasını indir",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "$2 boyutlu \"$1\" dosyasını indir",
  "FILE_CONTENT_WITH_SIZE_ALT": "$2 boyutlu \"$1\" dosyası",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter tuşu",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Mesajdaki öğelere erişin",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Sohbet geçmişi",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape tuşu",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "İletiden çık",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Sekme tuşu\nShift + sekme tuşu",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Mesajdaki öğeler arasında gezinin",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Yukarı ok tuşu\nAşağı ok tuşu",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Mesajlar arasında gezinin",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter tuşu",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Eylem gerçekleştirin",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Sekme tuşu\nShift + sekme tuşu",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Sohbet penceresindeki öğeler arasında gezinin",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Sohbet penceresi",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Kapat",
  "KEYBOARD_HELP_HEADER": "Klavye denetimleri",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Yeni bir pencerede açılır; harici.",
  "RECEIPT_CARD_TAX": "Vergi",
  "RECEIPT_CARD_TOTAL": "Toplam",
  "RECEIPT_CARD_VAT": "KDV",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Boş ileti gönderilemez.",
  "SPEECH_INPUT_LISTENING": "Dinliyor…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon kapalı",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon açık",
  "SPEECH_INPUT_STARTING": "Başlatılıyor...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Sonraki",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Önceki",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Önerilen eylemler",
  "TEXT_INPUT_ALT": "İleti gelen kutusu",
  "TEXT_INPUT_PLACEHOLDER": "İletinizi yazın",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Gönder",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Konuş",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Dosya yükle",
  "TOAST_ACCORDION_FEW": "$1 Bildirim: Ayrıntıları görmek için buraya tıklayın",
  "TOAST_ACCORDION_MANY": "$1 Bildirim: Ayrıntıları görmek için buraya tıklayın",
  "TOAST_ACCORDION_OTHER": "$1 Bildirim: Ayrıntıları görmek için buraya tıklayın",
  "TOAST_ACCORDION_TWO": "$1 Bildirim: Ayrıntıları görmek için buraya tıklayın",
  "TOAST_ALT_ERROR": "Hata",
  "TOAST_ALT_INFO": "Bilgiler",
  "TOAST_ALT_SUCCESS": "Başarılı",
  "TOAST_ALT_WARN": "Uyarı",
  "TOAST_DISMISS_BUTTON": "Kapat",
  "TOAST_TITLE_ALT": "Bildirim",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Sohbet geçmişi, gezinmek için ok tuşlarını kullanın.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "sohbet geçmişi",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Mesaj etkileşimlidir. Sohbet geçmişine geçmek için shift sekme tuşuna 2 ila 3 kez basın. Ardından etkileşim kurmak için mesaja tıklayın.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Mesajda bir veya daha fazla bağlantı var. Sohbet geçmişine geçmek için shift sekme tuşuna 2 ila 3 kez basın. Ardından etkileşim kurmak için mesaja tıklayın.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "İletide önerilen eylemler var. Seçmek için Shift + sekme tuşlarına 2-3 kez basın.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "İletide önerilen eylemler var. Seçmek için $1 tuşuna basın.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "İleti gönderilemedi.",
  "TRANSCRIPT_MORE_MESSAGES": "Diğer iletiler",
  "TRANSCRIPT_NEW_MESSAGES": "Yeni iletiler",
  "TRANSCRIPT_TERMINATOR_TEXT": "Sohbet geçmişinin sonu",
  "TYPING_INDICATOR_ALT": "Yazma göstergesi gösteriliyor",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 yazıyor.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 ve diğer kişiler yazıyor."
}