import React from 'react'
import SynthesisManager from './SynthesisManager'
import RecognitionManager from './RecognitionManager'

interface SpeechManagerProps {
  isRetorikNews?: boolean
}

const SpeechManager = ({ isRetorikNews }: SpeechManagerProps): JSX.Element => {
  return (
    <React.Fragment>
      <SynthesisManager isRetorikNews={isRetorikNews} />
      {!isRetorikNews && <RecognitionManager />}
    </React.Fragment>
  )
}

export default SpeechManager
