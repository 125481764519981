import { useCallback } from 'react'
import { useRetorikStore } from '../components/Contexts/retorikStore'
import {
  useSpeechStore,
  setActiveRecognitionState,
  setCancel
} from '../components/Contexts/speechStore'
import { RecognitionState, Mode } from '../models/enums'

const useToggleMicrophone = (): any => {
  const mode = useRetorikStore((state) => state.mode)
  const activeRecognitionState = useSpeechStore(
    (state) => state.activeRecognitionState
  )

  const toggleMicrophone = useCallback(
    (fromExpectedInput?: boolean) => {
      if (mode === Mode.text) {
        setActiveRecognitionState(RecognitionState.Closed)
      } else {
        switch (activeRecognitionState) {
          // Do nothing if we are in a transition state
          case RecognitionState.Initializing:
          case RecognitionState.Closing:
            return
          case RecognitionState.Listening: {
            setActiveRecognitionState(RecognitionState.ForceClosing)
            setCancel(true)
            break
          }
          case RecognitionState.Closed: {
            setActiveRecognitionState(RecognitionState.Initializing)
            !fromExpectedInput && setCancel(true)
            break
          }
        }
      }
    },
    [mode, activeRecognitionState]
  )

  return toggleMicrophone
}

export default useToggleMicrophone
