import React, { useEffect, useState, useRef } from 'react'
import { useSpeech } from '../Contexts/SpeechContext'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { useActivityStore } from '../Contexts/activityStore'
import {
  useSpeechStore,
  setCancel,
  setSpeaking,
  setCurrentPlaying,
  setCurrentReplyToId,
  setEndedActivities,
  setBoundaryData,
  setStreamingReplyToId,
  setStreamingQueue,
  removeFirstFromStreamingQueue,
  setCurrentStreaming
} from '../Contexts/speechStore'
import { setRetorikNewsEnded, useUtilsStore } from '../Contexts/utilsStore'
import {
  createSpeechSynthesisPonyfill,
  SpeechSynthesisUtterance
} from '@davi-ai/web-speech-cognitive-services-davi'
import type { PonyfillOptions } from '@davi-ai/web-speech-cognitive-services-davi'
import useToggleMicrophone from '../../hooks/useToggleMicrophone'
import createUtterance from '../../utils/createUtterance'
import RetorikSpeech from './RetorikSpeech'
import checkLastbotActivity from '../../utils/checkLastbotActivity'
import { fetchSpeechServicesToken } from '../../utils/fetchSpeechServicesToken'
import { selectVoice } from '../../utils/selectVoice'
import type { CreateUtteranceParams } from '../../models/speechTypes'
import type { RetorikActivity } from '../../models/activityTypes'
import { Mode, Routes } from '../../models/enums'
import { useRetorikStore } from '../Contexts/retorikStore'
import { SayAsItem } from '../../models/types'

interface SynthesisManagerProps {
  isRetorikNews?: boolean
}

const SynthesisManager = ({
  isRetorikNews
}: SynthesisManagerProps): JSX.Element | null => {
  const cancel = useSpeechStore((state) => state.cancel)
  const streamingReplyToId = useSpeechStore((state) => state.streamingReplyToId)
  const streamingQueue = useSpeechStore((state) => state.streamingQueue)
  const cancelRef = useRef<boolean>(false)
  const currentPlaying = useSpeechStore((state) => state.currentPlaying)
  const currentReplyToId = useSpeechStore((state) => state.currentReplyToId)
  const endedActivities = useSpeechStore((state) => state.endedActivities)
  const { voice, customVoice, setVoice, ponyfillCredentials } = useSpeech()
  const { route } = useView()
  const locale = useLocaleStore((state) => state.locale)
  const mode = useRetorikStore((state) => state.mode)
  const agentData = useRetorikStore((state) => state.agentData)
  const {
    appAvailable,
    configuration: { preventExpectedInputHint, speechSynthesisOptions }
  } = useRetorik()
  const currentNewsActivity = useUtilsStore(
    (state) => state.currentNewsActivity
  )
  const lastBotActivity = useActivityStore(
    (state) => state.lastBotMessageActivity
  )
  const toggleMicrophone = useToggleMicrophone()

  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(
    null
  )
  const [nextUtterance, setNextUtterance] =
    useState<SpeechSynthesisUtterance | null>(null)
  const [utteranceEnded, setUtteranceEnded] = useState<boolean>(false)
  const queuedActivitiesRef = useRef<Array<RetorikActivity>>([])
  const currentActivityRef = useRef<RetorikActivity | null>(null)
  const currentPlayingRef = useRef<RetorikActivity | null>(null)
  const timerRef: React.MutableRefObject<any> = useRef(null)
  const [isNewsView, setIsNewsView] = useState<boolean>(!!isRetorikNews)

  const [ponyfill, setPonyfill] = useState<any>(undefined)
  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null)

  const [lastListTextPlayed, setLastListTextPlayed] = useState<string>('')
  const tempCurrentStreamingRef = useRef<string | null>(null)

  /**
   * Async function to retrieve a new token and assign it to the speechConfig inside ponyfill.speechSynthesis
   * @param region string
   * @param key string
   */
  const refreshTokenInNineMinutes = async (
    region: string,
    key: string
  ): Promise<void> => {
    // A token is valid during 10 minutes, let's refresh it after 9 minutes
    refreshTimerRef.current = setTimeout(async () => {
      const token = await fetchSpeechServicesToken(region, key)

      if (token && ponyfill?.speechSynthesis?.speechConfig) {
        ponyfill.speechSynthesis.speechConfig.authorizationToken = token
        refreshTokenInNineMinutes(region, key)
      }
    }, 1000 * 60 * 9) // 9 minutes
  }

  const fetchTokenAndCreatePonyfill = async (
    key: string,
    region: string
  ): Promise<void> => {
    const token = await fetchSpeechServicesToken(region, key)

    if (token) {
      setPonyfill(
        createSpeechSynthesisPonyfill({
          credentials: {
            region: ponyfillCredentials.region,
            authorizationToken: token
          }
        })
      )
    }
  }

  /**
   * Launch token refresh after ponyfill creation if subscription key and region are given
   */
  useEffect(() => {
    ponyfill &&
      ponyfillCredentials?.subscriptionKey &&
      ponyfillCredentials?.region &&
      refreshTokenInNineMinutes(
        ponyfillCredentials.region,
        ponyfillCredentials.subscriptionKey
      )
  }, [ponyfill])

  useEffect(() => {
    if (
      ponyfillCredentials?.authorizationToken &&
      ponyfillCredentials?.region
    ) {
      const data: PonyfillOptions = {
        credentials: ponyfillCredentials
      }
      setPonyfill(createSpeechSynthesisPonyfill(data))
    } else if (
      ponyfillCredentials?.subscriptionKey &&
      ponyfillCredentials?.region
    ) {
      fetchTokenAndCreatePonyfill(
        ponyfillCredentials.subscriptionKey,
        ponyfillCredentials.region
      )
    }
  }, [ponyfillCredentials])

  const onVoicesChanged = (): void => {
    const voices = ponyfill.speechSynthesis.getVoices()
    if (voices && Array.isArray(voices) && voices.length > 0) {
      setVoice(
        selectVoice(
          ponyfill.speechSynthesis.getVoices(),
          locale,
          customVoice,
          agentData
        )
      )
    }
  }

  /**
   * Check activity locale in case of voice change during the speech, to keep the language defined in activity
   * instead of switching languages between answers that are written in the same language
   * @param activityLocale : string
   * @returns SpeechSynthesisVoice | null
   */
  const getVoiceAfterCheckingActivityLocale = (
    activityLocale: string
  ): SpeechSynthesisVoice | null => {
    return activityLocale === locale
      ? voice
      : selectVoice(
          ponyfill.speechSynthesis.getVoices(),
          activityLocale,
          customVoice,
          agentData
        )
  }

  useEffect(() => {
    if (ponyfill) {
      const voices = ponyfill.speechSynthesis.getVoices()
      if (voices && Array.isArray(voices) && voices.length > 0) {
        setVoice(
          selectVoice(
            ponyfill.speechSynthesis.getVoices(),
            locale,
            customVoice,
            agentData
          )
        )
      } else {
        ponyfill.speechSynthesis.onvoiceschanged = onVoicesChanged
      }
    }
  }, [ponyfill, customVoice, agentData, locale])

  /**
   * On call (used with the 'cancelSpeech' event is fired) :
   *  - set speechCancelStore's cancel state to false
   */
  const cancelSpeech = (): void => {
    !isNewsView && setCancel(true)
  }

  const checkSayAsWordsAndCreateUtterance = (
    params: CreateUtteranceParams
  ): SpeechSynthesisUtterance => {
    let sayAsWords: Array<SayAsItem> = []
    if (speechSynthesisOptions?.sayAs) {
      speechSynthesisOptions?.sayAs?.common?.length &&
        (sayAsWords = [...speechSynthesisOptions.sayAs.common])
      speechSynthesisOptions?.sayAs?.localized &&
        speechSynthesisOptions.sayAs.localized[locale]?.length &&
        (sayAsWords = [
          ...sayAsWords,
          ...speechSynthesisOptions.sayAs.localized[locale]
        ])
    }

    return createUtterance({ ...params, sayAsWords })
  }

  const checkActivityAndCreateUtterance = (
    params: CreateUtteranceParams
  ): SpeechSynthesisUtterance => {
    if (
      params.activity.attachmentLayout &&
      params.activity.attachmentLayout.toLowerCase() === 'davilist'
    ) {
      const textToSpeak = params.activity.speak || params.activity.text
      if (textToSpeak) {
        if (textToSpeak === lastListTextPlayed) {
          return checkSayAsWordsAndCreateUtterance({ ...params, voice: null })
        } else {
          setLastListTextPlayed(textToSpeak)
        }
      }
    }

    return checkSayAsWordsAndCreateUtterance({ ...params })
  }

  /**
   * On component mount :
   *  - attach event listener to 'cancelSpeech' event
   * On component unmount :
   *  - reset the timer
   *  - detach the event listener
   */
  useEffect(() => {
    // Event called from the outside to cancel speech
    document.addEventListener('cancelSpeech', cancelSpeech)

    return (): void => {
      timerRef && clearTimeout(timerRef.current)
      refreshTimerRef.current && clearTimeout(refreshTimerRef.current)
      document.removeEventListener('cancelSpeech', cancelSpeech)
    }
  }, [])

  useEffect(() => {
    if (isNewsView) {
      if (currentNewsActivity && ponyfill) {
        const params: CreateUtteranceParams = {
          activity: currentNewsActivity,
          voice: getVoiceAfterCheckingActivityLocale(
            currentNewsActivity.locale || locale
          ),
          locale: currentNewsActivity.locale || locale
        }
        setUtterance(checkSayAsWordsAndCreateUtterance({ ...params }))
      } else {
        setUtterance(null)
      }
    }
  }, [currentNewsActivity, ponyfill])

  /**
   * On ViewContext's route state change :
   *  - when we toggle from a view to another, we reset the data related to speech
   */
  useEffect(() => {
    setSpeaking(false)
    setBoundaryData([])
    const ended = [...endedActivities]
    if (currentPlaying) {
      // Add played activity id to context's endedActivities
      ended.length > 10 && ended.splice(0, 1)
      currentPlaying.id && ended.push(currentPlaying.id)
      setEndedActivities(ended)
    }
    setCurrentPlaying(undefined)
    currentPlayingRef.current = null
    queuedActivitiesRef.current = []
    setCancel(false)
    setUtterance(null)
    setNextUtterance(null)

    !isRetorikNews && setIsNewsView(route === Routes.News)
  }, [route])

  /**
   * On RetorikContext's appAvailable state change :
   *  - appAvailable is set to true after the user interacted with the loader and every needed element is loaded
   *  - no utterance is created while appAvailable isn't true
   *  - if during the waiting time, some activities were queued, when appAvailable comes to true, let's begin creating the utterances and playing them
   */
  useEffect(() => {
    if (appAvailable && queuedActivitiesRef.current?.length) {
      const activity = queuedActivitiesRef.current[0]
      currentActivityRef.current = activity
      const params: CreateUtteranceParams = {
        activity: activity,
        voice: getVoiceAfterCheckingActivityLocale(activity.locale || locale),
        locale: activity.locale || locale
      }
      const queue = [...queuedActivitiesRef.current]
      queue.splice(0, 1)
      queuedActivitiesRef.current = queue
      setUtterance(checkActivityAndCreateUtterance(params))
    }
  }, [appAvailable])

  /**
   * On speechCancelStore's cancel state change :
   *  - if there is currently non utterance being played, reset cancel state to false
   *  - if an utterance is being played, set it to null to stop playing and prevent data in queue from being played
   *  - setting an utterance to null will trigger the handleUtteranceEnded method automatically
   */
  useEffect(() => {
    cancelRef.current = cancel
    if (cancel) {
      utterance ? setUtterance(null) : setCancel(false)
      setNextUtterance(null)
    }
  }, [cancel])

  /**
   * On lastBotActivity, ponyfill states change :
   *  - check if the ponyfill is created and the voices loaded
   *  - if the activity doesn't have a replyToId value, put it in the queue (used for reminder process)
   *  - if the app is not available yet, put the activity in the queue if it is not yet inside
   *  - NB: an activity can be received once from the directline, but can be found several times in the activities in the botframework
   *  this is because it is processed multiple times if there is something to speak (channelDate empty, then channelData with {speak: true})
   *  - if an utterance is being played, process the new one (do nothing / put it in the queue / stop the current one and play the new one)
   *  - if an utterance has to be created, create it and set the utterance state
   */
  useEffect(() => {
    if (ponyfill && voice && lastBotActivity?.id) {
      let createNewUtterance = false
      if (!lastBotActivity.replyToId) {
        if (isNewsView) {
          // Do nothing
        } else if (currentPlaying || queuedActivitiesRef.current?.length) {
          queuedActivitiesRef.current = [
            ...queuedActivitiesRef.current,
            lastBotActivity
          ]
        } else {
          if (
            endedActivities.length === 0 ||
            endedActivities[endedActivities.length - 1] !== lastBotActivity.id
          ) {
            createNewUtterance = true
          }
        }
      } else if (lastBotActivity.replyToId === streamingReplyToId) {
        if (lastBotActivity.value?.streamingId) {
          setCurrentPlaying(lastBotActivity)
          currentPlayingRef.current = lastBotActivity
        } else {
          queuedActivitiesRef.current = [
            ...queuedActivitiesRef.current,
            lastBotActivity
          ]
        }
      } else if (!appAvailable) {
        if (!currentReplyToId) {
          setCurrentReplyToId(lastBotActivity.replyToId)
          queuedActivitiesRef.current = [lastBotActivity]
        } else {
          if (currentReplyToId === lastBotActivity.replyToId) {
            let count = 0
            if (queuedActivitiesRef.current?.length) {
              queuedActivitiesRef.current.forEach(
                (activity: RetorikActivity) => {
                  if (activity.id === lastBotActivity.id) {
                    count++
                  }
                }
              )
            }

            if (count === 0) {
              queuedActivitiesRef.current = [
                ...queuedActivitiesRef.current,
                lastBotActivity
              ]
            }
          } else {
            setCurrentReplyToId(lastBotActivity.replyToId)
            queuedActivitiesRef.current = [lastBotActivity]
          }
        }
      }
      // If there is currently an utterance playing, verify if the replyToId is the same, if so queue the new utterance, else change the utterance with the new one
      else if (currentActivityRef?.current) {
        const returnCode = checkLastbotActivity(
          lastBotActivity,
          currentActivityRef.current,
          currentReplyToId,
          queuedActivitiesRef.current,
          endedActivities
        )

        const queue = [...queuedActivitiesRef.current]
        switch (returnCode) {
          case 0:
            break
          case 1:
            // Add the activity to the queue
            queue.push(lastBotActivity)
            queuedActivitiesRef.current = queue
            break
          case 2:
            // Stop current activity and play the new one
            queuedActivitiesRef.current = []
            createNewUtterance = true
            break
        }
      } else {
        createNewUtterance = !endedActivities.includes(lastBotActivity.id)
      }

      if (createNewUtterance) {
        currentActivityRef.current = lastBotActivity
        setCurrentReplyToId(
          lastBotActivity.replyToId || 'customReplyToIdIfNotPresent'
        )
        const params: CreateUtteranceParams = {
          activity: lastBotActivity,
          voice: getVoiceAfterCheckingActivityLocale(
            lastBotActivity.locale || locale
          ),
          locale: lastBotActivity.locale || locale
        }

        setUtterance(checkActivityAndCreateUtterance(params))
      }
    }
  }, [lastBotActivity, ponyfill, voice])

  useEffect(() => {
    if (streamingReplyToId && streamingQueue.length) {
      const tempActivity = streamingQueue[0]

      const params: CreateUtteranceParams = {
        activity: tempActivity,
        voice: getVoiceAfterCheckingActivityLocale(locale),
        locale: locale
      }

      removeFirstFromStreamingQueue()
      setUtterance(checkSayAsWordsAndCreateUtterance(params))
      setCurrentPlaying(undefined)
      currentPlayingRef.current = null
      currentActivityRef.current = null
      tempCurrentStreamingRef.current = tempActivity.text || null
    }
  }, [streamingReplyToId])

  /**
   * On utteranceEnded state change :
   *  - if the state is true, an utterance just ended, so the steps are :
   *  - call the handleEnded method
   *  - set utteranceEnded state to false to wait for another utterance end
   */
  useEffect(() => {
    if (utteranceEnded) {
      handleEnded()
      setUtteranceEnded(false)
    }
  }, [utteranceEnded])

  const dispatchSpeechEvent = (start: boolean): void => {
    const speechEvent = start
      ? new Event('retorikSpeakStart')
      : new Event('retorikSpeakStop')
    document.dispatchEvent(speechEvent)
  }

  /**
   * On call :
   *  - set speaking state to true
   *  - clear timerref timeout
   *  - set currentPlaying and currentReplyToId states after a small delay to prevent make sure that the display will happen after the speech has begun
   */
  const handleUtteranceStart = (): void => {
    setSpeaking(true)
    if (!isNewsView && currentActivityRef?.current) {
      setCurrentPlaying(currentActivityRef.current)
      currentPlayingRef.current = currentActivityRef.current
    }
    dispatchSpeechEvent(true)

    // Set next utterance to prepare synthesis data in order to improve fluidity
    if (streamingReplyToId && streamingQueue.length) {
      const tempActivity = streamingQueue[0]
      if (tempActivity.text) {
        const params: CreateUtteranceParams = {
          activity: tempActivity,
          voice: getVoiceAfterCheckingActivityLocale(locale),
          locale: locale
        }

        const tempUtterance = checkSayAsWordsAndCreateUtterance(params)
        tempUtterance.text !== nextUtterance?.text &&
          setNextUtterance(tempUtterance)
      }
    }

    setCurrentStreaming(tempCurrentStreamingRef?.current)
  }

  /**
   * On call :
   *  - set speaking state to false
   *  - set utteranceEnded state to true
   */
  const handleUtteranceEnd = (): void => {
    setSpeaking(false)
    isNewsView ? setRetorikNewsEnded(true) : setUtteranceEnded(true)
    dispatchSpeechEvent(false)
  }

  const checkDictateState = (): void => {
    !preventExpectedInputHint && mode === Mode.vocal && toggleMicrophone(true)
  }

  /**
   * On call :
   *  - update the states related to the ende activity
   *  - check if the end of the utterance was called by a cancel or not
   *  - if it wasn't a cancel, deal with the queue
   *  - if this was the last activity of the queue, check if the microphone has to be opened automatically (inputHint = expectingInput)
   */
  const handleEnded = (): void => {
    let futureUtterance: SpeechSynthesisUtterance | null = null
    const ended = [...endedActivities]
    if (currentPlayingRef?.current) {
      // Add played activity id to context's endedActivities
      ended.length > 10 && ended.splice(0, 1)
      currentPlayingRef.current.id && ended.push(currentPlayingRef.current.id)
    }
    setCurrentPlaying(undefined)
    setEndedActivities(ended)
    // Check if the audio ended because of a cancel call
    if (cancelRef.current) {
      queuedActivitiesRef.current = []
      setStreamingReplyToId(null)
      tempCurrentStreamingRef.current = null
      setStreamingQueue([])
      setCancel(false)
    } else {
      if (streamingQueue.length) {
        const tempActivity = streamingQueue[0]

        const params: CreateUtteranceParams = {
          activity: tempActivity,
          voice: getVoiceAfterCheckingActivityLocale(locale),
          locale: locale
        }

        removeFirstFromStreamingQueue()
        futureUtterance = checkSayAsWordsAndCreateUtterance(params)
        tempCurrentStreamingRef.current = tempActivity.text || null
      } else {
        setStreamingReplyToId(null)
        // Launch next activity in the queue and remove it from the queue
        if (queuedActivitiesRef.current?.length) {
          const activity = queuedActivitiesRef.current[0]
          currentActivityRef.current = activity
          const params: CreateUtteranceParams = {
            activity: activity,
            voice: getVoiceAfterCheckingActivityLocale(
              activity.locale || locale
            ),
            locale: activity.locale || locale
          }
          futureUtterance = checkActivityAndCreateUtterance(params)
          const queue = [...queuedActivitiesRef.current]
          queue.splice(0, 1)
          queuedActivitiesRef.current = queue
        } else {
          if (currentPlayingRef?.current?.inputHint === 'expectingInput') {
            checkDictateState()
          }
        }
      }
    }

    currentPlayingRef.current = null
    setUtterance(futureUtterance)
  }

  return (
    <RetorikSpeech
      ponyfill={ponyfill}
      appAvailable={appAvailable}
      utterance={utterance}
      nextUtterance={nextUtterance}
      onStart={handleUtteranceStart}
      onError={handleUtteranceEnd}
      onEnd={handleUtteranceEnd}
    />
  )
}

export default SynthesisManager
