import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const PrintIcon = ({ className }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24.578 24.578'
      className={className || 'rf-h-6'}
    >
      <g transform='translate(0.5 0.5)'>
        <path
          d='M9,11.252V3H23.147v8.252'
          transform='translate(-4.284 -3)'
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          d='M7.716,24.11H5.358A2.358,2.358,0,0,1,3,21.752V15.858A2.358,2.358,0,0,1,5.358,13.5H24.221a2.358,2.358,0,0,1,2.358,2.358v5.895a2.358,2.358,0,0,1-2.358,2.358H21.863'
          transform='translate(-3 -5.248)'
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          d='M9,21H23.147v9.431H9Z'
          transform='translate(-4.284 -6.853)'
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default PrintIcon
