{
  "1": "domača stran",
  "2": "info kanal v živo",
  "3": "vreme",
  "4": "številke za klic v sili",
  "1000": "zgodovina",
  "1001": "vadnica",

  "common": {
    "all": "vse",
    "close": "blizu",
    "back": "nazaj",
    "continue": "Nadaljuj",
    "download": "prenesi",
    "downloadsheet": "prenesite ta list",
    "downloadsheetmobile": "prenesite ta list na pametni telefon",
    "filter": "Filter",
    "history": "zgodovinski",
    "lang": "jezikov",
    "menu": "meni",
    "mode": "moda",
    "needhelp": "Rabim pomoč?",
    "parameters": "nastavitve",
    "print": "Tiskanje",
    "printsheet": "Natisnite ta list",
    "result": "rezultat",
    "results": "rezultate",
    "singleLang": "jezik",
    "sound": "njo",
    "submit": "Skicka in",
    "subtitle": "podnaslov",
    "tutorial": "vadnica",
    "validate": "potrditi"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "polis",
    "firefighter": "brandmän",
    "general": "allmän",
    "hospital": "Sjukhus",
    "homeless": "Hemlös",
    "poison": "Giftkontrollcenter",
    "emergency": "Akuttjänster"
  },

  "error": {
    "title": "Ett fel har uppstått",
    "button": "Starta om programmet"
  },

  "history": {
    "title": "zgodovino pogovorov"
  },

  "inputs": {
    "choose": "Välj ett svar"
  },

  "language": {
    "change": "spremenite jezik",
    "select": "izberite jezik po vaši izbiri"
  },

  "link": {
    "buttontext": "Oglejte si dokument",
    "goto": "Pojdi na naslov",
    "click": "Klikni tukaj",
    "scanQR": "s skeniranjem QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Za optimalno izkušnjo,",
      "classic": "Za bolj klasično izkušnjo,",
      "vocal": "Z agentom se pogovarjam iz oči v oči.",
      "text": "Pisno komuniciram z agentom."
    },
    "modes": {
      "vocal": "vokalni način",
      "text": "besedilni način"
    },
    "loaderValidation": "Začni pogovor",
    "locale": "preverjanje jezika",
    "supported": "preverjanje podprtih jezikov",
    "addressdata": "pridobivanje podatkov"
  },

  "menu": {
    "message": "Vnesite svoje sporočilo",
    "allowMicrophone": "Za nadaljevanje dovolite brskalniku uporabo mikrofona",
    "backconversation": "nazaj k pogovoru",
    "answer": " odgovarja na vaša vprašanja"
  },

  "mobileLandscapePreventer": {
    "firstline": "Za optimalno izkušnjo,",
    "secondline": "obrnite zaslon"
  },

  "news": {
    "warningtext": "Dostopili boste do kanala z novicami, zvok na vaši napravi bo aktiviran",
    "live": "v živo",
    "continuous": "neprekinjeno"
  },

  "poi": {
    "comment": "pregled strank",
    "route": "cesta",
    "nodata": "Nobena vsebina se ne ujema z vašim iskanjem",
    "openinghours": "Odpiralni čas",
    "prices": "Cene",
    "paymentmethods": "Načini plačila",
    "numberofplaces": "število mest",
    "openroutemobile": "odprite Google Maps route na pametnem telefonu",
    "FoodEstablishment": "Kje jesti",
    "LodgingBusiness": "Kje spati",
    "TouristDestination": "Kaj videti",
    "TouristAttraction": "Kaj storiti",
    "Product": "Lokalni izdelki",
    "Service": "Storitve",
    "Event": "Dogodki",
    "City": "Mesta",
    "Wine": "Vina"
  },

  "suggestion": {
    "consult": "Preveri",
    "thetutorial": "vadnica"
  },

  "template": {
    "reset": "ponastavi vse",
    "showresults": "Pokaži rezultate",
    "suggestiontitle": "Predlogi za iskanje",
    "reminder": "Ne pozabite, \n lahko odgovorim na vaša vprašanja."
  }
}
