{
  "_comment": "[CI-LOCKED] เนื้อหาของไฟล์นี้ถูกล็อก ยินดีรับการสนับสนุน แต่การตรวจสอบความถูกต้องของการเปลี่ยนแปลงอาจล่าช้า",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "บอท $1 กล่าวว่า:",
  "ACTIVITY_YOU_SAID_ALT": "คุณพูดว่า:",
  "ACTIVITY_BOT_ATTACHED_ALT": "บอทแนบ:",
  "ACTIVITY_ERROR_BOX_TITLE": "ข้อความแสดงข้อผิดพลาด",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "คลิกเพื่อโต้ตอบ",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "ข้อความเป็นแบบโต้ตอบ",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "มีลิงก์อย่างน้อยหนึ่งลิงก์ในข้อความ",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "การส่งไม่สำเร็จ",
  "ACTIVITY_YOU_ATTACHED_ALT": "คุณแนบ:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "สิ่งที่แนบ 1 รายการ",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "สิ่งที่แนบ $1 รายการ",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "สิ่งที่แนบ $1 รายการ",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "สิ่งที่แนบ $1 รายการ",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "สิ่งที่แนบ $1 รายการ",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "ส่งไม่สำเร็จ [ลองใหม่][RETRY]",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "กำลังส่ง",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "ส่งเมื่อ $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "ตอนนี้",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "ชั่วโมงที่แล้ว",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "นาทีที่แล้ว",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "วันนี้",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "เมื่อวานนี้",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "ข้อผิดพลาดในการแยกวิเคราะห์ Adaptive Card",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "ข้อผิดพลาดในการแสดงผล Adaptive Card",
  "ATTACHMENT_AUDIO": "คลิปเสียง",
  "ATTACHMENT_CARD": "การ์ด: $1 $2 $3",
  "ATTACHMENT_FILE": "ไฟล์: $1",
  "ATTACHMENT_IMAGE": "รูปภาพ",
  "ATTACHMENT_TEXT": "ข้อความ: $1",
  "ATTACHMENT_VIDEO": "คลิปวิดีโอ",
  "CAROUSEL_FLIPPER_NEXT_ALT": "ถัดไป",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "ก่อนหน้า",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "เชื่อมต่อแล้ว",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "กำลังเชื่อมต่อ...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "ไม่สามารถเชื่อมต่อได้",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "เครือข่ายขัดข้อง กำลังเชื่อมต่อใหม่...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "แสดงข้อผิดพลาด โปรดตรวจสอบคอนโซลหรือติดต่อนักพัฒนาบอท",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "ใช้เวลาเชื่อมต่อนานกว่าปกติ",
  "CONNECTIVITY_STATUS_ALT": "สถานะของการเชื่อมต่อ: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "ดาวน์โหลดไฟล์ '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "ดาวน์โหลดไฟล์ '$1' ขนาด $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' ขนาด $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "แป้น Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "เข้าถึงรายการในข้อความ",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "ประวัติการแชท",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "แป้น Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "ออกจากข้อความ",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "แป้น Tab\nแป้น Shift + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "เลื่อนไปมาระหว่างรายการในข้อความ",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "แป้นลูกศรขึ้น\nแป้นลูกศรลง",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "เลื่อนไปมาระหว่างข้อความ",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "แป้น Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "ทำการดำเนินการ",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "แป้น Tab\nแป้น Shift + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "เลื่อนไปมาระหว่างรายการในหน้าต่างแชท",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "หน้าต่างแชท",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "ปิด",
  "KEYBOARD_HELP_HEADER": "ตัวควบคุมบนแป้นพิมพ์",
  "MARKDOWN_EXTERNAL_LINK_ALT": "เปิดในหน้าต่างใหม่; ภายนอก",
  "RECEIPT_CARD_TAX": "ภาษี",
  "RECEIPT_CARD_TOTAL": "ยอดรวม",
  "RECEIPT_CARD_VAT": "ภาษีมูลค่าเพิ่ม",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "ไม่สามารถส่งข้อความที่ว่างเปล่า",
  "SPEECH_INPUT_LISTENING": "กำลังฟัง...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "ปิดไมโครโฟน",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "เปิดไมโครโฟน",
  "SPEECH_INPUT_STARTING": "กำลังเริ่ม...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "ถัดไป",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "ก่อนหน้า",
  "SUGGESTED_ACTIONS_LABEL_ALT": "การดำเนินการที่แนะนำ",
  "TEXT_INPUT_ALT": "กล่องป้อนข้อความ",
  "TEXT_INPUT_PLACEHOLDER": "พิมพ์ข้อความของคุณ",
  "TEXT_INPUT_SEND_BUTTON_ALT": "ส่ง",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "พูด",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "อัปโหลดไฟล์",
  "TOAST_ACCORDION_FEW": "$1 การแจ้งเตือน: คลิกที่นี่เพื่อดูรายละเอียด",
  "TOAST_ACCORDION_MANY": "$1 การแจ้งเตือน: คลิกที่นี่เพื่อดูรายละเอียด",
  "TOAST_ACCORDION_OTHER": "$1 การแจ้งเตือน: คลิกที่นี่เพื่อดูรายละเอียด",
  "TOAST_ACCORDION_TWO": "$1 การแจ้งเตือน: คลิกที่นี่เพื่อดูรายละเอียด",
  "TOAST_ALT_ERROR": "ผิดพลาด",
  "TOAST_ALT_INFO": "ข้อมูล",
  "TOAST_ALT_SUCCESS": "สำเร็จ",
  "TOAST_ALT_WARN": "คำเตือน",
  "TOAST_DISMISS_BUTTON": "ปิดการเตือน",
  "TOAST_TITLE_ALT": "การแจ้งเตือน",
  "TRANSCRIPT_ARIA_LABEL_ALT": "ประวัติการแชท กดแป้นลูกศรเพื่อนำทาง",
  "TRANSCRIPT_ARIA_ROLE_ALT": "ประวัติการแชท",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "ข้อความเป็นแบบโต้ตอบ กดแป้น Shift Tab 2 ถึง 3 ครั้งเพื่อสลับไปที่ประวัติการแชท จากนั้นคลิกที่ข้อความเพื่อโต้ตอบ",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "มีลิงก์อย่างน้อยหนึ่งลิงก์ในข้อความ กดแป้น Shift Tab 2 ถึง 3 ครั้งเพื่อสลับไปที่ประวัติการแชท จากนั้นคลิกที่ข้อความเพื่อโต้ตอบ",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "ข้อความมีการดำเนินการที่แนะนำ กด Shift Tab 2 ถึง 3 ครั้งเพื่อเลือก",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "ข้อความมีการดำเนินการที่แนะนำ กด $1 เพื่อเลือก",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "ไม่สามารถส่งข้อความได้",
  "TRANSCRIPT_MORE_MESSAGES": "ข้อความเพิ่มเติม",
  "TRANSCRIPT_NEW_MESSAGES": "ข้อความใหม่",
  "TRANSCRIPT_TERMINATOR_TEXT": "สิ้นสุดประวัติการแชท",
  "TYPING_INDICATOR_ALT": "แสดงตัวแสดงการพิมพ์",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 กำลังพิมพ์",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 และคนอื่นๆ กำลังพิมพ์"
}