import React, { useEffect, useRef } from 'react'
import { useView } from '../Contexts/ViewContext'
import {
  useSpeechStore,
  setMuted,
  setCancel,
  setActiveRecognitionState
} from '../Contexts/speechStore'
import { DeviceType, RecognitionState } from '../../models/enums'

const FocusManager = (): JSX.Element => {
  const { currentDeviceType } = useView()
  const muted = useSpeechStore((state) => state.muted)
  const activeRecognitionState = useSpeechStore(
    (state) => state.activeRecognitionState
  )
  const focusedRef = useRef<boolean>(true)
  const oldMutedRef = useRef<boolean>(muted)
  const recognitionStateRef = useRef<RecognitionState>(activeRecognitionState)

  useEffect(() => {
    focusedRef.current && (oldMutedRef.current = muted)
  }, [muted])

  useEffect(() => {
    recognitionStateRef.current = activeRecognitionState
  }, [activeRecognitionState])

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      focusedRef.current = true
      oldMutedRef.current === false && setMuted(false)
    } else {
      focusedRef.current = false
      setCancel(true)
      setMuted(true)
      recognitionStateRef.current === RecognitionState.Listening &&
        setActiveRecognitionState(RecognitionState.ForceClosing)
    }
  }

  useEffect(() => {
    currentDeviceType === DeviceType.mobile &&
      document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [currentDeviceType])

  return <React.Fragment />
}

export default FocusManager
