{
  "_comment": "[CI-LOCKED] इस फ़ाइल की सामग्री लॉक है. योगदानकर्ताओं का स्वागत है, लेकिन परिवर्तन सत्यापन में देरी हो सकती है.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "नियंत्रण विकल्प $1",
  "ACTIVITY_BOT_SAID_ALT": "बॉट $1 ने कहा:",
  "ACTIVITY_YOU_SAID_ALT": "आपने कहा:",
  "ACTIVITY_BOT_ATTACHED_ALT": "बॉट संलग्न किया गया:",
  "ACTIVITY_ERROR_BOX_TITLE": "त्रुटि संदेश",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "सहभागिता करने के लिए क्लिक करें.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "संदेश इंटरैक्टिव है.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "संदेश में एक या अधिक लिंक हैं.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "भेजना विफल रहा.",
  "ACTIVITY_YOU_ATTACHED_ALT": "आपने यह संलग्न किया है:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 अनुलग्नक.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 अनुलग्नक.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 अनुलग्नक.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 अनुलग्नक.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 अनुलग्नक.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "भेजना विफल. [पुन: प्रयास करें][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "भेजी जा रही है",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "$1 पर भेजी गई",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "अभी-अभी",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "एक घंटा पहले",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "1 मिनट पहले",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "आज",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "बीता हुआ कल",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "एडेप्टिव कार्ड पार्स त्रुटि",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "एडेप्टिव कार्ड रेंडर त्रुटि",
  "ATTACHMENT_AUDIO": "एक ऑडियो क्लिप.",
  "ATTACHMENT_CARD": "एक कार्ड: $1 $2 $3",
  "ATTACHMENT_FILE": "एक फ़ाइल: $1",
  "ATTACHMENT_IMAGE": "एक छवि",
  "ATTACHMENT_TEXT": "एक पाठ: $1",
  "ATTACHMENT_VIDEO": "एक वीडियो क्लिप",
  "CAROUSEL_FLIPPER_NEXT_ALT": "अगला",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "पिछला",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "कनेक्ट किया गया",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "कनेक्ट किया जा रहा है...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "कनेक्ट करने में असमर्थ.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "नेटवर्क व्यवधान उत्पन्न हुआ. पुन: कनेक्ट करें...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "रेंडर त्रुटि. कृपया कंसोल की जाँच करें या बॉट डेवलपर से संपर्क करें.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "कनेक्ट होने में सामान्य से अधिक समय ले रहा है.",
  "CONNECTIVITY_STATUS_ALT": "कनेक्टिविटी स्थिति: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "'$1' फ़ाइल डाउनलोड करें",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "$2 के आकार की '$1' फ़ाइल डाउनलोड करें",
  "FILE_CONTENT_WITH_SIZE_ALT": "$2 के आकार की '$1'",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter कुंजी",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "संदेश में आइटम एक्सेस करें",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "चैट इतिहास",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape कुंजी",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "संदेश बंद करें",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab कुंजी\nShift + Tab कुंजी",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "संदेश में आइटम के बीच जाएं",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "ऊपर तीर कुंजी\nनीचे तीर कुंजी",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "संदेशों के बीच जाएं",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter कुंजी",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "क्रिया करें",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab कुंजी\nShift + Tab कुंजी",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "चैट विंडो में आइटम के बीच जाएं",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "चैट विंडो",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "बंद करें",
  "KEYBOARD_HELP_HEADER": "कीबोर्ड नियंत्रण",
  "MARKDOWN_EXTERNAL_LINK_ALT": "एक नई विंडो में खोलता है; बाहरी.",
  "RECEIPT_CARD_TAX": "कर",
  "RECEIPT_CARD_TOTAL": "योग",
  "RECEIPT_CARD_VAT": "VAT",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "रिक्त संदेश भेज नहीं सकते.",
  "SPEECH_INPUT_LISTENING": "सुन रहा है...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "माइक्रोफ़ोन बंद करें",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "माइक्रोफ़ोन चालू करें",
  "SPEECH_INPUT_STARTING": "आरंभ हो रहा है…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "अगला",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "पिछला",
  "SUGGESTED_ACTIONS_LABEL_ALT": "सुझाई गई क्रियाएँ",
  "TEXT_INPUT_ALT": "संदेश इनपुट बॉक्स",
  "TEXT_INPUT_PLACEHOLDER": "अपना संदेश लिखें",
  "TEXT_INPUT_SEND_BUTTON_ALT": "भेजें",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "बोलें",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "फ़ाइल अपलोड करें",
  "TOAST_ACCORDION_FEW": "$1 सूचनाएँ: विवरण देखने के लिए यहाँ क्लिक करें",
  "TOAST_ACCORDION_MANY": "$1 सूचनाएँ: विवरण देखने के लिए यहाँ क्लिक करें",
  "TOAST_ACCORDION_OTHER": "$1 सूचनाएँ: विवरण देखने के लिए यहाँ क्लिक करें",
  "TOAST_ACCORDION_TWO": "$1 सूचनाएँ: विवरण देखने के लिए यहाँ क्लिक करें",
  "TOAST_ALT_ERROR": "त्रुटि",
  "TOAST_ALT_INFO": "जानकारी",
  "TOAST_ALT_SUCCESS": "सफल",
  "TOAST_ALT_WARN": "चेतावनी",
  "TOAST_DISMISS_BUTTON": "खारिज करें",
  "TOAST_TITLE_ALT": "सूचना",
  "TRANSCRIPT_ARIA_LABEL_ALT": "चैट इतिहास, नेविगेट करने के लिए एरो कुंजियां दबाएं.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "चैट इतिहास",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "संदेश इंटरैक्टिव है. चैट इतिहास में जाने के लिए Shift Tab कुंजी को 2 से 3 बार दबाएं. फिर सहभागिता करने के लिए संदेश पर क्लिक करें.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "संदेश में एक या अधिक लिंक हैं. चैट इतिहास में जाने के लिए Shift Tab कुंजी को 2 से 3 बार दबाएं. फिर संदेश पर क्लिक करके सहभागिता करें.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "संदेश में सुझाई गई क्रियाएँ हैं. उन्हें चुनने के लिए shift टैब को 2 से 3 बार दबाएँ.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "संदेश में सुझाई गई क्रियाएँ हैं. उन्हें चुनने के लिए $1 दबाएँ.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "संदेश भेजा नहीं जा सका.",
  "TRANSCRIPT_MORE_MESSAGES": "अधिक संदेश",
  "TRANSCRIPT_NEW_MESSAGES": "नए संदेश",
  "TRANSCRIPT_TERMINATOR_TEXT": "चैट इतिहास का अंत",
  "TYPING_INDICATOR_ALT": "टाइप करने वाला संकेतक दिखा रहा है",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 टाइप कर रहा है.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 और अन्य लिख रहे हैं."
}