{
  "_comment": "[CI-LOCKED] De inhoud van dit bestand is vergrendeld. Bijdragen zijn welkom, maar wijzigingen in de validatie zijn mogelijk vertraagd.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 zei:",
  "ACTIVITY_YOU_SAID_ALT": "U zei:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot heeft bijgevoegd:",
  "ACTIVITY_ERROR_BOX_TITLE": "Foutbericht",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Klik om te communiceren.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Bericht is interactief.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Een of meer koppelingen in het bericht.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Verzenden mislukt.",
  "ACTIVITY_YOU_ATTACHED_ALT": "U hebt bijgevoegd:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 bijlage.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 bijlagen.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 bijlagen.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 bijlagen.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 bijlagen.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Verzenden mislukt. [Opnieuw][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Wordt verzonden",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Verzonden om $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Zojuist",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Een uur geleden",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Een minuut geleden",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Vandaag",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Gisteren",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Parseerfout adaptieve kaart",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Weergavefout adaptieve kaart",
  "ATTACHMENT_AUDIO": "Een audioclip.",
  "ATTACHMENT_CARD": "Een kaart: $1 $2 $3",
  "ATTACHMENT_FILE": "Een bestand: $1",
  "ATTACHMENT_IMAGE": "Een afbeelding",
  "ATTACHMENT_TEXT": "Een tekst: $1",
  "ATTACHMENT_VIDEO": "Een videoclip",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Volgende",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Vorige",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Verbonden",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Verbinding maken...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Kan geen verbinding maken.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Er is een netwerkonderbreking opgetreden. Verbinding wordt hersteld...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Fout bij genereren. Controleer de console of neem contact op met de bot-ontwikkelaar.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Het duurt langer dan gewoonlijk om verbinding te maken.",
  "CONNECTIVITY_STATUS_ALT": "Verbindingsstatus: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Bestand '$1' downloaden",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Downloadbestand '$1' van grootte $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' van grootte $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter-toets",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Toegang tot items in bericht",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Chatgeschiedenis",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape-toets",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Afsluitbericht",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabtoets\nShift + tabtoets",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Heen en weer gaan tussen items in een bericht",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Pijl-omhoog\nPijl-omlaag",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Heen en weer gaan tussen berichten",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter-toets",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Actie uitvoeren",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabtoets\nShift + tabtoets",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Heen en weer gaan tussen items in het chatvenster",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Chatvenster",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Sluiten",
  "KEYBOARD_HELP_HEADER": "Toetsenbordbesturingselementen",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Wordt geopend in een nieuw venster; extern.",
  "RECEIPT_CARD_TAX": "Belastingen",
  "RECEIPT_CARD_TOTAL": "Totaal",
  "RECEIPT_CARD_VAT": "Btw",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Kan geen leeg bericht verzenden.",
  "SPEECH_INPUT_LISTENING": "Luisteren...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Microfoon uit",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Microfoon aan",
  "SPEECH_INPUT_STARTING": "Starten...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Volgende",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Vorige",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Voorgestelde acties",
  "TEXT_INPUT_ALT": "Vak voor berichtinvoer",
  "TEXT_INPUT_PLACEHOLDER": "Typ uw bericht",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Verzenden",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Spreken",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Bestand uploaden",
  "TOAST_ACCORDION_FEW": "$1 meldingen: klik hier voor details",
  "TOAST_ACCORDION_MANY": "$1 meldingen: klik hier voor details",
  "TOAST_ACCORDION_OTHER": "$1 meldingen: klik hier voor details",
  "TOAST_ACCORDION_TWO": "$1 meldingen: klik hier voor details",
  "TOAST_ALT_ERROR": "Fout",
  "TOAST_ALT_INFO": "Informatie",
  "TOAST_ALT_SUCCESS": "Gelukt",
  "TOAST_ALT_WARN": "Waarschuwing",
  "TOAST_DISMISS_BUTTON": "Negeren",
  "TOAST_TITLE_ALT": "Melding",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Chatgeschiedenis, druk op de pijltoetsen om te navigeren.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "chatgeschiedenis",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Bericht is interactief. Druk 2 tot 3 keer op Shift en tabtoets om over te schakelen naar de chatgeschiedenis. Klik vervolgens op het bericht voor interactie.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Een of meer koppelingen in het bericht. Druk 2 tot 3 keer op Shift en tabtoets om over te schakelen naar de chatgeschiedenis. Klik vervolgens op het bericht voor interactie.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Bericht heeft voorgestelde acties. Druk 2 tot 3 keer op Shift en tabtoets om deze te selecteren.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Bericht heeft voorgestelde acties. Druk op $1 om deze te selecteren.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Het bericht is niet verzonden.",
  "TRANSCRIPT_MORE_MESSAGES": "Meer berichten",
  "TRANSCRIPT_NEW_MESSAGES": "Nieuwe berichten",
  "TRANSCRIPT_TERMINATOR_TEXT": "Einde chatgeschiedenis",
  "TYPING_INDICATOR_ALT": "Indicator voor typen weergeven",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 is bezig met typen.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 en anderen zijn bezig met typen."
}