import React from 'react'
import { AvailableViews } from '../../../models/enums'
import { useLocaleStore } from '../../Contexts/localeStore'
import * as Icons from '../../Icons/ViewIcons'

interface LargeIconAndTitleProps {
  subView: number
  className?: string
}

const iconClassname =
  'rf-h-16 rf-w-16 large-vertical:rf-h-24 large-vertical:rf-w-24'

const LargeIconAndTitle = ({
  subView,
  className
}: LargeIconAndTitleProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  return (
    <div
      className={`rf-hidden large:rf-flex rf-w-full rf-pt-4 large-vertical:rf-pt-8 rf-pl-4 large-vertical:rf-pl-0 rf-flex-row rf-justify-start large-vertical:rf-justify-center rf-items-center ${className}`}
    >
      {/* Icon */}
      {subView === AvailableViews.weather ? (
        <Icons.WeatherIcon className={iconClassname} />
      ) : subView === AvailableViews.emergency ? (
        <Icons.EmergencyIcon className={iconClassname} />
      ) : (
        <React.Fragment />
      )}
      {/* Title */}
      <div className='rf-ml-2 rf-uppercase rf-text-[#575F6B] rf-subtitle-size-auto'>
        {translation[subView]}
      </div>
    </div>
  )
}

export default LargeIconAndTitle
