import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { useView } from '../Contexts/ViewContext'
import { rotatingPhoneFromVerticalToHorizontalPosition } from '../../models/urlEncodedImages'

const MobileLandscapePreventer = (): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const { isMobileLandscape } = useView()

  return isMobileLandscape ? (
    <div className='rf-absolute rf-w-full rf-h-full rf-z-exitwidget rf-flex rf-flex-col rf-items-center rf-bg-truewhite'>
      {/* Image */}
      <div className='rf-h-1/2 rf-flex rf-items-end rf-pb-4'>
        <img
          className='rf-h-2/3'
          src={rotatingPhoneFromVerticalToHorizontalPosition}
        />
      </div>

      {/* Texts */}
      <div className='rf-h-1/2 rf-pt-4 rf-flex rf-flex-col rf-items-center'>
        <div className='rf-font-semibold rf-text-xl'>
          {translation.mobileLandscapePreventer.firstline}
        </div>
        <div className='rf-font-bold rf-text-3xl'>
          {translation.mobileLandscapePreventer.secondline}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default MobileLandscapePreventer
