import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const DrivingCarIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 34.168 29.135'
      className={className || 'rf-w-8 rf-h-8'}
    >
      <path
        id='car'
        d='M32.385,71.254H28.507L27.43,68.559A7.217,7.217,0,0,0,20.7,64H12.471a7.219,7.219,0,0,0-6.735,4.559L4.658,71.254H.781a.777.777,0,0,0-.754.966l.389,1.554a.777.777,0,0,0,.754.589h1.3a4.116,4.116,0,0,0-1.43,3.109V80.58A4.111,4.111,0,0,0,2.076,83.3v3.5a2.073,2.073,0,0,0,2.072,2.072H6.221A2.073,2.073,0,0,0,8.293,86.8V84.725h16.58V86.8a2.073,2.073,0,0,0,2.072,2.072h2.072A2.073,2.073,0,0,0,31.09,86.8V83.3a4.109,4.109,0,0,0,1.036-2.716V77.471A4.118,4.118,0,0,0,30.7,74.362H32a.777.777,0,0,0,.754-.589l.389-1.554a.778.778,0,0,0-.754-.966ZM9.584,70.1a3.109,3.109,0,0,1,2.887-1.954H20.7A3.109,3.109,0,0,1,23.582,70.1l1.291,3.227H8.293ZM6.221,80.567a2.066,2.066,0,1,1,0-4.132,3.987,3.987,0,0,1,3.109,3.1c0,1.24-1.865,1.033-3.109,1.033Zm20.725,0c-1.243,0-3.109.207-3.109-1.033a3.987,3.987,0,0,1,3.109-3.1,2.066,2.066,0,1,1,0,4.132Z'
        transform='translate(0.501 -63.5)'
        fill={color || '#fff'}
      />
    </svg>
  )
}

export default DrivingCarIcon
