import React from 'react'
import { useUtilsStore } from '../Contexts/utilsStore'

const ModalFullscreen = (): JSX.Element => {
  const content = useUtilsStore((state) => state.modalFullscreenContent)

  const handleClick = (event): void => {
    event?.stopPropagation()
  }

  return content ? (
    <div
      className='rf-absolute rf-z-modal rf-h-full rf-w-full rf-flex rf-flex-col rf-justify-center rf-items-center'
      style={{
        background: 'rgba(18,18,18,0.7)'
      }}
      onClick={(event): void => handleClick(event)}
    >
      {content}
    </div>
  ) : (
    <React.Fragment />
  )
}

export default ModalFullscreen
