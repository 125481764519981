// Check if we should let the event propagate. For example leaflet events should pass to allow map panning
const checkShouldPropagate = (e: WheelEvent | TouchEvent): boolean => {
  if (e.target) {
    try {
      const className = (e.target as HTMLElement).className
      return className && typeof className === 'string'
        ? className.includes('leaflet')
        : false
    } catch {
      return false
    }
  } else {
    return false
  }
}

const handleStop = (e: WheelEvent | TouchEvent): void => {
  !checkShouldPropagate(e) && e.cancelable && e.preventDefault()
}

const handleRefStop = (
  e: WheelEvent | TouchEvent,
  ref: React.RefObject<HTMLDivElement>
): void => {
  if (!checkShouldPropagate(e) && e.cancelable && ref.current) {
    const scrollHeight = ref.current.scrollHeight
    const scrollTop = ref.current.scrollTop
    const clientHeight = ref.current.clientHeight

    // If no overflow, prevent event default
    if (scrollHeight === clientHeight) {
      e.preventDefault()
    }
    // Wheel event, deltaY gives the vertical direction
    else if (e.type === 'wheel') {
      scrollTop === 0
        ? (e as WheelEvent).deltaY < 0 && e.preventDefault()
        : scrollTop + clientHeight >= scrollHeight - 5 &&
          (e as WheelEvent).deltaY > 0 &&
          e.preventDefault()
    }
    // Touch event, we need an origin to calculate the direction of the movement
    else if (e.type === 'touchmove') {
      if ((e as TouchEvent).touches.length > 1) {
        e.preventDefault()
      } else {
        const positionInLocalStorage = localStorage.getItem(
          'Retorik.Framework.TouchY'
        )
        const origin = positionInLocalStorage
          ? parseInt(positionInLocalStorage)
          : null

        if (origin && !isNaN(origin)) {
          const currentY =
            (e as TouchEvent).touches.length > 0
              ? (e as TouchEvent).touches[0].screenY
              : null

          if (currentY || currentY === 0) {
            const deltaY = origin - currentY
            scrollTop === 0
              ? deltaY < 0 && e.preventDefault()
              : scrollTop + clientHeight >= scrollHeight - 5 &&
                deltaY > 0 &&
                e.preventDefault()
          } else {
            e.preventDefault()
          }
        } else {
          e.preventDefault()
        }
      }
    }

    e.stopImmediatePropagation()
  }
}

const handleTouch = (e: TouchEvent): void => {
  localStorage.setItem('Retorik.Framework.TouchY', `${e.touches[0].screenY}`)
  e.touches.length > 1 &&
    !checkShouldPropagate(e) &&
    e.cancelable &&
    e.preventDefault()
}

const preventEvents = (
  element: HTMLElement | null,
  ref?: React.RefObject<HTMLDivElement>
): void => {
  if (ref && ref?.current) {
    try {
      ref.current.removeEventListener('wheel', (e) => handleRefStop(e, ref))
      ref.current.removeEventListener('touchstart', (e) => handleTouch(e))
      ref.current.removeEventListener('touchmove', (e) => handleRefStop(e, ref))
    } catch (err) {
      console.warn(err)
    }

    ref.current.addEventListener('wheel', (e) => handleRefStop(e, ref), {
      passive: false
    })
    ref.current.addEventListener('touchstart', (e) => handleTouch(e), {
      passive: false
    })
    ref.current.addEventListener('touchmove', (e) => handleRefStop(e, ref), {
      passive: false
    })
  } else if (element) {
    element.addEventListener('wheel', (e) => handleStop(e), {
      passive: false
    })
    element.addEventListener('touchstart', (e) => handleTouch(e), {
      passive: false
    })
    element.addEventListener('touchmove', (e) => handleStop(e), {
      passive: false
    })
  }
}

export default preventEvents
