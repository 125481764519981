{
  "_comment": "[CI-LOCKED] Šī faila saturs ir bloķēts. Ieguldījumi tiek gaidīti, taču izmaiņu validācija var tikt aizkavēta.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Robots $1 teica:",
  "ACTIVITY_YOU_SAID_ALT": "Jūs teicāt:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robots pievienots:",
  "ACTIVITY_ERROR_BOX_TITLE": "Kļūdas ziņojums",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Noklikšķiniet, lai mijiedarbotos.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Ziņojums ir interaktīvs.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Ziņojumā ir viena vai vairākas saites.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Nosūtīšana neizdevās.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Jūs pievienojāt:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 pielikums.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 pielikumi.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 pielikumi.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 pielikumi.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 pielikumi.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Neizdevās nosūtīt. [Mēģināt vēlreiz][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Notiek sūtīšana",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Nosūtīts plkst. $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Tikko",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Pirms stundas",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Pirms minūtes",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Šodien",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Vakardien",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Adaptīvās kartītes parsēšanas kļūda",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Adaptīvās kartītes atveides kļūda",
  "ATTACHMENT_AUDIO": "Audioklips.",
  "ATTACHMENT_CARD": "Kartīte: $1 $2 $3",
  "ATTACHMENT_FILE": "Fails: $1",
  "ATTACHMENT_IMAGE": "Attēls",
  "ATTACHMENT_TEXT": "Teksts: $1",
  "ATTACHMENT_VIDEO": "Videoklips",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Tālāk",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Atpakaļ",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Izveidots savienojums",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Notiek savienojuma izveide…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Nevar izveidot savienojumu.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Radās tīkla darbības pārtraukums. Notiek atkārtota savienojuma izveide…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Atveides kļūda. Lūdzu, pārbaudiet konsoli vai sazinieties ar robota izstrādātāju.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Savienojuma izveide notiek ilgāk nekā parasti.",
  "CONNECTIVITY_STATUS_ALT": "Savienojuma statuss. $1",
  "FILE_CONTENT_ALT": "“$1”",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Lejupielādēt failu “$1”",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Lejupielādēt failu “$1”, kura lielums ir $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "“$1”, kura lielums ir $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Taustiņš Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Piekļūstiet ziņojuma elementiem",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Tērzēšanas vēsture",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Taustiņš Esc",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Iziet no ziņojuma",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabulēšanas taustiņš\nShift + tabulēšanas taustiņš",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Pārvietojieties starp ziņojuma elementiem",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Augšupvērstā bultiņa\nLejupvērstā bultiņa",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Pārvietojieties starp ziņojumiem",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Taustiņš Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Veiciet darbību",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabulēšanas taustiņš\nShift + tabulēšanas taustiņš",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Pārvietojieties starp elementiem tērzēšanas logā",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Tērzēšanas logs",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Aizvērt",
  "KEYBOARD_HELP_HEADER": "Tastatūras vadīklas",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Tiek atvērts jaunā logā (ārējā).",
  "RECEIPT_CARD_TAX": "Nodoklis",
  "RECEIPT_CARD_TOTAL": "Kopā",
  "RECEIPT_CARD_VAT": "PVN",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Nevar nosūtīt tukšu ziņojumu.",
  "SPEECH_INPUT_LISTENING": "Notiek klausīšanās…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Izslēgt mikrofonu",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Ieslēgt mikrofonu",
  "SPEECH_INPUT_STARTING": "Sākas ievade…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Tālāk",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Atpakaļ",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Ieteicamās darbības",
  "TEXT_INPUT_ALT": "Ziņojuma ievades lodziņš",
  "TEXT_INPUT_PLACEHOLDER": "Ierakstiet ziņojumu",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Nosūtīt",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Runāt",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Augšupielādēt failu",
  "TOAST_ACCORDION_FEW": "$1 paziņojumi. Noklikšķiniet šeit, lai skatītu detalizētu informāciju",
  "TOAST_ACCORDION_MANY": "$1 paziņojumi. Noklikšķiniet šeit, lai skatītu detalizētu informāciju",
  "TOAST_ACCORDION_OTHER": "$1 paziņojumi. Noklikšķiniet šeit, lai skatītu detalizētu informāciju",
  "TOAST_ACCORDION_TWO": "$1 paziņojumi. Noklikšķiniet šeit, lai skatītu detalizētu informāciju",
  "TOAST_ALT_ERROR": "Kļūda",
  "TOAST_ALT_INFO": "Informācija",
  "TOAST_ALT_SUCCESS": "Veiksmīgi",
  "TOAST_ALT_WARN": "Brīdinājums",
  "TOAST_DISMISS_BUTTON": "Nerādīt",
  "TOAST_TITLE_ALT": "Paziņojums",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Tērzēšanas vēsture, izmantojiet bulttaustiņus, lai naviģētu.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "tērzēšanas vēsture",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Ziņojums ir interaktīvs. Nospiediet taustiņu Shift un tabulēšanas taustiņu 2–3 reizes, lai pārslēgtu uz tērzēšanas vēsturi. Pēc tam noklikšķiniet uz ziņojuma, lai veiktu mijiedarbību.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Ziņojumā ir viena vai vairākas saites. Nospiediet taustiņu Shift un tabulēšanas taustiņu 2–3 reizes, lai pārslēgtu uz tērzēšanas vēsturi. Pēc tam noklikšķiniet uz ziņojuma, lai veiktu mijiedarbību.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Ziņojumam ir ieteicamās darbības. Lai tās atlasītu, 2–3 reizes nospiediet taustiņu kombināciju Shift Tab.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Ziņojumam ir ieteicamās darbības. Lai tās atlasītu, nospiediet $1.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Neizdevās nosūtīt ziņojumu.",
  "TRANSCRIPT_MORE_MESSAGES": "Vairāk ziņojumu",
  "TRANSCRIPT_NEW_MESSAGES": "Jauni ziņojumi",
  "TRANSCRIPT_TERMINATOR_TEXT": "Tērzēšanas vēstures beigas",
  "TYPING_INDICATOR_ALT": "Rāda rakstīšanas indikatoru",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 raksta.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 un citi raksta."
}