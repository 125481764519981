{
  "_comment": "[CI-LOCKED] 此檔案的內容已鎖定。歡迎提供意見，但變更驗證可能會有所延遲。",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "機器人 $1 說:",
  "ACTIVITY_YOU_SAID_ALT": "您說:",
  "ACTIVITY_BOT_ATTACHED_ALT": "機器人附加了:",
  "ACTIVITY_ERROR_BOX_TITLE": "錯誤訊息",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "按一下以進行互動。",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "訊息為互動式訊息。",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "訊息中有一或多個連結。",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "傳送失敗。",
  "ACTIVITY_YOU_ATTACHED_ALT": "您附加了:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 個附件。",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 個附件。",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 個附件。",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 個附件。",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 個附件。",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "傳送失敗。[重試][RETRY]。",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "正在傳送",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "於 $1 傳送",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "剛剛",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "1 小時前",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "1 分鐘前",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "今天",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "昨天",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "調適型卡片剖析錯誤",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "調適型卡片轉譯錯誤",
  "ATTACHMENT_AUDIO": "音訊剪輯。",
  "ATTACHMENT_CARD": "卡片: $1 $2 $3",
  "ATTACHMENT_FILE": "檔案: $1",
  "ATTACHMENT_IMAGE": "影像",
  "ATTACHMENT_TEXT": "文字: $1",
  "ATTACHMENT_VIDEO": "視訊剪輯",
  "CAROUSEL_FLIPPER_NEXT_ALT": "下一個",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "上一個",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "已連線",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "正在連線…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "無法連線。",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "發生網路中斷。正在重新連線…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "轉譯錯誤。請檢查主控台或連絡機器人開發人員。",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "連線所需的時間比平時更長。",
  "CONNECTIVITY_STATUS_ALT": "連線狀態: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "下載 '$1' 檔案",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "下載 '$1' 檔案，大小為 $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1'，大小為 $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "ENTER 鍵",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "存取訊息中的項目",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "聊天記錄",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "ESC 鍵",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "結束訊息",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab 鍵\nShift + Tab 鍵",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "在訊息中的項目之間移動",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "向上鍵\n向下鍵",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "在訊息之間移動",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "ENTER 鍵",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "執行動作",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab 鍵\nShift + Tab 鍵",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "在聊天視窗中的項目之間移動",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "聊天視窗",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "關閉",
  "KEYBOARD_HELP_HEADER": "鍵盤控制項",
  "MARKDOWN_EXTERNAL_LINK_ALT": "在新視窗中開啟; 外部。",
  "RECEIPT_CARD_TAX": "稅金",
  "RECEIPT_CARD_TOTAL": "總計",
  "RECEIPT_CARD_VAT": "加值稅",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "無法傳送空白訊息。",
  "SPEECH_INPUT_LISTENING": "正在聆聽…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "關閉麥克風",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "開啟麥克風",
  "SPEECH_INPUT_STARTING": "正在啟動…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "下一個",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "上一個",
  "SUGGESTED_ACTIONS_LABEL_ALT": "建議的動作",
  "TEXT_INPUT_ALT": "訊息輸入方塊",
  "TEXT_INPUT_PLACEHOLDER": "輸入您的訊息",
  "TEXT_INPUT_SEND_BUTTON_ALT": "傳送",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "說話",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "上傳檔案",
  "TOAST_ACCORDION_FEW": "$1 則通知: 按一下這裡來查看詳細資料",
  "TOAST_ACCORDION_MANY": "$1 則通知: 按一下這裡來查看詳細資料",
  "TOAST_ACCORDION_OTHER": "$1 則通知: 按一下這裡來查看詳細資料",
  "TOAST_ACCORDION_TWO": "$1 則通知: 按一下這裡來查看詳細資料",
  "TOAST_ALT_ERROR": "錯誤",
  "TOAST_ALT_INFO": "資訊",
  "TOAST_ALT_SUCCESS": "成功",
  "TOAST_ALT_WARN": "警告",
  "TOAST_DISMISS_BUTTON": "關閉",
  "TOAST_TITLE_ALT": "通知",
  "TRANSCRIPT_ARIA_LABEL_ALT": "聊天記錄，按方向鍵進行瀏覽。",
  "TRANSCRIPT_ARIA_ROLE_ALT": "聊天記錄",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "訊息為互動式。請按 Shift Tab 鍵 2 到 3 次切換至聊天記錄，然後按一下訊息進行互動。",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "訊息中的一或多個連結。請按 Shift Tab 鍵 2 到 3 次切換至聊天記錄，然後按一下訊息進行互動。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "訊息有建議的動作。請按下 Shift Tab 2 至 3 次選取它們。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "訊息有建議的動作。請按下 $1 選取它們。",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "無法傳送訊息。",
  "TRANSCRIPT_MORE_MESSAGES": "更多訊息",
  "TRANSCRIPT_NEW_MESSAGES": "新訊息",
  "TRANSCRIPT_TERMINATOR_TEXT": "聊天記錄結尾",
  "TYPING_INDICATOR_ALT": "顯示輸入指示器",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 正在鍵入。",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 和其他人正在鍵入。"
}