import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { DownloadIcon } from '../../Icons/Miscellaneous'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'

interface DownloadSheetProps {
  className?: string
  url: string
}

const DownloadSheet = ({ className, url }: DownloadSheetProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)

  return (
    <div className={`${className} rf-w-full rf-flex`}>
      <a
        href={url}
        className='rf-px-5 rf-py-3 rf-h-fit rf-flex rf-flex-row rf-items-center rf-mt-8 rf-mb-16 large:rf-my-0 rf-border-2 rf-border-black rf-rounded-lg hover:rf-bg-truewhite rf-cursor-pointer rf-font-bold'
      >
        <DownloadIcon className='rf-h-6 rf-w-6 rf-mr-1' />
        {capitalizeFirstLetter(translation.common.downloadsheet, locale)}
      </a>
    </div>
  )
}

export default DownloadSheet
