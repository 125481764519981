import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'

const ErrorFallback = ({ resetErrorBoundary }): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  const handleReset = () => {
    resetErrorBoundary()
    window.location.reload()
  }

  return (
    <div className='rf-h-full rf-w-full rf-flex rf-flex-col rf-justify-center rf-items-center rf-gap-2'>
      <p className='rf-uppercase'>{translation.error.title}</p>
      <button onClick={handleReset} className='rf-border rf-px-4'>
        {translation.error.button}
      </button>
    </div>
  )
}

export default ErrorFallback
