{
  "1": "kezdőlap",
  "2": "élő hírcsatorna",
  "3": "időjárás jelentés",
  "4": "Segélyhívó számok",
  "1000": "történelmi",
  "1001": "oktatóanyag",

  "common": {
    "all": "minden",
    "continue": "Folytatni",
    "download": "Letöltés",
    "downloadsheet": "Töltse le ezt a lapot",
    "downloadsheetmobile": "töltse le ezt a lapot okostelefonra",
    "filter": "Szűrő",
    "history": "történelmi",
    "lang": "NYELVEK",
    "menu": "menü",
    "mode": "divat",
    "needhelp": "Kell segítség?",
    "parameters": "beállítások",
    "print": "Nyomtatás",
    "printsheet": "Nyomtassa ki ezt a lapot",
    "result": "eredmény",
    "results": "eredmények",
    "singleLang": "nyelv",
    "sound": "neki",
    "submit": "Beküldés",
    "subtitle": "felirat",
    "tutorial": "oktatóanyag",
    "validate": "érvényesít"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "rendőrség",
    "firefighter": "tűzoltók",
    "general": "tábornoki",
    "citypolice": "Helyi rendőrség",
    "hospital": "Kórház",
    "homeless": "Hajléktalanok (ingyenes hívás)",
    "poison": "Méregellenőrző Központ",
    "emergency": "európai vészhelyzetek"
  },

  "error": {
    "title": "Hiba történt",
    "button": "Indítsa újra az alkalmazást"
  },

  "history": {
    "title": "beszélgetés előzményei"
  },

  "inputs": {
    "choose": "Válasszon egy választ"
  },

  "language": {
    "change": "változtassa meg a nyelvet",
    "select": "válassza ki a használni kívánt nyelvet"
  },

  "link": {
    "buttontext": "Tekintse meg a dokumentumot",
    "goto": "Menjen a címre",
    "click": "Kattints ide",
    "scanQR": "a QR-kód beolvasásával"
  },

  "loader": {
    "loader": {
      "optimal": "Az optimális élmény érdekében",
      "classic": "A klasszikusabb élmény érdekében",
      "vocal": "Négyszemközt beszélek az ügynökkel.",
      "text": "Írásban kommunikálok az ügynökkel."
    },
    "modes": {
      "vocal": "vokális mód",
      "text": "szöveges mód"
    },
    "loaderValidation": "Indítsa el a beszélgetést",
    "locale": "nyelv ellenőrzése",
    "supported": "támogatott nyelvek ellenőrzése",
    "addressdata": "adatok lekérése"
  },

  "menu": {
    "message": "Írja be üzenetét",
    "allowMicrophone": "A folytatáshoz engedélyezze böngészőjének a mikrofon használatát",
    "back": "vissza",
    "backconversation": "vissza a beszélgetéshez",
    "close": "Bezárás",
    "answer": " válaszol a kérdéseidre"
  },

  "mobileLandscapePreventer": {
    "firstline": "सर्वोत्तम अनुभव के लिए,",
    "secondline": "अपनी स्क्रीन पलटें"
  },

  "news": {
    "warningtext": "Hozzáfér a hírcsatornához, és a készülék hangja aktiválódik",
    "live": "élő",
    "continuous": "folyamatosan"
  },

  "poi": {
    "comment": "felhasználói értékelés",
    "route": "út",
    "nodata": "Egyetlen tartalom sem felel meg a keresésnek",
    "openinghours": "Nyitvatartási idő",
    "prices": "Árak",
    "paymentmethods": "Fizetési módok",
    "numberofplaces": "helyek száma",
    "openroutemobile": "nyissa meg a Google Maps útvonalat az okostelefonon",
    "FoodEstablishment": "Hol tudok enni",
    "LodgingBusiness": "Hol kell aludni",
    "TouristDestination": "Mit kell látni",
    "TouristAttraction": "Mit kell tenni",
    "Product": "Helyi termékek",
    "Service": "Szolgáltatások",
    "Event": "Események",
    "City": "Városok",
    "Wine": "Borok"
  },

  "suggestion": {
    "consult": "Nézze meg",
    "thetutorial": "az oktatóanyag"
  },

  "template": {
    "reset": "minden visszaállítása",
    "showresults": "Mutasd meg az eredményeket",
    "suggestiontitle": "Keresési javaslatok",
    "reminder": "Ne felejtsd el, \n tudok válaszolni a kérdéseidre."
  }
}
