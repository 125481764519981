{
  "_comment": "[CI-LOCKED] Το περιεχόμενο αυτού του αρχείου είναι κλειδωμένο. Οι συνεισφορές είναι ευπρόσδεκτες, αλλά η επικύρωση αλλαγών μπορεί να καθυστερήσει.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Το bot $1 είπε:",
  "ACTIVITY_YOU_SAID_ALT": "Είπατε:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Συνημμένο bot:",
  "ACTIVITY_ERROR_BOX_TITLE": "Μήνυμα σφάλματος",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Κάντε κλικ για διαδραστική χρήση.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Το μήνυμα έχει αλληλεπίδραση.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Μία ή περισσότερες συνδέσεις στο μήνυμα.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Η αποστολή απέτυχε.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Επισυνάψατε:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 συνημμένο.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 συνημμένα.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 συνημμένα.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 συνημμένα.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 συνημμένα.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Η αποστολή απέτυχε. [Επανάληψη][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Αποστολή",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Στάλθηκε στις $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Μόλις τώρα",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Πριν από μία ώρα",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Πριν από ένα λεπτό",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Σήμερα",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Εχθές",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Σφάλμα ανάλυσης προσαρμοστικής κάρτας",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Σφάλμα απόδοσης προσαρμοστικής κάρτας",
  "ATTACHMENT_AUDIO": "Ένα απόσπασμα ήχου.",
  "ATTACHMENT_CARD": "Μια κάρτα: $1 $2 $3",
  "ATTACHMENT_FILE": "Ένα αρχείο: $1",
  "ATTACHMENT_IMAGE": "Μια εικόνα",
  "ATTACHMENT_TEXT": "Ένα κείμενο: $1",
  "ATTACHMENT_VIDEO": "Ένα απόσπασμα βίντεο",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Επόμενο",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Προηγούμενο",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Συνδέθηκε",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Σύνδεση…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Αδυναμία σύνδεσης.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Παρουσιάστηκε διακοπή δικτύου. Επανασύνδεση...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Σφάλμα απόδοσης. Ελέγξτε την κονσόλα ή επικοινωνήστε με τον προγραμματιστή bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Η σύνδεση διαρκεί περισσότερο χρόνο από το συνηθισμένο.",
  "CONNECTIVITY_STATUS_ALT": "Κατάσταση συνδεσιμότητας: $1",
  "FILE_CONTENT_ALT": "\"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Λήψη αρχείου \"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Λήψη αρχείου \"$1\" μεγέθους $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "\"$1\" μεγέθους $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Πλήκτρο Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Πρόσβαση σε στοιχεία στο μήνυμα",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Ιστορικό συνομιλίας",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Πλήκτρο Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Μήνυμα εξόδου",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Πλήκτρο Tab\nShift + πλήκτρο Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Μετακινηθείτε μεταξύ στοιχείων σε ένα μήνυμα",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Πλήκτρο βέλους προς τα επάνω\nΠλήκτρο βέλους προς τα κάτω",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Μετακίνηση μεταξύ μηνυμάτων",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Πλήκτρο Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Εκτέλεση ενέργειας",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Πλήκτρο Tab\nShift + πλήκτρο Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Μετακίνηση μεταξύ στοιχείων στο παράθυρο συνομιλίας",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Παράθυρο συνομιλίας",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Κλείσιμο",
  "KEYBOARD_HELP_HEADER": "Στοιχεία ελέγχου πληκτρολόγιου",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Ανοίγει νέο παράθυρο – εξωτερικό.",
  "RECEIPT_CARD_TAX": "Φόρος",
  "RECEIPT_CARD_TOTAL": "Σύνολο",
  "RECEIPT_CARD_VAT": "ΦΠΑ",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Δεν είναι η αποστολή κενού μηνύματος.",
  "SPEECH_INPUT_LISTENING": "Ακρόαση...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Μικρόφωνο ανενεργό",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Μικρόφωνο ενεργό",
  "SPEECH_INPUT_STARTING": "Έναρξη…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Επόμενο",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Προηγούμενο",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Προτεινόμενες ενέργειες",
  "TEXT_INPUT_ALT": "Πλαίσιο εισαγωγής μηνύματος",
  "TEXT_INPUT_PLACEHOLDER": "Πληκτρολογήστε το μήνυμά σας",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Αποστολή",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Ομιλία",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Αποστολή αρχείου",
  "TOAST_ACCORDION_FEW": "$1 Ειδοποιήσεις: Κάντε κλικ εδώ για να δείτε λεπτομέρειες",
  "TOAST_ACCORDION_MANY": "$1 Ειδοποιήσεις: Κάντε κλικ εδώ για να δείτε λεπτομέρειες",
  "TOAST_ACCORDION_OTHER": "$1 Ειδοποιήσεις: Κάντε κλικ εδώ για να δείτε λεπτομέρειες",
  "TOAST_ACCORDION_TWO": "$1 Ειδοποιήσεις: Κάντε κλικ εδώ για να δείτε λεπτομέρειες",
  "TOAST_ALT_ERROR": "Σφάλμα",
  "TOAST_ALT_INFO": "Πληροφορίες",
  "TOAST_ALT_SUCCESS": "Επιτυχία",
  "TOAST_ALT_WARN": "Προειδοποίηση",
  "TOAST_DISMISS_BUTTON": "Ματαίωση",
  "TOAST_TITLE_ALT": "Ειδοποίηση",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Ιστορικό συνομιλίας, πιέστε τα πλήκτρα βέλους για περιήγηση.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "ιστορικό συνομιλίας",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Το μήνυμα είναι αλληλεπιδραστικό. Πιέστε τον συνδυασμό πλήκτρων Shift και Tab 2 έως 3 φορές για να μεταβείτε στο ιστορικό συνομιλίας. Στη συνέχεια, κάντε κλικ στο μήνυμα για αλληλεπίδραση.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Μία ή περισσότερες συνδέσεις στο μήνυμα. Πιέστε τον συνδυασμό πλήκτρων Shift και Tab 2 έως 3 φορές για να μεταβείτε στο ιστορικό συνομιλίας. Στη συνέχεια, κάντε κλικ στο μήνυμα για αλληλεπίδραση.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Το μήνυμα έχει προτεινόμενες ενέργειες. Πιέστε τον συνδυασμό πλήκτρων Shift και Tab 2 έως 3 φορές για να τις επιλέξετε.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Το μήνυμα έχει προτεινόμενες ενέργειες. Πιέστε $1 για να τις επιλέξετε.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Δεν ήταν δυνατή η αποστολή του μηνύματος.",
  "TRANSCRIPT_MORE_MESSAGES": "Περισσότερα μηνύματα",
  "TRANSCRIPT_NEW_MESSAGES": "Νέα μηνύματα",
  "TRANSCRIPT_TERMINATOR_TEXT": "Λήξη ιστορικού συνομιλίας",
  "TYPING_INDICATOR_ALT": "Εμφάνιση δείκτη πληκτρολόγησης",
  "TYPING_INDICATOR_SINGLE_TEXT": "Ο/Η $1 πληκτρολογεί.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "Ο/Η $1 και άλλοι πληκτρολογούν."
}