import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const BorneParametersIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <path
        d='M218.9,43.9l-2.6,13.3l-9.7,48.5c-15.6,6.1-29.6,14.7-42.3,25l-47.4-16.3l-12.8-4.1l-6.6,11.7L64.9,178
      l-6.6,11.7l9.7,8.7l36.7,32.1c-1.3,8.4-3.1,16.7-3.1,25.5c0,8.8,1.7,17.1,3.1,25.5l-36.7,32.1l-9.7,8.7l6.6,11.7l32.6,56.1l6.6,11.7
      l12.8-4.1l47.4-16.3c12.8,10.3,26.8,18.9,42.3,25l9.7,48.5l2.6,13.3h91.8l2.6-13.3l9.7-48.5c15.6-6.1,29.6-14.7,42.3-25l47.4,16.3
      l12.8,4.1l6.6-11.7l32.6-56.1l6.6-11.7l-9.7-8.7l-36.7-32.1c1.3-8.4,3.1-16.7,3.1-25.5c0-8.8-1.7-17.1-3.1-25.5l36.7-32.1l9.7-8.7
      l-6.6-11.7l-32.6-56.1l-6.6-11.7l-12.8,4.1l-47.4,16.3c-12.8-10.3-26.8-18.9-42.3-25l-9.7-48.5l-2.6-13.3H218.9z M245.4,76.5h38.8
      l8.2,42.3l2,9.7l9.2,3.1c18.6,5.8,35.4,15.6,49.5,28.6l7.1,6.6l9.2-3.1l41.3-14.3l19.4,33.2l-32.6,29.1l-7.7,6.1l2.6,9.7
      c2.1,9.3,3.1,18.8,3.1,28.6c0,9.8-1,19.3-3.1,28.6l-2,9.7l7.1,6.1l32.6,29.1l-19.4,33.2l-41.3-14.3l-9.2-3.1l-7.1,6.6
      c-14.1,12.9-30.9,22.8-49.5,28.6l-9.2,3.1l-2,9.7l-8.2,42.3h-38.8l-8.2-42.3l-2-9.7l-9.2-3.1c-18.6-5.8-35.4-15.6-49.5-28.6
      l-7.1-6.6l-9.2,3.1l-41.3,14.3l-19.4-33.2l32.6-29.1l7.7-6.1l-2.6-9.7c-2.1-9.3-3.1-18.8-3.1-28.6c0-9.8,1-19.3,3.1-28.6l2.6-9.7
      l-7.7-6.1l-32.6-29.1l19.4-33.2l41.3,14.3l9.2,3.1l7.1-6.6c14.1-12.9,30.9-22.8,49.5-28.6l9.2-3.1l2-9.7L245.4,76.5z M264.8,174.4
      c-44.9,0-81.6,36.7-81.6,81.6s36.7,81.6,81.6,81.6s81.6-36.7,81.6-81.6S309.7,174.4,264.8,174.4z M264.8,207.1c27.2,0,49,21.7,49,49
      s-21.7,49-49,49c-27.2,0-49-21.7-49-49S237.6,207.1,264.8,207.1z'
        fill={color || '#95989E'}
      />
    </svg>
  )
}

export default BorneParametersIcon
