/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react'
import RetorikComposer from './RetorikComposer'
import RetorikRouter from './RetorikRouter'
import Container from './Container'
import ContextProvider from '../Contexts/ContextProvider'
import getThemeColors from '../../utils/getThemeColors'
import type { RetorikMainComponentConfiguration } from '../../models/retorikTypes'
import type { ThemeColors } from '../../models/colorTypes'
import NewsView from '../Views/NewsView'
import SpeechManager from '../Speech/SpeechManager'
import FocusManager from '../Speech/FocusManager'
import MobileLandscapePreventer from '../Utils/MobileLandscapePreventer'
import ErrorCollector from '../Telemetry/ErrorCollector'
import { ContainerParent, Mode } from '../../models/enums'
import SendTypingIndicator from '../Utils/SendTypingIndicator'
import { useRetorikStore } from '../Contexts/retorikStore'
import { pdfjs } from 'react-pdf'
import * as pdfJsWorker from 'pdfjs-dist/build/pdf.worker.js'

// Configure react-pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker

type RetorikContainerProps = {
  config: RetorikMainComponentConfiguration
  parent: number
  isConfigUpdated: boolean
  changedLayout: boolean
}

const RetorikContainer = ({
  parent,
  isConfigUpdated,
  config: props,
  changedLayout
}: RetorikContainerProps): JSX.Element => {
  const [processedThemeColors] = useState<ThemeColors>(
    getThemeColors(props.colors)
  )
  const currentLayout: ContainerParent = useRetorikStore(
    (state) => state.currentLayout
  )

  return props.config &&
    props.viewsConfig &&
    props.chatbotData &&
    props.chatbotDataWidget &&
    props.agentData &&
    props.ponyfillFactoryCredentials ? (
    <ContextProvider
      skipLoader={props.skipLoader}
      mode={props.defaultMode || Mode.vocal}
      viewsConfiguration={props.viewsConfig}
      config={props.config}
      addressData={props.addressData}
      agentSource={props.agentData}
      ponyfillFactoryCredentials={props.ponyfillFactoryCredentials}
      themeColors={processedThemeColors}
      isConfigUpdated={isConfigUpdated}
      customVoice={props.customVoice}
    >
      {!props.config.forceMobileView && <MobileLandscapePreventer />}
      <RetorikComposer
        addressData={props.addressData}
        userData={props.userData}
        externalEventHandler={props.externalEventHandler}
        colors={processedThemeColors}
        isRetorikNews={parent === ContainerParent.news}
        changedLayout={changedLayout}
      >
        <ErrorCollector>
          <SpeechManager isRetorikNews={parent === ContainerParent.news} />
          <FocusManager />
          <Container
            fullSize={
              parent !== ContainerParent.widget &&
              props.config.fullSize !== false
            }
            width={props.width}
            height={props.height}
            colors={processedThemeColors}
            parent={parent}
          >
            {parent === ContainerParent.news ? (
              <NewsView
                chatbotData={
                  currentLayout === ContainerParent.agent
                    ? props.chatbotData
                    : props.chatbotDataWidget
                }
                hideMenu={true}
                isRetorikNews={true}
              />
            ) : (
              <RetorikRouter
                chatbotData={
                  currentLayout === ContainerParent.agent
                    ? props.chatbotData
                    : props.chatbotDataWidget
                }
                externalComponents={props.externalComponents}
              />
            )}
          </Container>
          <SendTypingIndicator />
        </ErrorCollector>
      </RetorikComposer>
    </ContextProvider>
  ) : (
    <React.Fragment />
  )
}

export default RetorikContainer
