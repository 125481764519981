import { create } from 'zustand'
import { setRetorikEvent } from './utilsStore'
import { RetorikEvent, RecognitionState } from '../../models/enums'
import type { Boundary } from '../../models/speechTypes'
import type { RetorikActivity } from '../../models/activityTypes'

interface SpeechStore {
  speaking: boolean
  muted: boolean
  cancel: boolean
  boundaryData: Array<Boundary>
  currentReplyToId: string | undefined
  currentPlaying: RetorikActivity | undefined
  endedActivities: Array<string>
  currentOrLastPlayedActivity: RetorikActivity | undefined
  // Speech recognition data
  activeRecognitionState: RecognitionState
  lastRecognitionInterim: string
  // Streaming by multiple events data
  streamingReplyToId: string | null
  streamingReplyToIdForText: string | null
  streamingQueue: Array<RetorikActivity>
  streamingQueueForText: Array<RetorikActivity>
  currentStreaming: string | null
}

const initialState: SpeechStore = {
  speaking: false,
  muted: false,
  cancel: false,
  boundaryData: [],
  currentReplyToId: undefined,
  currentPlaying: undefined,
  endedActivities: [],
  currentOrLastPlayedActivity: undefined,
  activeRecognitionState: RecognitionState.Closed,
  lastRecognitionInterim: '',
  streamingReplyToId: null,
  streamingReplyToIdForText: null,
  streamingQueue: [],
  streamingQueueForText: [],
  currentStreaming: null
}

export const useSpeechStore = create<SpeechStore>()(() => {
  return initialState
})

export const setSpeaking = (value: boolean): void => {
  useSpeechStore.setState({ speaking: value })
  setRetorikEvent(value ? RetorikEvent.SpeechStarted : RetorikEvent.SpeechEnded)
}

export const setMuted = (value: boolean): void => {
  useSpeechStore.setState({ muted: value })
}

export const setCancel = (cancel: boolean): void => {
  useSpeechStore.setState({ cancel: cancel })
}

export const setBoundaryData = (value: Array<Boundary>): void => {
  useSpeechStore.setState({ boundaryData: value })
}

export const setCurrentReplyToId = (value: string | undefined): void => {
  useSpeechStore.setState({ currentReplyToId: value })
}

export const setCurrentPlaying = (value: RetorikActivity | undefined): void => {
  useSpeechStore.setState({ currentPlaying: value })
  value && setCurrentOrLastPlayedActivity(value)
}

export const setEndedActivities = (value: Array<string>): void => {
  useSpeechStore.setState({ endedActivities: value })
}

export const setCurrentOrLastPlayedActivity = (
  value: RetorikActivity | undefined
): void => {
  useSpeechStore.setState({ currentOrLastPlayedActivity: value })
}

// Speech Recognition utils
export const setActiveRecognitionState = (value: RecognitionState): void => {
  useSpeechStore.setState({ activeRecognitionState: value })
}

export const setLastRecognitionInterim = (value: string): void => {
  useSpeechStore.setState({ lastRecognitionInterim: value })
}

export const setStreamingReplyToId = (value: string | null): void => {
  useSpeechStore.setState({
    streamingReplyToId: value,
    streamingReplyToIdForText: value
  })
  value === null && useSpeechStore.setState({ currentStreaming: null })
}

export const setStreamingReplyToIdForText = (value: string | null): void => {
  useSpeechStore.setState({ streamingReplyToIdForText: value })
}

export const setStreamingQueue = (value: Array<RetorikActivity>): void => {
  useSpeechStore.setState({
    streamingQueue: value,
    streamingQueueForText: value
  })
}

export const addToStreamingQueue = (value: RetorikActivity): void => {
  console.log('add to queue')
  if (value.replyToId) {
    // Set replyToId and new queue if replyToId is different from the current one
    if (useSpeechStore.getState().streamingReplyToId !== value.replyToId) {
      useSpeechStore.setState({
        streamingReplyToId: value.replyToId,
        streamingReplyToIdForText: value.replyToId,
        streamingQueue: [value],
        streamingQueueForText: [value]
      })
    } else {
      useSpeechStore.setState({
        streamingQueue: [...useSpeechStore.getState().streamingQueue, value],
        streamingQueueForText: [
          ...useSpeechStore.getState().streamingQueueForText,
          value
        ]
      })
    }
  }
}

export const removeFirstFromStreamingQueue = (): void => {
  const currentQueue = [...useSpeechStore.getState().streamingQueue]
  if (currentQueue.length) {
    currentQueue.splice(0, 1)
    useSpeechStore.setState({
      streamingQueue: [...currentQueue]
    })
  }
}

export const setCurrentStreaming = (value: string | null): void => {
  useSpeechStore.setState({ currentStreaming: value })
}

export const resetSpeechStore = (): void => {
  useSpeechStore.setState({ ...initialState })
}
