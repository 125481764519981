import React, { useState } from 'react'
import {
  setModalFullscreenContent,
  useUtilsStore
} from '../Contexts/utilsStore'
import { useLocaleStore } from '../Contexts/localeStore'
import { PrintIcon } from '../Icons/DetailedPOIIcons'
import { CloseIcon } from '../Icons/Miscellaneous'
import { Document, Page } from 'react-pdf'
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons/ChevronIcons'

interface PrintingPreviewProps {
  url: string
  printingAllowed?: boolean
}

const PrintingPreview = ({
  url,
  printingAllowed
}: PrintingPreviewProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const printingCallback = useUtilsStore((state) => state.printingCallback)
  const [documentLoaded, setDocumentLoaded] = useState<boolean>(false)
  const [numberOfPages, setNumberOfPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumberOfPages(numPages)
    setDocumentLoaded(true)
  }

  const handleClick = (): void => {
    console.log('Call printing and close preview')
    printingCallback?.(url)
    handleClose()
  }

  const handleClose = (): void => {
    setModalFullscreenContent(null)
  }

  const changePage = (index: number): void => {
    setPageNumber((current) => current + index)
  }

  return (
    <div
      className='rf-h-full large-vertical:rf-mt-[20vh] large-vertical:rf-h-1/2 rf-max-w-1/2 large-vertical:rf-max-w-[60%] rf-py-4 rf-flex rf-flex-col rf-gap-4'
      style={{
        visibility: documentLoaded ? 'visible' : 'hidden'
      }}
    >
      {/* Upper part */}
      <div className='rf-grid rf-grid-cols-printingPreview rf-min-h-12 rf-h-12'>
        {printingAllowed ? (
          <button
            className='rf-px-4 rf-py-1 rf-h-full rf-justify-self-center rf-flex rf-flex-row rf-items-center rf-gap-2 rf-font-bold rf-text-sm rf-rounded-lg rf-bg-truewhite'
            onClick={handleClick}
          >
            <PrintIcon />
            {translation.common.print}
          </button>
        ) : (
          <div>{'\u2800'}</div>
        )}
        <button
          className='rf-h-full rf-w-12 rf-justify-self-end rf-flex rf-justify-center rf-items-center rf-rounded-lg rf-bg-truewhite'
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Document preview */}
      <div className='rf-relative rf-w-full rf-flex rf-flex-col rf-rounded-lg rf-overflow-y-scroll rf-scrollbar-thin'>
        {/* Number of pages + navigation between pages */}
        {numberOfPages > 1 && (
          <div className='rf-w-full rf-px-4 rf-pt-3 rf-pb-2 rf-flex rf-flex-row rf-justify-between rf-items-center rf-bg-truewhite'>
            <button
              className={`${pageNumber <= 1 ? 'rf-invisible' : 'rf-visible'}`}
              onClick={() => changePage(-1)}
            >
              <ChevronLeftIcon className='rf-h-4' color='#000' />
            </button>
            <p className='rf-text-xl'>
              {pageNumber} / {numberOfPages}
            </p>
            <button
              className={`${
                pageNumber >= numberOfPages ? 'rf-invisible' : 'rf-visible'
              }`}
              onClick={() => changePage(1)}
            >
              <ChevronRightIcon className='rf-h-4' color='#000' />
            </button>
          </div>
        )}

        {/* Pdf preview */}
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={handleClose}
        >
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </div>
    </div>
  )
}

export default PrintingPreview
