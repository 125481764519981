import { create } from 'zustand'
import { ContainerParent, Mode } from '../../models/enums'
import { setUserTyping, setLastListActivity } from './utilsStore'
import { setMuted } from './speechStore'
import { AgentData } from '../../models/types'
import fetchAgentData from '../../utils/fetchAgentData'

interface RetorikStore {
  mode: Mode
  subtitlesInConfiguration: boolean
  displaySubtitles: boolean
  agentData: AgentData | undefined
  loaderClosed: boolean
  currentLayout: ContainerParent
  changeLayoutButtonDisplay: boolean
  layoutChanged: boolean
  userAlreadySet: boolean
  enableDocumentPrinting: boolean
  disableSound: boolean
}

const initialState: RetorikStore = {
  mode: Mode.text,
  subtitlesInConfiguration: false,
  displaySubtitles: false,
  agentData: undefined,
  loaderClosed: false,
  currentLayout: ContainerParent.agent,
  changeLayoutButtonDisplay: false,
  layoutChanged: false,
  userAlreadySet: false,
  enableDocumentPrinting: false,
  disableSound: false
}

export const useRetorikStore = create<RetorikStore>()(() => {
  return initialState
})

export const setDisableSound = (value: boolean): void => {
  useRetorikStore.setState({ disableSound: value })
}

export const setLayoutChanged = (value: boolean): void => {
  console.log(
    'set layoutChanged',
    useRetorikStore.getState().layoutChanged,
    value
  )
  useRetorikStore.setState({ layoutChanged: value })
}

export const setLayout = (value: ContainerParent): void => {
  useRetorikStore.setState({ currentLayout: value })
}

export const setUserAlreadySet = (value: boolean): void => {
  useRetorikStore.setState({ userAlreadySet: value })
}

export const setDisplayChangeLayoutButton = (value: boolean): void => {
  useRetorikStore.setState({ changeLayoutButtonDisplay: value })
}

export const setMode = (value: Mode): void => {
  useRetorikStore.setState({ mode: value })
  !useRetorikStore.getState().subtitlesInConfiguration &&
    useRetorikStore.setState({ displaySubtitles: false })
  setLastListActivity(undefined)
  value !== Mode.text && setUserTyping(false)
  !useRetorikStore.getState().disableSound && setMuted(value === Mode.text)
}

export const setLoaderClosed = (value: boolean): void => {
  useRetorikStore.setState({ loaderClosed: value })
}

export const setSubtitlesInConfiguration = (value: boolean): void => {
  useRetorikStore.setState({ subtitlesInConfiguration: value })
  useRetorikStore.setState({
    displaySubtitles: value && useRetorikStore.getState().mode === Mode.vocal
  })
}

export const setDisplaySubtitles = (value: boolean): void => {
  useRetorikStore.setState({ displaySubtitles: value })
}

export const setAgentData = async (
  value: string | { url: string; name?: string }
): Promise<void> => {
  const url = typeof value === 'string' ? value : value.url
  const data = await fetchAgentData(url)
  if (data) {
    typeof value !== 'string' && value.name && (data.name = value.name)
  }

  useRetorikStore.setState({ agentData: data })
}

export const setEnableDocumentPrinting = (value: boolean): void => {
  useRetorikStore.setState({ enableDocumentPrinting: value })
}

export const resetRetorikStore = (): void => {
  useRetorikStore.setState({ ...initialState })
}
