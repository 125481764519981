import React from 'react'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { ArrowIcon } from '../../../Icons/ArrowIcons'
import { imageFiles } from '../../../../utils/imageFiles'

interface VocalReminderProps {
  className?: string
  handleClose: () => void
}

const VocalReminder = ({
  className,
  handleClose
}: VocalReminderProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  return (
    <div
      className={`hover:rf-cursor-pointer rf-flex rf-flex-row rf-items-start ${className}`}
      onClick={handleClose}
    >
      <img src={imageFiles.leaMini} className='rf-h-12' />
      <div className='rf-flex rf-flex-col rf-align-start rf-justify-start'>
        <div className='rf-flex rf-flex-col rf-pl-2'>
          {translation.template.reminder.split('\n').map((line, key) => {
            return <div key={key}>{line}</div>
          })}
        </div>
        <ArrowIcon />
      </div>
    </div>
  )
}

export default VocalReminder
