{
  "1": "strona główna",
  "2": "kanał informacyjny na żywo",
  "3": "pogoda",
  "4": "numery alarmowe",
  "1000": "historia",
  "1001": "instruktaż",

  "common": {
    "all": "Wszystko",
    "back": "z powrotem",
    "close": "zamknąć",
    "continue": "Kontynuować",
    "download": "pobierać",
    "downloadsheet": "pobierz ten arkusz",
    "downloadsheetmobile": "pobierz ten arkusz na smartfonie",
    "filter": "Filtr",
    "history": "historyczny",
    "lang": "Języki",
    "menu": "menu",
    "mode": "moda",
    "needhelp": "Potrzebuję pomocy?",
    "parameters": "ustawienia",
    "print": "Wydrukować",
    "printsheet": "Wydrukuj ten arkusz",
    "result": "wynik",
    "results": "wyniki",
    "singleLang": "język",
    "sound": "jej",
    "submit": "składać",
    "subtitle": "podtytuł",
    "tutorial": "instruktaż",
    "validate": "potwierdzić"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "Policja",
    "firefighter": "strażacy",
    "general": "ogólny",
    "hospital": "Szpital",
    "homeless": "Bezdomny",
    "poison": "Centrum Kontroli Trucizn",
    "emergency": "Służby ratunkowe"
  },

  "error": {
    "title": "Wystąpił błąd",
    "button": "Uruchom ponownie aplikację"
  },

  "history": {
    "title": "Historia konwersacji"
  },

  "inputs": {
    "choose": "Wybierz odpowiedź"
  },

  "language": {
    "change": "zmienić język",
    "select": "wybierz wybrany język"
  },

  "link": {
    "buttontext": "Obejrzyj dokument",
    "goto": "Przejdź do adresu",
    "click": "Kliknij tutaj",
    "scanQR": "poprzez zeskanowanie kodu QR"
  },

  "loader": {
    "loader": {
      "optimal": "Aby uzyskać optymalne wrażenia,",
      "classic": "Aby uzyskać bardziej klasyczne wrażenia,",
      "vocal": "Rozmawiam twarzą w twarz z agentem.",
      "text": "Komunikuję się pisemnie z agentem."
    },
    "modes": {
      "vocal": "tryb wokalny",
      "text": "tryb tekstowy"
    },
    "loaderValidation": "Rozpocznij rozmowę",
    "locale": "sprawdzanie języka",
    "supported": "sprawdzanie obsługiwanych języków",
    "addressdata": "pobieranie danych"
  },

  "menu": {
    "message": "Wpisz swoją wiadomość",
    "allowMicrophone": "Aby kontynuować, zezwól przeglądarce na korzystanie z mikrofonu",
    "backconversation": "wróć do rozmowy",
    "answer": " odpowiada na Twoje pytania"
  },

  "mobileLandscapePreventer": {
    "firstline": "Aby uzyskać optymalne wrażenia,",
    "secondline": "obróć ekran"
  },

  "news": {
    "warningtext": "Uzyskasz dostęp do kanału informacyjnego, dźwięk na Twoim urządzeniu zostanie aktywowany",
    "live": "na żywo",
    "continuous": "bez przerwy"
  },

  "poi": {
    "comment": "Recenzja klienta",
    "route": "droga",
    "nodata": "Żadna treść nie pasuje do Twojego wyszukiwania",
    "openinghours": "Godziny otwarcia",
    "prices": "Ceny",
    "paymentmethods": "Metody Płatności",
    "numberofplaces": "liczba miejsc",
    "openroutemobile": "otwórz trasę Map Google na smartfonie",
    "FoodEstablishment": "Gdzie zjeść",
    "LodgingBusiness": "Gdzie spać",
    "TouristDestination": "Co zobaczyć",
    "TouristAttraction": "Co robić",
    "Product": "Lokalne produkty",
    "Service": "Usługi",
    "Event": "Wydarzenia",
    "City": "Miasta",
    "Wine": "Wina"
  },

  "suggestion": {
    "consult": "Wymeldować się",
    "thetutorial": "samouczek"
  },

  "template": {
    "reset": "Zresetować wszystko",
    "showresults": "Pokaż wyniki",
    "suggestiontitle": "Wyszukaj sugestie",
    "reminder": "Nie zapomnij, \n, że mogę odpowiedzieć na Twoje pytania."
  }
}
