import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo
} from 'react'
import { DeviceType } from '../../models/enums'
import type {
  CustomVoice,
  PonyfillFactoryCredentials
} from '../../models/types'
import { useRetorik } from './RetorikContext'
import { useView } from './ViewContext'

export type SpeechProviderProps = {
  ponyfillFactoryCredentials: PonyfillFactoryCredentials
  customVoice: CustomVoice | undefined
  children?: ReactNode
}

type SpeechContextType = {
  // Common data
  ponyfillCredentials: PonyfillFactoryCredentials
  // Speech synthesis data
  voice: SpeechSynthesisVoice | null
  customVoice: CustomVoice | undefined
  setVoice: (x: SpeechSynthesisVoice | null) => void
  // Speech recognition data
  useContinuousRecognition: boolean
  setUseContinuousRecognition: (x: boolean) => void
}

const SpeechContextDefaultValues: SpeechContextType = {
  // Common data
  ponyfillCredentials: {
    region: 'francecentral',
    subscriptionKey: ''
  },
  // Speech synthesis data
  voice: null,
  customVoice: undefined,
  setVoice: () => {},
  // Speech recognition data
  useContinuousRecognition: true,
  setUseContinuousRecognition: () => {}
}

export const SpeechContext = createContext<SpeechContextType>(
  SpeechContextDefaultValues
)

export function useSpeech(): SpeechContextType {
  return useContext(SpeechContext)
}

export function SpeechProvider({
  ponyfillFactoryCredentials,
  customVoice,
  children
}: SpeechProviderProps): JSX.Element {
  const {
    configuration: { speechRecognitionOptions }
  } = useRetorik()
  const { currentDeviceType } = useView()

  const checkContinuousSpeechRecognition = (): boolean => {
    if (speechRecognitionOptions?.enableContinuousRecognitionOnAllDevices) {
      return true
    }

    if (speechRecognitionOptions?.enableContinuousRecognitionOn) {
      switch (currentDeviceType) {
        case DeviceType.mobile:
          return !!speechRecognitionOptions.enableContinuousRecognitionOn.mobile
        case DeviceType.widget:
          return !!speechRecognitionOptions.enableContinuousRecognitionOn.widget
        case DeviceType.landscape:
          return !!speechRecognitionOptions.enableContinuousRecognitionOn
            .desktop
        case DeviceType.borne:
          return !!speechRecognitionOptions.enableContinuousRecognitionOn.borne
        default:
          return false
      }
    }

    return false
  }

  const [voice, setVoice] = useState<SpeechSynthesisVoice | null>(null)
  const [useContinuousRecognition, setUseContinuousRecognition] =
    useState<boolean>(checkContinuousSpeechRecognition())

  const value = useMemo(
    () => ({
      ponyfillCredentials: ponyfillFactoryCredentials,
      voice,
      customVoice,
      setVoice,
      useContinuousRecognition,
      setUseContinuousRecognition
    }),
    [
      ponyfillFactoryCredentials,
      voice,
      customVoice,
      setVoice,
      useContinuousRecognition,
      setUseContinuousRecognition
    ]
  )

  return (
    <React.Fragment>
      <SpeechContext.Provider value={value}>{children}</SpeechContext.Provider>
    </React.Fragment>
  )
}
