import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const RoundIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20.306 20.306'
      className={className || 'rf-h-4'}
    >
      <g transform='translate(-2 -2)'>
        <path
          d='M21.306,12.153A9.153,9.153,0,1,1,12.153,3a9.153,9.153,0,0,1,9.153,9.153Z'
          transform='translate(0 0)'
          fill='none'
          stroke={color || '#000'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          d='M18,21.661V18'
          transform='translate(-5.847 -5.847)'
          fill='none'
          stroke={color || '#000'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          d='M18,12h0'
          transform='translate(-5.847 -3.508)'
          fill='none'
          stroke={color || '#000'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default RoundIcon
