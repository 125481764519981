{
  "1": "หน้าแรก",
  "2": "ช่องทางข้อมูลสด",
  "3": "สภาพอากาศ",
  "4": "หมายเลขฉุกเฉิน",
  "1000": "ประวัติศาสตร์",
  "1001": "กวดวิชา",

  "common": {
    "all": "ทั้งหมด",
    "back": "กลับ",
    "close": "ปิด",
    "continue": "ดำเนินการต่อ",
    "download": "ดาวน์โหลด",
    "downloadsheet": "ดาวน์โหลดเอกสารนี้",
    "downloadsheetmobile": "ดาวน์โหลดเอกสารนี้บนสมาร์ทโฟน",
    "filter": "กรอง",
    "history": "ประวัติศาสตร์",
    "lang": "ภาษา",
    "menu": "เมนู",
    "mode": "แฟชั่น",
    "needhelp": "ต้องการความช่วยเหลือ?",
    "parameters": "การตั้งค่า",
    "print": "พิมพ์",
    "printsheet": "พิมพ์แผ่นนี้",
    "result": "ผลลัพธ์",
    "results": "ผลลัพธ์",
    "singleLang": "ภาษา",
    "sound": "ของเธอ",
    "submit": "ส่ง",
    "subtitle": "คำบรรยาย",
    "tutorial": "กวดวิชา",
    "validate": "เพื่อตรวจสอบ"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "ตำรวจ",
    "firefighter": "นักดับเพลิง",
    "general": "ทั่วไป",
    "hospital": "โรงพยาบาล",
    "homeless": "คนไร้บ้าน",
    "poison": "ศูนย์ควบคุมพิษ",
    "emergency": "บริการฉุกเฉิน"
  },

  "error": {
    "title": "เกิดข้อผิดพลาด",
    "button": "เปิดแอปพลิเคชันอีกครั้ง"
  },

  "history": {
    "title": "ประวัติการสนทนา"
  },

  "inputs": {
    "choose": "เลือกคำตอบ"
  },

  "language": {
    "change": "เปลี่ยนภาษา",
    "select": "เลือกภาษาที่คุณต้องการ"
  },

  "link": {
    "buttontext": "ดูเอกสาร",
    "goto": "ไปที่ที่อยู่",
    "click": "คลิกที่นี่",
    "scanQR": "โดยการสแกน QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "เพื่อประสบการณ์ที่ดีที่สุด",
      "classic": "เพื่อประสบการณ์คลาสสิกยิ่งขึ้น",
      "vocal": "ฉันสื่อสารโดยการพูดคุยกับตัวแทน",
      "text": "ฉันสื่อสารทางข้อความกับตัวแทน"
    },
    "modes": {
      "vocal": "โหมดเสียงพูด",
      "text": "โหมดข้อความ"
    },
    "loaderValidation": "เริ่มการสนทนา",
    "locale": "กำลังตรวจสอบภาษา",
    "supported": "ตรวจสอบภาษาที่รองรับ",
    "addressdata": "กำลังดึงข้อมูล"
  },

  "menu": {
    "message": "ป้อนข้อความของคุณ",
    "allowMicrophone": "อนุญาตให้เบราว์เซอร์ของคุณใช้ไมโครโฟนเพื่อดำเนินการต่อ",
    "backconversation": "กลับไปที่การสนทนา",
    "answer": " ตอบคำถามของคุณ"
  },

  "mobileLandscapePreventer": {
    "firstline": "เพื่อประสบการณ์ที่ดีที่สุด",
    "secondline": "พลิกหน้าจอของคุณ"
  },

  "news": {
    "warningtext": "คุณจะเข้าถึงช่องข่าวเสียงบนอุปกรณ์ของคุณจะถูกเปิดใช้งาน",
    "live": "สด",
    "continuous": "อย่างต่อเนื่อง"
  },

  "poi": {
    "comment": "รีวิวลูกค้า",
    "route": "ถนน",
    "nodata": "ไม่มีเนื้อหาที่ตรงกับการค้นหาของคุณ",
    "openinghours": "เวลาทำการ",
    "prices": "ราคา",
    "paymentmethods": "วิธีการชำระเงิน",
    "numberofplaces": "จำนวนสถานที่",
    "openroutemobile": "เปิดเส้นทาง Google Maps บนสมาร์ทโฟน",
    "FoodEstablishment": "กินที่ไหนดี",
    "LodgingBusiness": "นอนไหน.",
    "TouristDestination": "มีอะไรให้ดูบ้าง",
    "TouristAttraction": "จะทำอย่างไร",
    "Product": "สินค้าพื้นเมือง",
    "Service": "บริการ",
    "Event": "กิจกรรม",
    "City": "เมือง",
    "Wine": "ไวน์"
  },

  "suggestion": {
    "consult": "เช็คเอาท์",
    "thetutorial": "บทช่วยสอน"
  },

  "template": {
    "reset": "การตั้งค่าทั้งหมด",
    "showresults": "แสดงผล",
    "suggestiontitle": "คำแนะนำการค้นหา",
    "reminder": "อย่าลืม \n ฉันสามารถตอบคำถามของคุณได้"
  }
}
