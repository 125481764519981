import type { HistoryActivity, RetorikActivity } from '../models/activityTypes'
import { createNewQuestionForTimestamp } from '../utils/activityUtils'
import { useActivityStore } from '../components/Contexts/activityStore'

export default function useMessageActivities(): Array<HistoryActivity> {
  const activities = useActivityStore((state) => state.messageActivities)
  // processedId contains the ids of the activities already processed (because useActivities gives duplicate activities with different state depending on if it's been spoken or not )
  const processedId: Array<string> = []
  const history: HistoryActivity[] = []

  activities.forEach((activity) => {
    if (activity.id && !processedId.includes(activity.id)) {
      processedId.push(activity.id)
      // Check if this is a question or an answer (questions from the user don't have a replyToId field)
      if (activity.replyToId) {
        const question: HistoryActivity | undefined = history.find(
          (historyItem) => historyItem.question.id === activity.replyToId
        )
        if (question) {
          question.answers.push(activity)
        } else {
          const newQuestion: RetorikActivity =
            createNewQuestionForTimestamp(activity)

          history.push({
            question: newQuestion,
            answers: [activity]
          })
        }
      } else {
        if (
          (activity as RetorikActivity).label === 'Davi.BringupMessage' &&
          (activity as RetorikActivity).text
        ) {
          const newQuestion: RetorikActivity =
            createNewQuestionForTimestamp(activity)
          history.push({
            question: newQuestion,
            answers: [activity]
          })
        } else {
          history.push({
            question: activity,
            answers: []
          })
        }
      }
    }
  })

  return history
}
