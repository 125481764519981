import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useEffect,
  useRef
} from 'react'
import type { RetorikActivity } from '../../models/activityTypes'
import type { Filter, FilterItem } from '../../models/filterTypes'
import { hooks } from 'botframework-webchat-component'
import { getColorFromCategoryId } from '../../utils/getColorFromCategoryId'
import { useView } from './ViewContext'
import { useRetorik } from './RetorikContext'

const { useSendPostBack } = hooks

export type ListProviderProps = {
  children?: ReactNode
}

export type ListContextType = {
  content: RetorikActivity | undefined
  filters: Array<Filter>
  selectedFilters: Array<Filter>
  loadingData: boolean
  color: string
  setContent: (x: RetorikActivity | undefined) => void
  setSelectedFilters: (x: Array<Filter>) => void
  resetFilters: () => void
  processFiltersOnChange: (x: Array<Filter>, y?: boolean) => void
  setSuggestedFilter: (x: Filter) => void
  setLoadingData: (x: boolean) => void
}

const ListContextDefaultValues: ListContextType = {
  content: undefined,
  filters: [],
  selectedFilters: [],
  loadingData: false,
  color: '',
  setContent: () => {},
  setSelectedFilters: () => {},
  resetFilters: () => {},
  processFiltersOnChange: () => {},
  setSuggestedFilter: () => {},
  setLoadingData: () => {}
}

export const ListContext = createContext<ListContextType>(
  ListContextDefaultValues
)

export function useList(): ListContextType {
  return useContext(ListContext)
}

export function ListProvider({ children }: ListProviderProps): JSX.Element {
  const sendPostBack = useSendPostBack()
  const { themeColors } = useView()
  const {
    configuration: { timerForFilterSelectionListMode }
  } = useRetorik()
  const [content, setContent] = useState<RetorikActivity | undefined>()
  const [filters, setFilters] = useState<Array<Filter>>([])
  const [selectedFilters, setSelectedFilters] = useState<Array<Filter>>([])
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const color = useMemo(() => {
    return getColorFromCategoryId(
      content?.channelData?.category?.id,
      themeColors
    )
  }, [content])
  const timerRef = useRef<NodeJS.Timer>()

  const setSuggestedFilter = (filter: Filter): void => {
    setSelectedFilters([filter])
  }

  /**
   * On call :
   *  - set selectedFilters state to empty array
   *  - send postBack message with empty filters
   */
  const resetFilters = (): void => {
    setSelectedFilters([])
    sendPostBack({ filters: [] })
  }

  /**
   * On call :
   *  - set selectedFilters and filters states to empty arrays
   */
  const totalReset = (): void => {
    setSelectedFilters([])
    setFilters([])
  }

  const processFiltersOnChange = (data: Array<Filter>, full = false): void => {
    let tempFilters: Array<Filter> = []
    timerRef?.current && clearTimeout(timerRef.current)

    if (selectedFilters.length > 0) {
      let remainingData = [...data]
      // Check on each selected filter to modify if necessary
      selectedFilters.forEach((selectedFilter) => {
        let items: Array<FilterItem> = [...selectedFilter.filterItems]
        data.forEach((filter) => {
          if (filter.id === selectedFilter.id) {
            // Special treatment for calendar filter : only 1 value allowed
            if (filter.id === 'Event.DateRange') {
              if (
                items.length > 0 &&
                filter.filterItems.length > 0 &&
                items[0] === filter.filterItems[0]
              ) {
                items = []
              } else {
                items = [...filter.filterItems]
              }
            } else {
              // If the chosen filter is a full one and has already at least one item chosen, let's choose all its items
              if (full) {
                items =
                  selectedFilter.filterItems.length ===
                  filter.filterItems.length
                    ? []
                    : [...filter.filterItems]
              }
              // Else let's deal with the items one by one
              else {
                items = [
                  ...selectedFilter.filterItems,
                  ...filter.filterItems
                ].filter(
                  (item) =>
                    !(
                      selectedFilter.filterItems.find(
                        (filterItem) =>
                          item.name === filterItem.name &&
                          item.value === filterItem.value
                      ) !== undefined &&
                      filter.filterItems.find(
                        (filterItem) =>
                          item.name === filterItem.name &&
                          item.value === filterItem.value
                      ) !== undefined
                    )
                )
              }
            }

            // Let's remove this filter from remainingData because it has been processed
            remainingData = remainingData.filter((fil) => fil.id !== filter.id)
          }
        })

        items.length > 0 &&
          (tempFilters = [
            ...tempFilters,
            {
              id: selectedFilter.id,
              title: selectedFilter.title,
              filterItems: items
            }
          ])
      })

      // If some filters weren't in the selected filters, we add them to the selected filters
      remainingData.length > 0 &&
        (tempFilters = [...tempFilters, ...remainingData])
    } else {
      data.length > 0 && (tempFilters = data)
    }

    setSelectedFilters(tempFilters)

    timerRef.current = setTimeout(
      () => {
        sendPostBack({ filters: tempFilters })
        setLoadingData(true)
      },
      timerForFilterSelectionListMode === undefined
        ? 1000
        : timerForFilterSelectionListMode
    )
  }

  const value = useMemo(
    () => ({
      content: content,
      filters: filters,
      selectedFilters: selectedFilters,
      loadingData,
      color: color,
      setContent,
      setSelectedFilters,
      resetFilters,
      processFiltersOnChange,
      setSuggestedFilter,
      setLoadingData
    }),
    [
      content,
      filters,
      selectedFilters,
      loadingData,
      color,
      setContent,
      setSelectedFilters,
      resetFilters,
      processFiltersOnChange,
      setSuggestedFilter,
      setLoadingData
    ]
  )

  /**
   * On content state change :
   *  - if there is no content, reset all filters and selected filters
   *  - if there is some content, set filters state with the content received or an empty array, and selectedFilters state with received data if there are some
   */
  useEffect(() => {
    if (content) {
      if (filters.length === 0 || content.channelData?.filters !== filters) {
        setFilters(content.channelData?.filters || [])
      }
      content.channelData?.selectedFilters &&
        setSelectedFilters(content.channelData?.selectedFilters)
    } else {
      totalReset()
    }
  }, [content])

  return (
    <React.Fragment>
      <ListContext.Provider value={value}>{children}</ListContext.Provider>
    </React.Fragment>
  )
}
