{
  "1": "home",
  "2": "live info channel",
  "3": "weather",
  "4": "emergency numbers",
  "1000": "history",
  "1001": "tutorial",

  "common": {
    "abort": "abort",
    "all": "all",
    "back": "back",
    "close": "close",
    "continue": "continue",
    "download": "download",
    "downloadsheet": "download this sheet",
    "downloadsheetmobile": "download this sheet on smartphone",
    "filter": "filter",
    "history": "history",
    "lang": "languages",
    "menu": "menu",
    "mode": "mode",
    "needhelp": "Need help ?",
    "parameters": "parameters",
    "print": "print",
    "printsheet": "print this sheet",
    "result": "result",
    "results": "results",
    "singleLang": "language",
    "sound": "sound",
    "submit": "submit",
    "subtitle": "subtitle",
    "tutorial": "tutorial",
    "validate": "validate"
  },

  "emergency": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general",
    "citypolice": "Local police",
    "hospital": "Hospital",
    "homeless": "Homeless (free call)",
    "poison": "Poison Control Center",
    "emergency": "European emergencies"
  },

  "error": {
    "title": "An error occurred",
    "button": "Launch the application again"
  },

  "history": {
    "title": "chat history"
  },

  "inputs": {
    "choose": "Choose an answer"
  },

  "language": {
    "change": "change the language",
    "select": "select the language you want to use"
  },

  "link": {
    "buttontext": "View the document",
    "goto": "Go to address",
    "click": "Click here",
    "scanQR": "by scanning the QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "For an optimal experience,",
      "classic": "For a more classical experience,",
      "vocal": "I communicate by speaking with the agent.",
      "text": "I communicate by text with the agent."
    },
    "modes": {
      "vocal": "vocal mode",
      "text": "text mode"
    },
    "loaderValidation": "Start conversation",
    "locale": "checking language",
    "supported": "checking supported languages",
    "addressdata": "retrieving data",
    "store": "store",
    "directline": "directline",
    "ponyfill": "ponyfill"
  },

  "menu": {
    "message": "Enter your message",
    "allowMicrophone": "Give your browser permission to use microphone to continue",
    "backconversation": "back to the conversation",
    "answer": " answers your questions"
  },

  "mobileLandscapePreventer": {
    "firstline": "For an optimal experience,",
    "secondline": "flip your screen"
  },

  "news": {
    "warningtext": "You are about to access the information channel, the sound of your device will be activated",
    "live": "live",
    "continuous": "continuous"
  },

  "poi": {
    "comment": "customer review",
    "route": "route",
    "nodata": "No content corresponds to your search",
    "openinghours": "Opening hours",
    "prices": "Prices",
    "paymentmethods": "Payment methods",
    "numberofplaces": "number of places",
    "openroutemobile": "open Google Maps route on smartphone",
    "FoodEstablishment": "Where to eat",
    "LodgingBusiness": "Where to sleep",
    "TouristDestination": "What to see",
    "TouristAttraction": "What to do",
    "Product": "Local products",
    "Service": "Services",
    "Event": "Events",
    "City": "Cities",
    "Wine": "Wines"
  },

  "suggestion": {
    "consult": "Check out",
    "thetutorial": "the tutorial"
  },

  "template": {
    "reset": "reset filters",
    "showresults": "Show results",
    "suggestiontitle": "Research suggestions",
    "reminder": "Don't forget,\n I can answer your questions."
  }
}