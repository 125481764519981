import type { UrlData } from '../models/linkAttachment'
import { TranslationFile } from '../models/translationsType'

interface PropsType {
  translation: TranslationFile
  isUsedOnBorne: boolean
  data: UrlData
  fileName?: string
}

const getTextToDisplayOnLink = ({
  translation,
  isUsedOnBorne,
  data,
  fileName
}: PropsType): string => {
  let textToDisplay = ''

  if (isUsedOnBorne) {
    if (data.download) {
      if (data.title) {
        textToDisplay = `${translation.common.download} \u00ab ${fileName} \u00bb`
      } else {
        textToDisplay = translation.common.download
      }
    } else {
      textToDisplay = translation.link.goto
    }

    textToDisplay += ` ${translation.link.scanQR}`
  } else {
    if (data.download) {
      if (data.title) {
        textToDisplay = `${translation.common.download} ${fileName}`
      } else {
        textToDisplay = translation.common.download
      }
    } else {
      textToDisplay = translation.link.click
    }
  }

  return textToDisplay
}

export { getTextToDisplayOnLink }
