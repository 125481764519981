{
  "_comment": "[CI-LOCKED] Sadržaj ove datoteke je zaključan. Doprinosi su dobrodošli, ali potvrda promene može biti odložena.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Robot $1 je rekao:",
  "ACTIVITY_YOU_SAID_ALT": "Vi ste rekli:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robot je priložio:",
  "ACTIVITY_ERROR_BOX_TITLE": "Poruka o grešci",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Kliknite za interakciju.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Poruka je interaktivna.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Poruka sadrži jednu vez ili više njih.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Slanje nije uspelo.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Priložili ste:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 prilog.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 priloga.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 priloga.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 priloga.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 priloga.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Slanje nije uspelo. [Ponovo pokušaj] [RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Slanje",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Poslato u $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Malopre",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Pre sat vremena",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Pre jednog minuta",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Danas",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Juče",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Greška u raščlanjivanju prilagodljive kartice",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Greška u prikazivanju prilagodljive kartice",
  "ATTACHMENT_AUDIO": "Audio klip.",
  "ATTACHMENT_CARD": "Kartica: $1 $2 $3",
  "ATTACHMENT_FILE": "Datoteka: $1",
  "ATTACHMENT_IMAGE": "Slika",
  "ATTACHMENT_TEXT": "Tekst: $1",
  "ATTACHMENT_VIDEO": "Video klip",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Dalje",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Prethodno",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Povezano",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Povezivanje…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Povezivanje nije moguće.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Došlo je do prekida mreže. Ponovno povezivanje…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Greška u prikazivanju. Proverite konzolu ili se obratite programeru robota.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Povezivanje traje duže nego inače.",
  "CONNECTIVITY_STATUS_ALT": "Status povezivanja: $1",
  "FILE_CONTENT_ALT": "„$1“",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Preuzimanje datoteke „$1“",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Preuzmite datoteku „$1“ veličine $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "„$1“ veličine $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Taster Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Pristup stavkama u poruci",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Istorija ćaskanja",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Taster Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Zatvori poruku",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Taster Tab\nShift + taster Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Prelazak sa jedne stavke na drugu u poruci",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Taster sa strelicom nagore\nTaster sa strelicom nadole",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Prelazak sa jedne poruke na drugu",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Taster Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Obavljanje radnje",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Taster Tab\nShift + taster Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Prelazak sa jedne stavke na drugu u prozoru za ćaskanje",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Prozor za ćaskanje",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Zatvori",
  "KEYBOARD_HELP_HEADER": "Tasterske kontrole",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Otvara se u novom prozoru; spoljno.",
  "RECEIPT_CARD_TAX": "Porez",
  "RECEIPT_CARD_TOTAL": "Ukupno",
  "RECEIPT_CARD_VAT": "PDV",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Nije moguće poslati praznu poruku.",
  "SPEECH_INPUT_LISTENING": "Slušamo…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon je isključen",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon je uključen",
  "SPEECH_INPUT_STARTING": "Počinje…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Dalje",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Prethodno",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Predložene radnje",
  "TEXT_INPUT_ALT": "Polje za unos poruke",
  "TEXT_INPUT_PLACEHOLDER": "Otkucajte poruku",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Pošalji",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Izgovorite",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Otpremi datoteku",
  "TOAST_ACCORDION_FEW": "$1 obaveštenja: Kliknite ovde da biste videli detalje",
  "TOAST_ACCORDION_MANY": "$1 obaveštenja: Kliknite ovde da biste videli detalje",
  "TOAST_ACCORDION_OTHER": "$1 obaveštenja: Kliknite ovde da biste videli detalje",
  "TOAST_ACCORDION_TWO": "$1 obaveštenja: Kliknite ovde da biste videli detalje",
  "TOAST_ALT_ERROR": "Greška",
  "TOAST_ALT_INFO": "Informacije",
  "TOAST_ALT_SUCCESS": "Uspešno",
  "TOAST_ALT_WARN": "Upozorenje",
  "TOAST_DISMISS_BUTTON": "Odbaci",
  "TOAST_TITLE_ALT": "Obaveštenje",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Istorija ćaskanja, koristite tastere sa strelicom da biste se kretali.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "istorija ćaskanja",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Poruka je interaktivna. Pritisnite tastere Shift i Tab 2 do 3 puta da biste se prebacili na istoriju ćaskanja. Zatim kliknite na poruku da biste ostvarili interakciju.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Poruka sadrži veze. Pritisnite tastere Shift i Tab 2 do 3 puta da biste se prebacili na istoriju ćaskanja. Zatim kliknite na poruku da biste ostvarili interakciju.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Poruka ima predložene radnje. Pritisnite tastere shift tab 2 do 3 puta kako biste ih izabrali.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Poruka ima predložene radnje. Pritisnite $1 kako biste ih izabrali.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Slanje poruke nije uspelo.",
  "TRANSCRIPT_MORE_MESSAGES": "Još poruka",
  "TRANSCRIPT_NEW_MESSAGES": "Nove poruke",
  "TRANSCRIPT_TERMINATOR_TEXT": "Kraj istorije ćaskanja",
  "TYPING_INDICATOR_ALT": "Prikazuje se indikator kucanja",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 kuca.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 i drugi kucaju."
}